import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAcademyJobs } from "../../../../../store/actions/Jobs";
import { useLocation } from "react-router-dom";
import JobDataTable from "./JobDataTable";
import Loading from "../../../../layouts/loadingComponents/Loading";
import { getJobStatus } from "../../../../../store/actions/Jobs";
import NewJobDataTable from "./NewJobDataTable";

const NewManageJobs = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const search = useLocation().search;
  const pg = new URLSearchParams(search).get("pg");
  const [pageNumber, setPageNumber] = useState(pg || 1);
  const currentPage = useSelector((state) => state?.academyJobs?.currentPage);
  const nextPage = useSelector((state) => state?.academyJobs?.nextPage);
  const lastPage = useSelector((state) => state?.academyJobs?.lastPage);
  const academyJobs = useSelector((state) => state?.academyJobs?.academyJobs);
  const academyJobsLoading = useSelector(
    (state) => state?.academyJobs?.loading
  );
  const jobStatusData = useSelector((state) => state?.jobStatus?.jobStatus);
  const jobStatusLoading = useSelector((state) => state?.jobStatus?.loading);

  const [loadingModalShow, setLoadingModalShow] = useState(
    academyJobsLoading || jobStatusLoading
  );
  const [numbersOfPages, setNumbersOfPages] = useState([]);
  const [jobStatus, setJobStatus] = useState("");
  const [allJobsState, setAllJobsState] = useState([]);
  const [forceLoad, setForceLoad] = useState(0);
  useEffect(() => {
    dispatch(getJobStatus(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    dispatch(getAcademyJobs(pageNumber, lng === "arab" ? 1 : 2, jobStatus));
  }, [pageNumber, jobStatus, forceLoad]);
  useEffect(() => {
    setPageNumber(pg || 1);
  }, [pg]);
  useEffect(() => {
    let array = [];
    let pages = lastPage;
    while (pages > 0) {
      array.push(pages);
      pages--;
    }
    setNumbersOfPages(array.reverse());
  }, [lastPage]);

  useEffect(() => {
    setAllJobsState(academyJobs);
  }, [academyJobs]);

  useEffect(() => {
    console.log(jobStatusData);
    setLoadingModalShow(academyJobsLoading || jobStatusLoading);
  }, [academyJobsLoading, jobStatusLoading]);
  return (
    <>
      <div className="home-page container">
        {loadingModalShow ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "15px",
              }}
              className="mb-4 flex-wrap"
            >
              <div
                style={{ gap: "10px" }}
                className="d-md-flex pe-0 manageJobs"
              >
                <button
                  style={
                    jobStatus === ""
                      ? {
                          borderBottom: "2px solid #008C77",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: 600,
                          fontStyle: "normal",
                          // marginRight: "15px",
                        }
                      : {
                          color: "#707070",
                          fontSize: "15px",
                          fontWeight: 600,
                          fontStyle: "normal",
                          marginRight: "15px",
                        }
                  }
                  onClick={() => setJobStatus("")}
                >
                  {t("All")}
                </button>
                {jobStatusData?.map((item, i) => (
                  <button
                    key={i}
                    style={
                      jobStatus === item?.id
                        ? {
                            borderBottom: "2px solid #008C77",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: 600,
                            fontStyle: "normal",
                            marginRight: "15px",
                          }
                        : {
                            color: "#707070",
                            fontSize: "15px",
                            fontWeight: 600,
                            fontStyle: "normal",
                            marginRight: "15px",
                          }
                    }
                    onClick={() => setJobStatus(item?.id)}
                  >
                    {item?.name}
                  </button>
                ))}
              </div>
              <div
              className="new-job-btn col-lg-4 col-sm-12 d-flex justify-content-end"
              >
                <button
              className="btn-2 semi-bold-white"
              onClick={() => history.push("/new-job")}
            >
              Post New Job
            </button>
              </div>
            </div>
            <NewJobDataTable
              forceLoad={forceLoad}
              setForceLoad={setForceLoad}
              page
              jobs={academyJobs}
              setPageNumber={setPageNumber}
              numbersOfPages={numbersOfPages}
              loadingModalShow={loadingModalShow}
              currentPage={currentPage}
              pageNumber={pageNumber}
              nextPage={nextPage}
              liveJob={false}
            />
          </>
        )}
      </div>
    </>
  );
};
export default NewManageJobs;
