import React, {useState, useEffect} from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './toastAlert.css'

export const toastAlert = (message, extra, errorType) => {
    toast.configure({
        position: toast.POSITION.BOTTOM_RIGHT,
        limit: 3,
        autoClose: 3500,
        closeOnClick: true,
    });
    let component = <></>
    const Component = (props) => {
        const [messageState, setMessageState] = useState(props.message)
        useEffect(() => {
            setMessageState(props.message)
            console.log(props.message)
        }, [props.message])
        return (
        <span>
            <span>{messageState}</span><br />
        </span >
        )
    };
    try {

        component = (
            <Component message={message}/>
            );
    } catch (error) {
        console.log('hola',error)
        toastAlert("Something went wrong...!", undefined, "error")
    }
    switch (errorType) {
        case "warning":
            if (Array.isArray(message)) {
            message.map(item => toast.warn(item));
            } else {
                toast.warn(message)
            }
            console.log(message);
            break;
        case "error":
            toast.error(message);
            break;
        case "success":
            toast.success(message);
            break;
        default:
            toast.info(message);
            break;
    }
    toast.clearWaitingQueue();
};
