import axios from 'axios';
import { AUTH, LOGOUT, RE_AUTH, SIGN_UP_FIRST_AUTH, FORGOT_PASSWORD, RESET_PASSWORD, AUTH_SUBTYPE, LOADING_USER_INFO, LOADED_USER_INFO } from '../actions/Constants'

export default (userDetails = { auth: false, authData: [], subTypeData: {}, authLoading: true }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem("user", JSON.stringify(action?.data[0]));
            localStorage.setItem("token", action.data[1]);
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.data[1]}`;
            return { ...userDetails, auth: true, authData: [...action?.data] };
        case LOGOUT:
            localStorage.removeItem("sess");
            window.location.replace('https://madaresweb.mvp-apps.ae/')
            return { ...userDetails, auth: false, authData: [], subTypeData: {} };
        case AUTH_SUBTYPE:
            return { ...userDetails, subTypeData: action?.data, authLoading: false };
        case LOADING_USER_INFO:
            return { ...userDetails, authLoading: true };
        case LOADED_USER_INFO:
            return { ...userDetails, authLoading: false };
        default:
            return userDetails;
    }
}