import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createNewJob,
  checkCompanyJob,
} from "../../../../../../store/actions/Jobs";
import { useDispatch } from "react-redux";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { toastAlert } from "../../../../../layouts/toastAlert/toastAlert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getNewSubjects,
  getGrades,
} from "../../../../../../store/actions/Jobs";
import Loading from "../../../../../layouts/loadingComponents/Loading";
import { Country, State, City } from "country-state-city";

let years = [
  { id: "0", name: "0" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "+5" },
];

let months = [
  { id: "0", name: "0" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
];
let city;

const DetailsStep = ({
  submitNewJobStep1,
  handleGeneralInfoChange,
  generalInfo,
  setGeneralInfo,
  editPost,
  editPostHandler
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  useEffect(() => {
    dispatch(getNewSubjects(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
  }, []);

  useEffect(() => {
    if(generalInfo?.state) {
      const code = State.getStatesOfCountry("SA").find(
        (x) => x.name === generalInfo?.state
      ).isoCode;
      city = City.getCitiesOfState("SA", code);
    }
  }, [generalInfo?.state])
  

  const subjects = useSelector((state) => state?.newSubjects?.subjects);
  const grades = useSelector((state) => state?.grades?.grades);
  const subjectsLoading = useSelector(
    (state) => state?.newSubjects?.newLoading
  );
  const gradesLoading = useSelector((state) => state?.grades?.innerLoading);
  useEffect(() => {
    setLoadingModalShow(subjectsLoading || gradesLoading);
  }, [subjectsLoading, gradesLoading]);

  const handleState = (e) => {
    handleGeneralInfoChange(e);
    const code = State.getStatesOfCountry("SA").find(
      (x) => x.name === e.target.value
    ).isoCode;
    city = City.getCitiesOfState("SA", code);
  };
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">
          {editPost ? t("Edit Job Post") : t("New Job Post")}
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            {loadingModalShow ? (
              <div
                style={{
                  minHeight: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Job title")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={generalInfo.title}
                      onChange={handleGeneralInfoChange}
                      className="form-control solid"
                      placeholder={t("Name")}
                      aria-label="name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Subjects")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <Select
                      value={generalInfo.subjects}
                      name="subjects"
                      onChange={(selectedOption) =>
                        handleGeneralInfoChange({
                          target: { value: selectedOption, name: "subjects" },
                        })
                      }
                      placeholder={t("Select Subject(s)")}
                      options={subjects?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      isMulti
                    />
                  </div>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Teaching level")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <Select
                      value={generalInfo.grades}
                      name="grades"
                      onChange={(selectedOption) =>
                        handleGeneralInfoChange({
                          target: { value: selectedOption, name: "grades" },
                        })
                      }
                      placeholder={t("Select Level(s)")}
                      options={grades?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      isMulti
                    />
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-xl-6  col-md-6 mb-4">
                      <label className="form-label font-w600">
                        {t("Country")}
                        <span className="text-danger scale5 ms-2">*</span>
                      </label>
                      <CountryDropdown
                        className="form-control solid"
                        defaultOptionLabel={t("Select Country")}
                        value={generalInfo.country} name='country' 
                        onChange={(val)=> handleGeneralInfoChange({target: {value:val, name:'country'}})}
                      />
                    </div> */}
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("State")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    {/* <RegionDropdown
                        className="form-control solid"
                        defaultOptionLabel={t("Select State")}
                        country={generalInfo.country}
                        value={generalInfo.city}
                        onChange={(val)=> handleGeneralInfoChange({target: {value:val, name:'city'}})}
                      /> */}
                    <select
                      value={generalInfo.state}
                      onChange={(e) => handleState(e)}
                      name="state"
                      className="form-control"
                    >
                      {State.getStatesOfCountry("SA")?.map((item, i) => (
                        <option key={i} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("City")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <select
                      value={generalInfo.city}
                      onChange={(e) => handleGeneralInfoChange(e)}
                      name="city"
                      className="form-control"
                    >
                      {city?.map((item, i) => (
                        <option key={i} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Minimum Salary / Month")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <input
                      name="minimum_salary"
                      value={generalInfo.minimum_salary}
                      onChange={(e) =>
                        handleGeneralInfoChange({
                          target: {
                            value: e.target.value.replace(/\D/, ""),
                            name: "minimum_salary",
                          },
                        })
                      }
                      type="text"
                      className="form-control solid forceEnglishFont"
                      placeholder={t("SAR")}
                      aria-label="name"
                    />
                  </div>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Maximum Salary / Month")}
                    </label>
                    <input
                      name="maximum_salary"
                      value={generalInfo.maximum_salary}
                      onChange={(e) =>
                        handleGeneralInfoChange({
                          target: {
                            value: e.target.value.replace(/\D/, ""),
                            name: "maximum_salary",
                          },
                        })
                      }
                      type="text"
                      className="form-control solid forceEnglishFont"
                      placeholder={t("SAR")}
                      aria-label="name"
                    />
                  </div>
                  <div
                    style={{ display: "flex", gap: "7px" }}
                    className="col-xl-6  col-md-6 mb-4"
                  >
                    <input
                      style={{
                        float: "unset",
                        marginRight: "unset",
                        marginLeft: "unset",
                      }}
                      checked={generalInfo.publish_salary_information == "0"}
                      value={generalInfo.publish_salary_information}
                      onChange={() =>
                        handleGeneralInfoChange({
                          target: {
                            value:
                              generalInfo.publish_salary_information == "0"
                                ? "1"
                                : "0",
                            name: "publish_salary_information",
                          },
                        })
                      }
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label">
                      {t("Don't publish salary information")}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <h6
                    style={{ fontSize: "17px" }}
                    className="form-label font-w600"
                  >
                    {t("Contract length")}
                  </h6>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Years")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <select
                      name="contract_length_years"
                      value={generalInfo.contract_length_years}
                      onChange={handleGeneralInfoChange}
                      className="form-select form-control solid"
                    >
                      <option value="">{t("None")}</option>
                      {years?.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Months")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <select
                      name="contract_length_months"
                      value={generalInfo.contract_length_months}
                      onChange={handleGeneralInfoChange}
                      className="form-select form-control solid"
                    >
                      <option value="">{t("None")}</option>
                      {months?.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Post Date")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="far fa-clock"></i>
                      </div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          className="form-control form_datetime solid"
                          name="start_date"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          value={generalInfo.start_date}
                          onChange={(date) =>
                            handleGeneralInfoChange({
                              target: { value: date, name: "start_date" },
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className="col-xl-6  col-md-6 mb-4">
                    <label className="form-label font-w600">
                      {t("Close Date")}
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="far fa-clock"></i>
                      </div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          label=""
                          clearable
                          className="form-control form_datetime solid"
                          name="close_date"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          value={generalInfo.close_date}
                          onChange={(date) =>
                            handleGeneralInfoChange({
                              target: { value: date, name: "close_date" },
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className="col-xl-12 mb-4">
                    <label className="form-label font-w600">
                      {t("Description")}:
                      <span className="text-danger scale5 ms-2">*</span>
                    </label>
                    <Rte changeFunc={setGeneralInfo} obj={generalInfo} />
                  </div>
                  <div className="card-footer text-end">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        to={"#"}
                        className="btn btn-primary"
                        onClick={ editPost? editPostHandler : submitNewJobStep1}
                      >
                        {t("Next")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailsStep;
