import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

//import
import CanvasChartTab from "../../Common/HomeCharts/CanvasChartTab";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import VacanyTab from "../../Common/HomeCharts/VacanyTab";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsStatistics } from "../../../../store/actions/applicants";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Loading from "../../../layouts/loadingComponents/Loading";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const Statistics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const NewCustomers1 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers1"), 1000)
  );
  const NewCustomers2 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers2"), 1000)
  );
  const NewCustomers3 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers3"), 1000)
  );
  const NewCustomers4 = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/NewCustomers4"), 1000)
  );
  const ApexPieChart = loadable(() =>
    pMinDelay(import("../../Common/HomeCharts/ApexPieChart"), 1000)
  );
  const [locationJobSwitch, setLocationJobSwitch] = useState(1);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [customStartDate, setCustomStartDate] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const [type, setType] = useState("MONTH");
  const { statistics, loading } = useSelector((state) => state?.statistics);

  useEffect(() => {
    console.log("moment", moment(new Date()).format("DD MMMM YYYY hh:mm A"))
    if (type) {
      setLoadingModalShow(true);
      let start_date;
      let end_date = moment(new Date()).format("YYYY-MM-DD");
      if (type === "TODAY" || type === "Select") {
        start_date = end_date;
      } else if (type === "WEEK") {
        start_date = moment(end_date).subtract(1, "WEEK").format("YYYY-MM-DD");
      } else if (type === "MONTH") {
        start_date = moment(end_date).subtract(1, "MONTH").format("YYYY-MM-DD");
      } else if (type === "CUSTOM") {
        setLoadingModalShow(false);
        setCustomDate(true);
        return false;
      }
      dispatch(
        getAnalyticsStatistics(
          start_date,
          end_date,
          setLoadingModalShow,
          lng === "arab" ? 1 : 2
        )
      );
    }
  }, [type]);

  useEffect(() => {
    if (customStartDate && customEndDate) {
      console.log("entered");
      setLoadingModalShow(true);
      dispatch(
        getAnalyticsStatistics(
          moment(customStartDate).format("YYYY-MM-DD"),
          moment(customEndDate).format("YYYY-MM-DD"),
          setLoadingModalShow,
          lng === "arab" ? 1 : 2
        )
      );
      setCustomDate(false);
      setType();
      setCustomStartDate();
      setCustomEndDate();
    }
  }, [customStartDate, customEndDate]);

  return (
    <>
      {loadingModalShow ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "306px",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            className="row pb-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {customDate ? (
              <>
                <div className="col-lg-2 mb-4">
                  <label className="form-label font-w600">
                    {t("Start Date")}
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="far fa-clock"></i>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        className="form-control form_datetime"
                        name="start_date"
                        format="dd/MM/yyyy"
                        value={customStartDate}
                        onChange={(date) => setCustomStartDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label font-w600">
                    {t("End Date")}
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="far fa-clock"></i>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label=""
                        clearable
                        className="form-control form_datetime"
                        name="end_date"
                        format="dd/MM/yyyy"
                        value={customEndDate}
                        onChange={(date) => setCustomEndDate(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-2 mb-4">
                <label className="form-label font-w600">
                  {t("Search By Date")}
                </label>
                <select
                  onChange={(e) => setType(e.target.value)}
                  name="state"
                  className="form-control"
                  value={type}
                >
                  <option value="Select">Select</option>
                  <option value="TODAY">Today</option>
                  <option value="WEEK">Last 7 Days</option>
                  <option value="MONTH">Last 30 Days</option>
                  <option value="CUSTOM">Custom</option>
                </select>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="fs-20 mb-3">{t("Applications Statuses")}</h4>
                  <div className="row separate-row">
                    {statistics?.applicationsStatuses?.length === 0 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "200px"
                        }}
                      >
                        <h6>No data found check with other date</h6>
                      </div>
                    ) : (
                      statistics?.applicationsStatuses?.map((item, index) => (
                        <div className="col-sm-6">
                          <div className="job-icon pb-4 d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center mb-1">
                                <h2 className="mb-0">{item?.count}</h2>
                              </div>
                              <span className="fs-14 d-block mb-2">
                                {item?.status?.name}
                              </span>
                            </div>
                            <div id="NewCustomers">
                              <NewCustomers1 />
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <VacanyTab forAcademy />
                </div>
                <div className="col-xl-12">
							<CanvasChartTab />
						</div>
              </div>
            </div> */}

            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <h4 className="fs-20 mb-3">
                          {t("Application Summary")}
                        </h4>
                        <div className="custom_switcher">
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 2
                                  ? "1px solid #104b08"
                                  : "unset",
                              color: locationJobSwitch === 2 ? "#104b08" : null,
                            }}
                            onClick={() => setLocationJobSwitch(2)}
                          >
                            {t("Citizenship")}
                          </label>
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 0
                                  ? "1px solid #104b08"
                                  : "unset",
                              color: locationJobSwitch === 0 ? "#104b08" : null,
                            }}
                            onClick={() => setLocationJobSwitch(0)}
                          >
                            {t("Location")}
                          </label>
                          <label
                            style={{
                              padding: "3px",
                              borderBottom:
                                locationJobSwitch === 1
                                  ? "1px solid #104b08"
                                  : "unset",
                              color: locationJobSwitch === 1 ? "#104b08" : null,
                            }}
                            onClick={() => setLocationJobSwitch(1)}
                          >
                            {t("Jobs")}
                          </label>
                        </div>

                        {locationJobSwitch === 0 ? (
                          statistics?.applicants?.applicantsByLocations?.map(
                            (item, i) => (
                              <div className="col-xl-6 col-sm-6">
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: "rgb(250, 153, 15)",
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {/* {t("Jeddah")} */}
                                    {item?.country}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              </div>
                            )
                          )
                        ) : locationJobSwitch === 1 ? (
                          <div className="col-xl-6 col-sm-6">
                            {statistics?.applicants?.applicantsByJobs?.map(
                              (item, i) => (
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: "rgb(162, 197, 99)",
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {item?.job?.title}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        ) : locationJobSwitch === 2 ? (
                          <div className="col-xl-6 col-sm-6">
                            {statistics?.applicants?.applicantsByCitizenships?.map(
                              (item, i) => (
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: "rgb(16, 75, 8)",
                                      width: "12px",
                                      height: "12px",
                                      float: "left",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <span className="fs-14 font-w500">
                                    {item?.name}
                                  </span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {item?.count}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {locationJobSwitch === 0 ? (
                          <div className="col-xl-6 col-sm-6">
                            <div id="pieChart1">
                              <ApexPieChart />
                            </div>
                          </div>
                        ) : (
                          <div className="col-xl-6 col-sm-6">
                            <div id="pieChart1">
                              <ApexPieChart second />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row separate-row">
                      <div className="col-sm-6">
                        <div className="job-icon pb-4 d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center mb-1">
                              <h2 className="mb-0">342</h2>
                              <span className="text-success ms-3">+0.5 %</span>
                            </div>
                            <span className="fs-14 d-block mb-2">
                              {t("Interview Schedules")}
                            </span>
                          </div>
                          <div id="NewCustomers">
                            <NewCustomers1 />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center mb-1">
                              <h2 className="mb-0">984</h2>
                            </div>
                            <span className="fs-14 d-block mb-2">
                              {t("Application Received")}
                            </span>
                          </div>
                          <div id="NewCustomers1">
                            <NewCustomers2 />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center mb-1">
                              <h2 className="mb-0">1,567k</h2>
                              <span className="text-danger ms-3">-2 % </span>
                            </div>
                            <span className="fs-14 d-block mb-2">
                              {t("Profile Viewed")}
                            </span>
                          </div>
                          <div id="NewCustomers2">
                            <NewCustomers3 />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="job-icon pt-4  d-flex justify-content-between">
                          <div>
                            <div className="d-flex align-items-center mb-1">
                              <h2 className="mb-0">437</h2>
                            </div>
                            <span className="fs-14 d-block mb-2">
                              {t("Unread Messages")}
                            </span>
                          </div>
                          <div id="NewCustomers3">
                            <NewCustomers4 />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Statistics;
