import React from 'react'
import { t } from 'i18next'

const ChooseSettings = ({ setView }) => {
  const updateView = (num) => {
    setView(num)
  }

  return (
    <>
      <div className="col-xl-12">
        <div onClick={() => updateView(1)} style={{ height: 'auto', boxShadow: '1px 0px 8px #80808045', cursor: 'pointer' }} className="card settingsBtn">
          <div className="card-body">
            <div className="row">
              {t('Change Password')}
            </div>
          </div>
        </div>
        <div onClick={() => updateView(2)} style={{ height: 'auto', boxShadow: '1px 0px 8px #80808045', cursor: 'pointer' }} className="card settingsBtn">
          <div className="card-body">
            <div className="row">
              {t('Change Email')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseSettings