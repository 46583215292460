import { t } from 'i18next'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeAcademyPassword } from '../../../../store/actions/applicants';
import { changeEmail } from '../../../../store/actions/Auth';
import Loading from '../../../layouts/loadingComponents/Loading';
import { toastAlert } from '../../../layouts/toastAlert/toastAlert';
import EmailVerfication from '../../DashboardAcademy/settings/EmailVerification';
import ChooseSettings from './ChooseSettings';

const Setting = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [emailVerf, setEmailVerf] = useState(false);
  const useAuth = useSelector((state) => state?.userDetails?.subTypeData);
  const [userData, setUserData] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
    email: "",
  })
  const handleSubmit = () => {
    console.log("userData", userData);
    setLoadingModalShow(true);
    let errorArr = [];
    if (view === 1) {
      let data = {
        current_password: userData.current_password,
        password: userData.password,
        password_confirmation: userData.confirm_password
      }
      if (!data.current_password) {
        errorArr.push(t("Current Password is required"));
      }
      if (!data.password) {
        errorArr.push(t("Password is required"));
      }
      if (!data.password_confirmation) {
        errorArr.push(t("Confirm Password is required"));
      }
      if (data?.password !== data?.password_confirmation) {
        errorArr.push(t("Password Not Matched"));
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning");
        setLoadingModalShow(false);
        return false;
      }
      dispatch(changeAcademyPassword(data, setLoadingModalShow))
        handleBack()
    } else if (view === 2) {
      let data = {
        email: userData.email,
      };
      if (!data.email) {
        errorArr.push(t("Email is required"));
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning");
        setLoadingModalShow(false);
        return false;
      }
      dispatch(changeEmail(data, setLoadingModalShow, setEmailVerf));
    }
  };
  const handleBack = () => {
    setUserData({
      current_password: "",
      password: "",
      confirm_password: "",
      email: "",
    });
    setView(0);
  };
  return (
    <>
      {emailVerf?
        <EmailVerfication/>
        :
        <>
              <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">
          {t("Teacher Settings")}
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              {loadingModalShow ? (
                <div
                  style={{
                    minHeight: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <div className="row">
                  {view === 0 ? (
                    <ChooseSettings setView={setView} />
                  ) : (
                    <>
                      {view === 1 ? (
                        <>
                          <div>
                            <div className="row">
                              <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                  {t("Current Password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control solid"
                                  placeholder={t("Current Password")}
                                  value={userData.current_password}
                                  name="current_password"
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      current_password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                  {t("New Password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control solid"
                                  placeholder={t("New Password")}
                                  value={userData.password}
                                  name="password"
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                  {t("Confirm Password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control solid"
                                  placeholder={t("Confirm Password")}
                                  value={userData.confirm_password}
                                  name="confirm_password"
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      confirm_password: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {view === 2 ? (
                        <>
                        <div className="row">
                          <div className="col-xl-6 col-md-6 mb-4">
                            <label className="form-label font-w600">
                              {t("Old Email")}
                            </label>
                            <input
                              type="email"
                              className="form-control solid"
                              placeholder={t("Example@example.com")}
                              name="email"
                              value={useAuth?.email}
                              disabled
                            />
                          </div>
                          <div className="col-xl-6 col-md-6 mb-4">
                            <label className="form-label font-w600">
                              {t("New Email")}
                              <span className="text-danger scale5 ms-2">
                                *
                              </span>
                            </label>
                            <input
                              type="email"
                              className="form-control solid"
                              placeholder={t("Example@example.com")}
                              name="email"
                              value={userData.email}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="card-footer text-end">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: view !== 0 ? "space-between" : "flex-end",
                }}
              >
                {view !== 0 ? (
                  <Link
                    to={"#"}
                    onClick={handleBack}
                    className="btn btn-secondry"
                  >
                    {t("Back")}
                  </Link>
                ) : null}
                {view !== 0 ? (
                  <Link
                    to={"#"}
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    {t("Save")}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
      }
    </>
  )
}

export default Setting