import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBestCandidates } from "../../../../store/actions/applicants";
import { t } from "i18next";
import { getAllJobTitles } from "../../../../store/actions/Jobs";
import { Link } from "react-router-dom";
import BestCandidateList from "./BestCandidateList";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const BestCandidate = () => {
  const [bestCandidatePopUp, setBestCandidatePopUp] = useState(true);
  const [jobTitle, setJobTitle] = useState("");
  const [applicantPopUp, setApplicantPopUp] = useState(false);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (jobTitle) {
      dispatch(getBestCandidates(jobTitle, setLoadingModalShow));
    }
  }, [jobTitle]);
  const { bestCandidates, loading } = useSelector(
    (state) => state?.bestCandidates
  );
  return (
    <>
      <BestCandidateModal
        show={bestCandidatePopUp}
        onHide={() => setBestCandidatePopUp(false)}
        setApplicantPopUp={setApplicantPopUp}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
      />
      <ApplicantsModal
        show={applicantPopUp}
        onHide={() => setApplicantPopUp(false)}
        data={bestCandidates}
      />
    </>
  );
};

export default BestCandidate;

const BestCandidateModal = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllJobTitles(lng === "arab" ? 1 : 2));
  }, []);
  const { allJobTitles } = useSelector((state) => state?.allJobTitles);
  const reviewApplicants = () => {
    props?.setApplicantPopUp(true);
    props.onHide();
  };
  return (
    <Modal {...props} centered size="md" className="fade">
      <Modal.Header>
        <h3>{t("Welcome To Madares AI")}</h3>
      </Modal.Header>
      <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
        <h4>Please select a job to find the best candidates</h4>
        <div className="row">
          <select
            value={props?.jobTitle}
            name="degree_level_id"
            onChange={(e) => props?.setJobTitle(e.target.value)}
            className="form-control solid"
          >
            <option value="">{t("Select")}</option>
            {allJobTitles?.map((item, i) => (
              <option key={i} value={item?.id}>
                {item?.title}
              </option>
            ))}
          </select>
        </div>
        {props?.jobTitle ? (
          <div className="row">
            <div
              style={{ display: "flex" }}
              className="col-xl-6  col-md-6 mb-4"
            ></div>
            <div className="card-footer text-end">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={"#"}
                  onClick={reviewApplicants}
                  className="btn btn-primary"
                >
                  {t("Review Applicants")}
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

const ApplicantsModal = (props) => {
  return (
    <Modal {...props} centered size="md" className="fade applicantModal">
      <Modal.Body>
        <BestCandidateList item={props?.data[0]} data={props?.data} onHide={props.onHide} type="Best Candidate" />
      </Modal.Body>
    </Modal>
  );
};
