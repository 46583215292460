import { t } from "i18next";
import React, { useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
// import './index.css';
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import { useDispatch } from "react-redux";
import Loading from "../../../layouts/loadingComponents/Loading";
import { deleteTeacherResume } from "../../../../store/actions/teachers";
import {
  deleteAcademyAvatar,
  deleteAcademyBanner,
} from "../../../../store/actions/applicants";
import { Modal } from "react-bootstrap";
import FileViewer from "react-file-viewer";

const SignleFilePicker = ({
  setFile,
  file,
  children,
  setFileState,
  FileState,
  setNewFile,
  type,
}) => {
  const [filesInfo, setFilesInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  useEffect(() => {
    setFilesInfo(FileState);
  }, [FileState]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        console.log(typeof setNewFile);
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
          setFileState(file);
          toastAlert(t("File uploaded successfuly"), undefined, "success");
          if (setNewFile) {
            setNewFile(reader.result);
          }
        };
        reader.onerror = function (error) {
          toastAlert(t("Error while uploading files"), undefined, "warning");
        };
      });
    },
    [FileState]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  var files = !filesInfo ? null : (
    <li key={filesInfo?.path}>
      {filesInfo?.path} - {filesInfo?.size} bytes
    </li>
  );
  const dispatch = useDispatch();
  const removeOldFile = () => {
    setLoading(true);
    if (type === "Resume") {
      dispatch(deleteTeacherResume(setLoading));
    }
    if (type === "Banner") {
      dispatch(deleteAcademyBanner(setLoading));
    }
    if (type === "Logo") {
      dispatch(deleteAcademyAvatar(setLoading));
    }
    setFile("");
  };
  const removeNewFile = () => {
    setFilesInfo("");
    setNewFile("");
    setFileState("")
  };
  const viewResume = () => {
    setViewModal(true);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50%"
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            {children}
          </div>
          <aside>
            <ul style={{ display: "flex", gap: "5px", alignItems: "center", wordBreak: "break-word", }}>
              {type === "Resume" ? (
                <>
                  {file?.resume_url || files}
                  {files && (
                    <i
                      title="remove file"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      onClick={() => removeNewFile()}
                      style={{ color: "red" }}
                    />
                  )}
                  {file?.resume_url && (
                    <>
                     <i
                      title="remove file"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      onClick={() => removeOldFile()}
                      style={{ color: "red" }}
                    />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="20"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                        color="#104b08"
                        style={{ marginRight: "10px" }}
                        onClick={() => viewResume()}
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                    </>
                  )}
                </>
              ) : null}
              {type === "Banner" ? (
                <>
                  {file || files}
                  {files && (
                    <i
                      title="remove file"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      onClick={() => removeNewFile()}
                      style={{ color: "red" }}
                    />
                  )}
                  {file && (
                    <>
                    <i
                      title="remove Banner"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      onClick={() => removeOldFile()}
                      style={{ color: "red" }}
                    />
                    </>
                  )}
                </>
              ) : null}
              {type === "Logo" ? (
                <>
                  {file || files}
                  {files && (
                    <i
                      title="remove avatar"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      style={{ color: "red" }}
                      onClick={() => removeNewFile()}
                    />
                  )}
                  {file && (
                    <i
                      title="remove avatar"
                      aria-hidden="true"
                      className="fa fa-window-close"
                      onClick={() => removeOldFile()}
                      style={{ color: "red" }}
                    />
                  )}
                </>
              ) : null}
            </ul>
          </aside>
          {type === "Resume"? 
          <ViewResumeModal
            show={viewModal}
            onHide={() => setViewModal(false)}
            data={file}
          />: null
          }
        </>
      )}
    </>
  );
};

export default SignleFilePicker;

const ViewResumeModal = ({ show, onHide, data }) => {
    console.log("data", data)
    return (
      <Modal show={show} onHide={onHide} centered size="md" className="fade">
        <Modal.Header>View Resume</Modal.Header>
        <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
          {/* <FileViewer fileType={data?.extension} filePath={"https://api.madaresweb.mvp-apps.ae" + data?.resume_url}  /> */}
          <FileViewer fileType="docx" filePath="Resume.docx"  />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  };
  