import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../../../../layouts/loadingComponents/Loading';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import { deleteTeacherExperience, editTeacherExperience } from '../../../../../store/actions/teachers';
import { toastAlert } from '../../../../layouts/toastAlert/toastAlert';

function formatDate(date) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

const EditExperienceModal = ({ show, onHide, data, subjects, grades, curriculums, setView, forceLoad, setForceLoad }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teacherExperience, setTeacherExperience] = useState({
        country: data?.country || "",
        curriculum_id: data?.curriculum_id || "",
        end_date: data?.end_date || "",
        experience_type: data?.experience_type || "",
        experience_id: data?.experience_id || "",
        school: data?.school || "",
        start_date: data?.start_date || "",
        still_work_there: data?.still_work_there || "",
        title: data?.title || "",
        years_of_experience: data?.years_of_experience || "",
        grades:
            data?.grades?.map((item) => {
                return { value: item?.id, label: item?.name };
            }) || [],
        subjects:
            data?.subjects?.map((item) => {
                return { value: item?.id, label: item?.name };
            }) || [],
    });
    useEffect(() => {
        setTeacherExperience({
            country: data?.country || "",
            curriculum_id: data?.curriculum_id || "",
            end_date: data?.end_date || "",
            experience_type: data?.experience_type || "",
            experience_id: data?.experience_id || "",
            school: data?.school || "",
            start_date: data?.start_date || "",
            still_work_there: data?.still_work_there || "",
            title: data?.title || "",
            years_of_experience: data?.years_of_experience || "",
            grades: data?.grades || [],
            subjects: data?.subjects || [],
        });
    }, [data]);

    //   Submit Button Handlers
    const submitExperienceForm = () => {
        setLoading(true);
        let userData = {
            ...teacherExperience,
            start_date: formatDate(teacherExperience.start_date),
            end_date:
                teacherExperience.still_work_there !== "1"
                    ? formatDate(teacherExperience.end_date)
                    : "",
        };
        let errorArr = [];
        if (!userData.country) {
            errorArr.push(t("Country is required"));
        }
        if (!userData.curriculum_id) {
            errorArr.push(t("Curriculum is required"));
        }
        if (!userData.experience_type) {
            errorArr.push(t("Experience Type is required"));
        }
        if (!userData.experience_id) {
            errorArr.push(t("Experience Id is required"));
        }
        if (!userData.school) {
            errorArr.push(t("School is required"));
        }
        if (!userData.start_date) {
            errorArr.push(t("Start Date is required"));
        }
        if (!userData.title) {
            errorArr.push(t("Title is required"));
        }
        if (!userData.years_of_experience) {
            errorArr.push(t("Years of experience is required"));
        }
        if (!userData.grades?.length) {
            errorArr.push(t("Grade is required"));
        }
        if (!userData.subjects?.length) {
            errorArr.push(t("Subject is required"));
        }
        if (errorArr.length > 0) {
            toastAlert(errorArr, undefined, "warning");
            setLoading(false);
            return false;
        }
        dispatch(editTeacherExperience(userData, setLoading, onHide, setView, forceLoad, setForceLoad));
    }

    const deleteExperience = () => {
        setLoading(true);
        dispatch(
            deleteTeacherExperience(teacherExperience?.experience_id, setLoading, onHide, setView, forceLoad, setForceLoad)
        );
    }

    return (
        <Modal show={show} onHide={onHide} centered size="md" className="fade">
            <Modal.Header>
                <h3>{t('Edit Experience')}</h3>
            </Modal.Header>
            <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
                {loading ? (
                    <div
                        style={{
                            minHeight: "500px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                    {t("Experience Type")}
                                </label>
                                <select
                                    value={teacherExperience?.experience_type}
                                    name="experience_type"
                                    onChange={(e) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            experience_type: e.target.value,
                                        })
                                    }
                                    className="form-control solid"
                                >
                                    <option value={t("Teaching")}>{t("Teaching")}</option>
                                    <option value={t("Educational Administration")}>
                                        {t("Educational Administration")}
                                    </option>
                                    <option value={t("Relevant work experience")}>
                                        {t("Relevant work experience")}
                                    </option>
                                </select>
                            </div>
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                    {t("Position Title")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control solid"
                                    placeholder={t("Computer Teacher")}
                                    value={teacherExperience?.title}
                                    name="title"
                                    onChange={(e) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            title: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                    {t("School or District")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control solid"
                                    placeholder={t("Name")}
                                    value={teacherExperience?.school}
                                    name="school"
                                    onChange={(e) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            school: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">{t("Country")}</label>
                                <CountryDropdown
                                    className="form-control solid"
                                    defaultOptionLabel={t("Select Country")}
                                    value={teacherExperience?.country}
                                    name="country"
                                    onChange={(e) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            country: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <label className="form-label font-w600">
                                    {t("Curriculum you taught")}
                                </label>
                                <select
                                    value={teacherExperience?.curriculum_id}
                                    name="curriculum_id"
                                    onChange={(e) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            curriculum_id: e.target.value,
                                        })
                                    }
                                    className="form-control solid"
                                >
                                    {curriculums?.map((item, i) => (
                                        <option key={i} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <label className="form-label font-w600">
                                    {t("Grade(s) you taught")}
                                </label>
                                <Select
                                    value={teacherExperience?.grades}
                                    name="grades"
                                    onChange={(e) =>
                                        setTeacherExperience({ ...teacherExperience, grades: e })
                                    }
                                    placeholder={t("Select Grade(s)")}
                                    options={grades?.map((item) => ({
                                        label: item?.name,
                                        value: item?.id,
                                    }))}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <label className="form-label font-w600">
                                    {t("Subject(s) you taught")}
                                </label>
                                <Select
                                    value={teacherExperience?.subjects}
                                    name="subjects"
                                    onChange={(selectedOption) =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            subjects: selectedOption,
                                        })
                                    }
                                    placeholder={t("Select Subject(s)")}
                                    options={subjects?.map((item) => ({
                                        label: item?.name,
                                        value: item?.id,
                                    }))}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">{t("From")}</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        className="form-control solid"
                                        name="date_of_birth"
                                        format="dd/MM/yyyy"
                                        value={teacherExperience?.start_date}
                                        onChange={(date) =>
                                            setTeacherExperience({
                                                ...teacherExperience,
                                                start_date: date,
                                            })
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            {teacherExperience.still_work_there == "1" ? null : (
                                <div className="col-xl-6  col-md-6 mb-4">
                                    <label className="form-label font-w600">{t("To")}</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            className="form-control solid"
                                            name="date_of_birth"
                                            format="dd/MM/yyyy"
                                            value={teacherExperience?.end_date}
                                            onChange={(date) =>
                                                setTeacherExperience({
                                                    ...teacherExperience,
                                                    end_date: date,
                                                })
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            )}
                        </div>

                        <div className="row">
                            <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-md-12 col-sm-12 col-lg-12 mb-3"
                            >
                                <input
                                    style={{
                                        float: "unset",
                                        marginRight: "unset",
                                        marginLeft: "unset",
                                    }}
                                    checked={teacherExperience.still_work_there == "1"}
                                    value={teacherExperience.still_work_there}
                                    onChange={() =>
                                        setTeacherExperience({
                                            ...teacherExperience,
                                            still_work_there:
                                                teacherExperience.still_work_there == "0" ? "1" : "0",
                                        })
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                />
                                <label className="form-check-label">
                                    {t("I am currently working in this role")}
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                            ></div>
                            <div className="card-footer text-end">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Link
                                        to={"#"}
                                        onClick={deleteExperience}
                                        className="btn btn-primary"
                                    >
                                        {t("Delete")}
                                    </Link>
                                    <Link
                                        to={"#"}
                                        onClick={submitExperienceForm}
                                        className="btn btn-primary"
                                    >
                                        {t("Save")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* // } */}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    )
}

export default EditExperienceModal