import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../../../layouts/loadingComponents/Loading";
import { Modal } from "react-bootstrap";
import NewJob from "../newJob/NewJob";
import { useDispatch } from "react-redux";
import { ADD_TEMPO_JOB_POST_DETAILS, CLEAR_TEMP } from "../../../../../store/actions/Constants";
import { deleteJob, changeJobStatus } from '../../../../../store/actions/Jobs';
const JobDataTable = ({ setPageNumber, jobs, numbersOfPages, loadingModalShow, firstFive, page, drafts, forceLoad, setForceLoad, currentPage, pageNumber, nextPage }) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const history = useHistory();
  const [editJob, setEditJob] = useState(false);
  const [deleteJob, setDeleteJob] = useState(false);
  const [jobData, setJobData] = useState();
  const [jobDataDelete, setJobDataDelete] = useState();
  const editThisJob = (jobDetails) => {
    setJobData(jobDetails)
    setEditJob(true)
  }
  const deleteThisJob = (jobDetails) => {
    setJobDataDelete(jobDetails)
    setDeleteJob(true)
  }
  const [profilesTable, setProfilesTable] = useState(
    drafts?
    {columns: [t("Job Title"), t("Date Of adding"), t("Next step"), "", ""]}:
    {columns: [t("Job Title"), t("Total Applicants"), t("Total Views"), t("Expires"), "", "", ""]}
  );

  useEffect(() => {
    console.log("jobs", jobs)
    setProfilesTable(
      drafts?
      {columns: [t("Job Title"), t("Date Of adding"), t("Next step"), "", ""]}:
      {columns: [t("Job Title"), t("Total Applicants"), t("Total Views"), t("Expires"), "", "", ""]}
    );
  }, [jobs]);

  return (
    <div className="col-12">
      <div style={{backgroundColor:'transparent', boxShadow:'unset'}} className="card">
      {page?
            null:
        <div style={{padding:'0px', backgroundColor:'transparent', borderBottom: 'none'}} className="card-header">
          <h4 className="card-title">{t("Live Jobs")}</h4>
          <div>
            <button
              // style={{ marginLeft: lng === 'arab' ? "15px" : "0px", marginRight: lng === 'arab' ? "0px" : "15px"  }}
              type="button"
              class="btn btn-outline-primary btn-margin"
              onClick={() => history.push("/manage-jobs")}
            >
              {t('All Jobs')}
            </button>
            <button type="button" class="btn btn-outline-primary" onClick={() => history.push('/new-job')}>
              {t('Post New Job')}
            </button>
          </div>
        </div>}
        <div style={{padding:'0px', backgroundColor:'#fff', borderRadius:'10px', boxShadow: '#d3d3d340 1px 0px 8px', minHeight:'20vh', display:'flex', alignItems:'center', justifyContent:'center'}} className="card-body mt-3">
        {loadingModalShow?
				<div style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight:'306px'}}>
				<Loading/>
				</div>:
        !loadingModalShow && jobs.length === 0?
          <h5>{drafts? t("You don't have any drafts"): t("You don't have any Active jobs")}</h5>:
          <div style={{borderRadius: '10px', width:'100%'}} className="table-responsive">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table id="example" className="display dataTable">
                <thead>
                  <tr role="row" style={{ backgroundColor: "#d7e0d7", borderRadius: '10px' }}>
                    {profilesTable.columns.map((d, i) => (
                      <th key={i}>{d}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jobs?.map((d, i) => (
                    <tr key={i}>
                      <td
                        style={{ fontWeight: 700, padding:'10px', cursor:'pointer' }}
                        onClick={() => history.push(`/applicants?id=${d?.id}`)}
                      >
                        {d?.title}
                      </td>
                      {drafts?
                      <>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.start_date}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>
                        {
                        d?.requirements_done === '0'?
                        t("Requirements"):
                        d?.benefits_done === '0'?
                        t("Benefits"):
                        t("Submitting")
                        }</td>
                      </>:
                      <>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.applications_count}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.views}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.close_date}</td>
                      </>
                    }
                    <td>
                      <i 
                        style={
                          d?.job_status_id && d?.job_status_id != 1?
                          {opacity: '0.3', cursor: 'unset'}:
                          null
                        }
                        onClick={
                          () => d?.job_status_id && d?.job_status_id != 1? null:deleteThisJob(d)
                        }  
                        className="fa fa-window-close" 
                        aria-hidden="true" 
                        title={drafts? t("Delete job"):t("Close job")}/>
                    </td>
                    {drafts?
                      null:
                      <td>
                        <i className="fa fa-eye" aria-hidden="true" title={t("view job")}></i>
                      </td>
                    }
                      <td>
                        <i onClick={() => editThisJob(d)} className="fa fa-pencil fa-fw" aria-hidden="true" title={t("edit job")}></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {firstFive || !setPageNumber || !numbersOfPages?
              null:
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info"></div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link onClick={() => pageNumber === 1? null: setPageNumber(currentPage - 1)} className={pageNumber === 1? "paginate_button previous disabled":"paginate_button previous"} to="#">
                    {lng === "arab" ? (
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-double-left"
                        aria-hidden="true"
                      ></i>
                    )}
                  </Link>
                  <span>
                    {numbersOfPages.map((number, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={pageNumber === number? "paginate_button active":"paginate_button"}
                        onClick={() => pageNumber === number? null:setPageNumber(number)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link onClick={() => nextPage === 1? null:setPageNumber(nextPage)} className={nextPage === 1? "paginate_button next disabled":"paginate_button next"} to="#">
                    {lng === "arab" ? (
                      <i
                        className="fa fa-angle-double-left"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    )}
                  </Link>
                </div>
              </div>}
            </div>
          </div>
          }
        </div>

      </div>
      <EditJobModal
        show={editJob}
        onHide={() => { 
          dispatch({ type: CLEAR_TEMP });
          setForceLoad(forceLoad + 1)
          setEditJob(false);     
        }}
        data={jobData}
      />
      <DeleteJobModal
        show={deleteJob}
        onHide={() => { 
          setForceLoad(forceLoad + 1)
          setDeleteJob(false);     
        }}
        data={jobDataDelete}
      />
    </div>
  );
};

export default JobDataTable;

const EditJobModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [jobModalLoading, setJobLoadingModal] = useState(true)
  const getJobData = async (element) => {
    await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: [element] });
    setJobLoadingModal(false)
  }
  useEffect(() => {
    if (props.data) {
      getJobData(props.data)
    }
  }, [props.data])
  return (
    <Modal {...props} centered size="lg" className={`fade applicantModal ${lng === 'arab'? 'forceArabicFont':'forceEnglishFont'}`}>
      <Modal.Body style={{padding:'15px'}}>
        <NewJob jobModalLoading={jobModalLoading} editPost jobId={props.data} />
      </Modal.Body>
    </Modal>
  );
};

const DeleteJobModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(true)
  const getJobData = async (element) => {
    setDeleteLoading(false)
  }
  const closeJobInner = async (jobID) => {
    setDeleteLoading(true)
    if (props.data?.job_status_id == 1) {
      await dispatch(changeJobStatus(jobID, 'Closed', setDeleteLoading));
    } else {
      await dispatch(deleteJob(jobID, setDeleteLoading));
    }
    props.onHide()
  }
  useEffect(() => {
    if (props.data) {
      getJobData(props.data)
    }
  }, [props.data])
  return (
    <Modal {...props} centered size="sm" className={`fade applicantModal ${lng === 'arab'? 'forceArabicFont':'forceEnglishFont'}`}>
      <Modal.Body  >
      {deleteLoading ?
        <div
          style={{
            minHeight: "98px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      : 
        <div className="delet_modal_body">
          <h4 className="mb-4">{t("Are you sure?")}</h4>
          <div className="delete_btns">
            <button onClick={() => props.onHide()} className="btn btn-light">
              {t("No")}
            </button>
            <button onClick={() => closeJobInner(props.data?.id)} className="btn btn-primary">
              {t("Yes")}
            </button>
          </div>
        </div>}
      </Modal.Body>
    </Modal>
  );
};