import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import { useHistory } from "react-router";

const Other_Info = ({editPost, editPostSubmitHandler, back, setOtherInfo, handleOtherInfoChange, otherInfo, submitNewJobStep4, setLoadingModalShow}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <>
    <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">
            {t("Other Information")}
        </h4>
    </div>
    <div className="row">
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body">
                  <div className="row">
                      <div className="col-xl-6  col-md-6 mb-4">
                      <label className="form-label font-w600">
                          {t("Number of vacancies")}
                      </label>
                      <input
                        name="number_of_vacancies"
                        value={otherInfo.number_of_vacancies}
                        onChange={(e) => handleOtherInfoChange({target: {value:e.target.value.replace(/\D/, ""), name:'number_of_vacancies'}})}
                        type="text"
                        className="form-control solid forceEnglishFont"
                        placeholder={t("1")}
                        aria-label="name"
                      />
                    </div>
                    <div className="row">
                    <div style={{display:'flex', gap:'7px'}} className="col-xl-12 mt-4 mb-4 col-md-12 mb-12">
                        <input  style={{float:'unset', marginRight:'unset', marginLeft:'unset'}}
                        checked={otherInfo.apply_by_website == '1'} 
                        value={otherInfo.apply_by_website} 
                        onChange={()=> handleOtherInfoChange({target: {value: otherInfo.apply_by_website == '0'? '1':'0', name:'apply_by_website'}})}
                        className="form-check-input" type="checkbox" />
                        <label className="form-label font-w600">
                            {t("Candidates will apply by visiting our website")}
                        </label>
                      </div>
                      {otherInfo.apply_by_website == '1'?
                    <div className="col-xl-6  col-md-6 mb-4">
                       <input
                        name="job_application_link"
                        value={otherInfo.job_application_link}
                        onChange={handleOtherInfoChange}
                        type="text"
                        className="form-control solid forceEnglishFont"
                        placeholder={t("Job Application Link")}
                        aria-label="name"
                        />
                    </div>:null}
                    </div>
                    <div className="col-xl-12  col-md-12 mb-12">
                      <label className="form-label font-w600">
                          {t("Notes to Madares Team")}
                      </label>
                      <textarea
                        className="form-control solid"
                        rows="4"
                        value={otherInfo.notes_to_madares_team}
                        name='notes_to_madares_team'
                        onChange={handleOtherInfoChange}
                      ></textarea>
                    </div>
                    <div style={{display:'flex', gap:'7px'}} className="col-xl-12 mt-4 mb-4 col-md-12 mb-12">
                        <input  style={{float:'unset', marginRight:'unset', marginLeft:'unset'}}
                        checked={otherInfo.agree_terms == '1'} 
                        value={otherInfo.agree_terms} 
                        onChange={()=> handleOtherInfoChange({target: {value: otherInfo.agree_terms == '0'? '1':'0', name:'agree_terms'}})}
                        className="form-check-input" type="checkbox" />
                        <label className="form-label font-w600">
                            {t("I agree to the") + ' '}<a href="/">{t("Job posting Terms and Conditions")}</a> 
                        </label>
                      </div>
                        </div>
                    <div className="row">
                    <div className="card-footer text-end">
                      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                      <button
                          className="btn btn-light"
                          onClick={() => back(2)}
                        >
                          {t("Back")}
                        </button>
                        <button
                          to={"#"}
                          className="btn btn-primary"
                          onClick={editPost? editPostSubmitHandler: submitNewJobStep4}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
  );
};
export default Other_Info;
