import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../../layouts/loadingComponents/Loading";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import { CountryDropdown } from "react-country-region-selector";
import { addTeacherLicense } from "../../../../../store/actions/teachers";
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
const AddLicenseModal = ({ show, onHide, grades, subjects, licenseTypes, setView, forceLoad, setForceLoad  }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teacherLicense, setTeacherLicense] = useState({
    country: "",
    has_expiration_date: "1",
    grades: [],
    subjects: [],
    place_of_issuance: "",
    state: "",
    teaching_license_type_id: "1",
    valid_from: new Date(),
    valid_to: new Date(),
  });

  // Submit Button Handlers
  const submitLicenseForm = () => {
    let userData = [teacherLicense];
    userData[0].valid_from = formatDate(teacherLicense?.valid_from);
    userData[0].valid_to =
      teacherLicense.has_expiration_date === "1"
        ? formatDate(teacherLicense?.valid_to)
        : "";
    let errorArr = [];
    if (!userData[0].country) {
      errorArr.push(t("Country is required"));
    }
    if (!userData[0].place_of_issuance) {
      errorArr.push(t("Place of issuance is required"));
    }
    if (!userData[0].teaching_license_type_id) {
      errorArr.push(t("License type required"));
    }
    if (!userData[0].valid_from) {
      errorArr.push(t("Valid from required"));
    }
    if (userData[0].subjects.length === 0) {
      errorArr.push(t("Subjects is required"));
    }
    if (userData[0].grades.length === 0) {
      errorArr.push(t("Grade is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    setLoading(true);
    dispatch(addTeacherLicense(userData, setLoading, onHide, setView, forceLoad, setForceLoad));
  };

  return (
    <Modal show={show} onHide={onHide} centered size="md" className="fade">
      <Modal.Header>
        <h3>{t("Add License")}</h3>
      </Modal.Header>
      <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-6 col-md-6 mb-4">
                <label className="form-label font-w600">
                  {t("Place of Issurance")}
                </label>
                <input
                  type="text"
                  className="form-control solid"
                  placeholder={t("Name")}
                  value={teacherLicense.place_of_issuance}
                  name="place_of_issuance"
                  onChange={(e) =>
                    setTeacherLicense({
                      ...teacherLicense,
                      place_of_issuance: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-xl-6 col-md-6 mb-4">
                <label className="form-label font-w600">
                  {t("License Type")}
                </label>
                <select
                  value={teacherLicense.teaching_license_type_id}
                  name="teaching_license_type_id"
                  onChange={(e) =>
                    setTeacherLicense({
                      ...teacherLicense,
                      teaching_license_type_id: e.target.value,
                    })
                  }
                  className="form-control solid"
                >
                  <option value="">{t("Select")}</option>
                  {licenseTypes?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Grade(s) you are licensed to teach")}
                </label>
                <Select
                  value={teacherLicense.grades}
                  name="grades"
                  onChange={(e) =>
                    setTeacherLicense({ ...teacherLicense, grades: e })
                  }
                  placeholder={t("Select Grade(s)")}
                  options={grades?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Subject(s) you are licensed to teach")}
                </label>
                <Select
                  value={teacherLicense.subjects}
                  name="subjects"
                  onChange={(e) =>
                    setTeacherLicense({ ...teacherLicense, subjects: e })
                  }
                  placeholder={t("Select Subject(s)")}
                  options={subjects?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Country your license was issued in")}
                </label>
                <CountryDropdown
                  className="form-control solid"
                  defaultOptionLabel={t("Select Country")}
                  value={teacherLicense.country}
                  name="country"
                  onChange={(e) =>
                    setTeacherLicense({ ...teacherLicense, country: e })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-md-6 mb-4">
                <label className="form-label font-w600">
                  {t("Valid from")}
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    className="form-control solid"
                    name="valid_from"
                    format="dd/MM/yyyy"
                    value={teacherLicense.valid_from}
                    onChange={(date) =>
                      setTeacherLicense({
                        ...teacherLicense,
                        valid_from: date,
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              {teacherLicense.has_expiration_date == "1" ? (
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="form-label font-w600">{t("Expiry")}*</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      className="form-control solid"
                      name="valid_to"
                      format="dd/MM/yyyy"
                      value={teacherLicense.valid_to}
                      onChange={(date) =>
                        setTeacherLicense({
                          ...teacherLicense,
                          valid_to: date,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div
                style={{ display: "flex", gap: "7px" }}
                className="col-md-12 col-sm-12 col-lg-6 mb-3"
              >
                <input
                  style={{
                    float: "unset",
                    marginRight: "unset",
                    marginLeft: "unset",
                  }}
                  checked={teacherLicense.has_expiration_date == "0"}
                  value={teacherLicense.has_expiration_date}
                  onChange={() =>
                    setTeacherLicense({
                      ...teacherLicense,
                      has_expiration_date:
                        teacherLicense.has_expiration_date == "0" ? "1" : "0",
                    })
                  }
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label">
                  {t("No expiration date")}
                </label>
              </div>
            </div>
            <div className="row">
              <div
                style={{ display: "flex", gap: "7px" }}
                className="col-xl-6  col-md-6 mb-4"
              ></div>
              <div className="card-footer text-end">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link
                    to={"#"}
                    onClick={() => onHide()}
                    className="btn btn-secondry"
                  >
                    {t("Cancel")}
                  </Link>
                  <Link
                    to={"#"}
                    onClick={submitLicenseForm}
                    className="btn btn-primary"
                  >
                    {t("Save")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default AddLicenseModal;
