import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../layouts/loadingComponents/Loading";
import loginImg from "../../../../assets/images/authBC.png";
import {
  logoutActoin,
  reSendVerifyChangeEmail,
} from "../../../../store/actions/Auth";
import { useDispatch } from "react-redux";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const EmailVerfication = () => {
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logoutActoin(setLoadingModalShow));
  };
  const reSend = () => {
    setLoadingModalShow(true);
    dispatch(reSendVerifyChangeEmail(setLoadingModalShow));
  };
  return (
    <div className="login_page">
      <img src={loginImg} alt="login_img" className="login_img" />
      <div className="login_card_wrapper">
        <h3
          className="page_title"
          style={{
            direction: lng === "arab" ? "rtl" : "ltr",
            marginLeft: "unset",
          }}
        >
          {t("Email Verification")}
        </h3>
        <div className="card overflow-hidden">
          <div style={{ padding: "20px" }} className="card-body innerCardBody">
            <div className="green-bg" />
            <div
              className="login_form"
              style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
            >
              {loadingModalShow ? (
                <div
                  style={{
                    minHeight: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="Spinner"
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      minHeight: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="regular_login"
                  >
                    <h5 style={{ color: "gray", textAlign: "center" }}>
                      {t("Please Check Your Email For A Verification Link")}
                    </h5>
                  </div>
                  <div className="haveAnAccount2">
                    <div className="widthMedia form-group mb-3">
                      <Link
                        style={{ fontSize: "11px", color: "gray" }}
                        to="#"
                        onClick={reSend}
                      >
                        {t("Re-send Verification Email")}
                      </Link>
                    </div>
                    <div className="widthMedia form-group mb-3">
                      <Link
                        style={{ fontSize: "11px", color: "gray" }}
                        to="#"
                        onClick={logOut}
                      >
                        {t("Logout")}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailVerfication;
