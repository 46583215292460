import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAcademyPassword } from "../../../../store/actions/applicants";
import Loading from "../../../layouts/loadingComponents/Loading";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import "./setting.css";
const NewSetting = () => {
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const useAuth = useSelector((state) => state?.userDetails?.subTypeData);
  const [userData, setUserData] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
    email: useAuth.email || "",
  });
  const handleSubmit = () => {
    setLoadingModalShow(true);
    let errorArr = [];
    const data = {
      current_password: userData?.current_password,
      password: userData?.password,
      password_confirmation: userData?.password_confirmation,
      email: useAuth?.email
    }
    if (!data.current_password) {
      errorArr.push(t("Current Password is required"));
    }
    if (!data.password) {
      errorArr.push(t("Password is required"));
    }
    if (!data.password_confirmation) {
      errorArr.push(t("Confirm Password is required"));
    }
    if (!data?.email) {
      errorArr.push(t("Email is required"));
    }
    if (data?.password !== data?.password_confirmation) {
      errorArr.push(t("Password Not Matched"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(changeAcademyPassword(data, setLoadingModalShow, res => {
      if(res.data) {
        setUserData({
          current_password: "",
          password: "",
          password_confirmation: "",
        });
      }
    }));
  };

  return (
    <div className="setting">
      <div className="responsive-setting">
      <div className="setting-title">
        Settings
        <div className="setting-form">
          {loadingModalShow ? (
            <div
              style={{
                minHeight: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          ) : (
            <>
              <div className="setting-main">
                <label className="setting-label-1">Current password</label>
                <input
                  type="password"
                  className="setting-input-field"
                  name="current_password"
                  value={userData?.current_password}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      current_password: e.target.value,
                    })
                  }
                />
                <label className="setting-label-1 setting-label-2">
                  New password
                </label>
                <input
                  type="password"
                  className="setting-input-field"
                  name="new_password"
                  value={userData?.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />
                <label className="setting-label-1 setting-label-2">
                  Confirm password
                </label>
                <input
                  type="password"
                  className="setting-input-field"
                  name="confirm_password"
                  value={userData?.password_confirmation}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      password_confirmation: e.target.value,
                    })
                  }
                />
                <label className="setting-label-1 setting-label-2">
                  Email Address
                </label>
                <input
                  type="email"
                  className="setting-input-field"
                  name="email"
                  value={useAuth?.email}
                  disabled
                />
              </div>
              <div className="setting-btn">
                <button
                  className="setting-submit-btn"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default NewSetting;
