import axios from "axios";
export const baseURL = "https://api.madaresweb.mvp-apps.ae/api/website/";
export const base_API_URL = "https://api.madaresweb.mvp-apps.ae/api/";
let userTokin = localStorage.getItem("token");

export const AcademyApplicants = async (userData, lang, pageNumber) => {
  let finalFormData = new FormData();
  userData = { ...userData, lang: lang, page: pageNumber };
  for (const item in userData) {
    if (item === "filters") {
      for (const innerItem in userData[item]) {
        if (innerItem === "date_applied" || innerItem === "experience") {
          finalFormData.append(
            `${item}[${innerItem}]`,
            userData[item][innerItem]
          );
        } else {
          for (let i = 0; i < userData[item][innerItem].length; i++) {
            finalFormData.append(
              `${item}[${innerItem}][${i}]`,
              userData[item][innerItem][i]["value"]
            );
          }
        }
      }
    } else {
      finalFormData.append(`${item}`, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/academyApplicants`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
export const changeApplicationStatus = async (userData, lang) => {
  let finalFormData = new FormData();
  userData = { ...userData, lang: lang };
  for (const item in userData) {
    if (item === "changes") {
      for (let i = 0; i < userData[item].length; i++) {
        for (const innerItem in userData[item][i]) {
          finalFormData.append(
            `${item}[${i}][${innerItem}]`,
            userData[item][i][innerItem]
          );
        }
      }
    } else {
      finalFormData.append(`${item}`, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/changeApplicationStatus`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const academyInfo = async (lang) => {
  return await axios.get(`${base_API_URL}academyInfo?lang=${lang}`);
};

export const removeAvatar = async () => {
  return await axios.delete(`${base_API_URL}removeAvatar`);
};

export const removeBanner = async () => {
  return await axios.delete(`${base_API_URL}removeBanner`);
};

export const updateAcademyGeneralProfile = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "types") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  // finalFormData.delete('types');
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/updateAcademyInfo`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const academyType = async (lang) => {
  return await axios.post(`${base_API_URL}academyType?lang=${lang}`);
};

export const academyFile = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "documents") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/addFile`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const destoryAcademyFiles = async (file_id) => {
  return await axios.delete(`${base_API_URL}destroyFile?file_id=${file_id}`);
};

export const academyPassword = async (data) => {
  return await axios.post(`${base_API_URL}auth/changePassword`, data);
};

export const bestCandidates = async (job_id) => {
  return await axios.post(`${base_API_URL}AI?job_id=${job_id}`);
};

export const getStatistics = async (start_date, end_date, lng) => {
  return await axios.post(
    `${base_API_URL}statistics?start_date=${start_date}&end_date=${end_date}&lang=${lng}`
  );
};

export const databaseAccess = async(lang, isStarred) => {
  let finalFormData = new FormData();
  finalFormData.append("lang", lang)
  if(isStarred !== 2) {
    finalFormData.append("filters[is_starred]", isStarred)
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/allApplicants`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
}

export const starApplicants = async(teacher_id) => {
  return await axios.post(`${base_API_URL}starred?teacher_id=${teacher_id}`)
}