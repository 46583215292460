import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../../../layouts/loadingComponents/Loading";
import saveAs from "save-as";
import "../../homePage/HomePage.css";
const NewApplicantionDataTable = ({
  setPageNumber,
  applicants,
  numbersOfPages,
  setApplicantPopUp,
  setApplicantsData,
  flag,
  firstFive,
  loadingModalShow,
  forAPage,
  currentPage,
  pageNumber,
  nextPage,
  handleCheck,
  checkedUsers,
  applicantsStatus,
  applicationStatus,
  setChangeAllTo,
  handleSubmitStatus,
  changeAllTo,
  star,
}) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [checkedElements, setCheckedElements] = useState(checkedUsers);
  useEffect(() => {
    setCheckedElements(checkedUsers);
    console.log(checkedUsers);
  }, [checkedUsers]);
  const [profilesTable, setProfilesTable] = useState({
    data: [],
    columns: [
      "",
      t("Name"),
      t("Citizenship"),
      t("Exp."),
      t("Education"),
      t("Job Applied"),
      t("Date"),
      "",
    ],
  });
  const history = useHistory();

  useEffect(() => {
    setProfilesTable({
      data: applicants?.map((item) => {
        return [
          item?.name,
          item?.citizenship,
          item?.exp,
          item?.education,
          item?.job_applied,
          item?.date,
          "",
        ];
      }),
      columns: [
        "",
        t("Name"),
        t("Citizenship"),
        t("Exp."),
        t("Education"),
        t("Job Applied"),
        t("Date"),
        "",
      ],
    });
  }, [applicants]);

  const popFun = (item) => {
    setApplicantPopUp(true);
    setApplicantsData(item);
  };
  async function downloadFile(displayedElement) {
    fetch(
      `https://api.madaresweb.mvp-apps.ae/api/download?path=${displayedElement?.teacher?.resume?.resume_url}&type=TR`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) =>
        saveAs(
          blob,
          `${
            displayedElement?.teacher?.first_name +
            "_" +
            displayedElement?.teacher?.last_name +
            "_resume"
          }`
        )
      );
  }
  return (
    <>
      {!flag ? (
        <div className="row d-flex align-items-center">
          <div className="col-lg-4 col-sm-12">
            <p className="title semi-bold-black">Most Recent Application</p>
          </div>
          <div className="col-lg-8 col-sm-12 d-flex justify-content-end">
            <button
              className="btn-2 semi-bold-white"
              onClick={() => history.push("/applicants")}
            >
              All Applicants
            </button>
          </div>
        </div>
      ) : null}
      {loadingModalShow ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "306px",
          }}
        >
          <Loading />
        </div>
      ) : !loadingModalShow && applicants.length === 0 ? (
        <div className="no-data-message">
          <h5
            style={{
              color: "black",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "15px",
            }}
          >
            {t("You don't have any Applicants")}
          </h5>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                {firstFive ? null : !applicationStatus ? null : <th></th>}
                <th></th>
                <th>{t("Name")}</th>
                <th>{t("Citizenship")}</th>
                <th>{t("Exp")}</th>
                <th>{t("Education")}</th>
                <th>{t("Job Applied")}</th>
                <th>{t("Date")}</th>
              </tr>
            </thead>
            <tbody>
              {applicants?.map((d, i) => (
                <tr>
                  {firstFive ? null : !applicationStatus ? null : (
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => handleCheck(d?.job?.id, d?.id)}
                        checked={
                          checkedElements.filter(
                            (item) => item.application_id == d?.id
                          ).length > 0
                        }
                        className="form-check-input mt-2"
                        id="check1"
                        required
                      />
                    </td>
                  )}

                  <td onClick={() => history.push(`applicant-profile?id=${d?.id}`)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                    >
                      <path
                        id="Path_987"
                        data-name="Path 987"
                        d="M556.728,232.258h-5.5a4.4,4.4,0,0,0-4.4,4.4v13.2a4.4,4.4,0,0,0,4.4,4.4h13.2a4.4,4.4,0,0,0,4.4-4.4v-5.5a1.1,1.1,0,1,0-2.2,0v5.5a2.2,2.2,0,0,1-2.2,2.2h-13.2a2.2,2.2,0,0,1-2.2-2.2v-13.2a2.2,2.2,0,0,1,2.2-2.2h5.5a1.1,1.1,0,0,0,0-2.2Zm8.345,2.2h-3.945a1.1,1.1,0,1,1,0-2.2h6.6a1.1,1.1,0,0,1,1.1,1.1v6.6a1.1,1.1,0,1,1-2.2,0v-3.945l-8.022,8.022a1.1,1.1,0,0,1-1.555-1.555Z"
                        transform="translate(-546.828 -232.258)"
                        fill="#4e4e4e"
                        fillRule="evenodd"
                      />
                    </svg>
                  </td>
                  <td className="applicant-name" onClick={() => popFun(d)}>
                    {d?.teacher?.first_name + " " + d?.teacher?.last_name}
                  </td>
                  <td>
                    {d?.teacher?.citizenships?.slice(0, 1)?.shift()?.name ||
                      "-"}
                  </td>
                  <td>
                    {d?.teacher?.total_teaching_experience_years === "0" &&
                    d?.teacher?.total_teaching_experience_months === "0"
                      ? "-"
                      : (d?.teacher?.total_teaching_experience_years !== "0"
                          ? d?.teacher?.total_teaching_experience_years +
                            t("Years")
                          : "") +
                        (d?.teacher?.total_teaching_experience_years !== "0" &&
                        d?.teacher?.total_teaching_experience_months !== "0"
                          ? t("and")
                          : "") +
                        (d?.teacher?.total_teaching_experience_months !== "0"
                          ? d?.teacher?.total_teaching_experience_months +
                            t("Months")
                          : "")}
                  </td>
                  <td>{d?.teacher?.highest_education_degree?.title || "-"}</td>
                  <td>{d?.job?.title || "-"}</td>
                  <td>{d?.apply_date || "-"}</td>
                  <td>
                    <div
                      className="circle"
                      onClick={() =>
                        d?.teacher?.allow_share_docs == "0"
                          ? null
                          : downloadFile(d)
                      }
                      style={
                        d?.teacher?.allow_share_docs == "0"
                          ? { opacity: "0.4" }
                          : null
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.859"
                        height="12.859"
                        viewBox="0 0 12.859 12.859"
                      >
                        <path
                          id="download"
                          d="M12.859,12.056a.8.8,0,0,1-.8.8H.8a.8.8,0,1,1,0-1.607H12.056A.8.8,0,0,1,12.859,12.056Zm-7-2.546A.8.8,0,0,0,7,9.51L9.845,6.662A.8.8,0,1,0,8.709,5.525L7.233,7V.8A.8.8,0,1,0,5.626.8V7L4.15,5.525A.8.8,0,0,0,3.014,6.662Z"
                          fill="#fc563e"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                </td>
              </tr>
            </tfoot>
          </table>
          {firstFive ? null : (
            <>
              <div className="table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                {!applicationStatus ? null : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {checkedElements.length > 0 ? (
                        <>
                          <label
                            style={{
                              padding: "10px 10px 0px 10px",
                              margin: "0px",
                            }}
                          >
                            {t("Items Selected:") +
                              " " +
                              checkedElements.length}
                          </label>
                          <div className="profile-modal-footer">
                            <select
                              disabled={
                                applicationStatus == 3 || applicationStatus == 7
                              }
                              value={changeAllTo}
                              onChange={(e) => setChangeAllTo(e.target.value)}
                              style={{ margin: "0px" }}
                              className="profile-modal-footer-select"
                            >
                              {applicantsStatus?.map((item, i) => (
                                <option
                                  disabled={
                                    item?.id == 1 ||
                                    item?.id == applicationStatus ||
                                    (applicationStatus == 4 && item?.id == 1) ||
                                    (applicationStatus == 4 && item?.id == 2) ||
                                    (applicationStatus == 5 && item?.id == 1) ||
                                    (applicationStatus == 5 && item?.id == 2) ||
                                    (applicationStatus == 5 && item?.id == 4) ||
                                    (applicationStatus == 6 && item?.id == 1) ||
                                    (applicationStatus == 6 && item?.id == 2) ||
                                    (applicationStatus == 6 && item?.id == 4) ||
                                    (applicationStatus == 6 && item?.id == 5)
                                  }
                                  key={i}
                                  value={item.id}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                            <button
                              onClick={handleSubmitStatus}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              // className="btn btn-primary"
                              className="btn-2 semi-bold-white"
                            >
                              {t("Submit")}
                            </button>
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <Link
                        onClick={() =>
                          pageNumber === 1
                            ? null
                            : setPageNumber(currentPage - 1)
                        }
                        className={
                          pageNumber === 1
                            ? "paginate_button previous disabled"
                            : "paginate_button previous"
                        }
                        to="#"
                      >
                        {lng === "arab" ? (
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-angle-double-left"
                            aria-hidden="true"
                          ></i>
                        )}
                      </Link>
                      <span>
                        {numbersOfPages.map((number, i) => (
                          <Link
                            key={i}
                            to="#"
                            className={
                              pageNumber === number
                                ? "paginate_button active"
                                : "paginate_button"
                            }
                            onClick={() =>
                              pageNumber === number
                                ? null
                                : setPageNumber(number)
                            }
                          >
                            {number}
                          </Link>
                        ))}
                      </span>

                      <Link
                        onClick={() =>
                          nextPage === 1 ? null : setPageNumber(nextPage)
                        }
                        className={
                          nextPage === 1
                            ? "paginate_button next disabled"
                            : "paginate_button next"
                        }
                        to="#"
                      >
                        {lng === "arab" ? (
                          <i
                            className="fa fa-angle-double-left"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>
                        )}
                      </Link>
                    </div>
                  </div>
                  
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {firstFive ? (
        <div
          className="view-title semi-bold-black"
          onClick={() => history.push("/applicants")}
        >
          View All recent Applicants
        </div>
      ) : null}
    </>
  );
};

export default NewApplicantionDataTable;
