import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../../../../layouts/loadingComponents/Loading'
import DateFnsUtils from "@date-io/date-fns";
import { useDispatch } from 'react-redux'
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";
import { deleteTeacherEducation, editTeacherEducation } from "../../../../../store/actions/teachers";

const EditEducationModal = ({ show, onHide, data, degrees, setView, forceLoad, setForceLoad}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teacherEducation, setTeacherEducation] = useState({
        education_id: data?.education_id || "",
        degree_level_id: data?.degree_level_id || " 1",
        title: data?.title || "",
        college: data?.college || "",
        year_of_graduation: data?.year_of_graduation || new Date(),
    });
    useEffect(() => {
        setTeacherEducation({
            education_id: data?.education_id || "",
            degree_level_id: data?.degree_level_id || " 1",
            title: data?.title || "",
            college: data?.college || "",
            year_of_graduation: data?.year_of_graduation || new Date(),
        });
    }, [data]);

    // Button Click Handlers
    const submitEducationForm = () => {
        setLoading(true);
        let userData = {
            ...teacherEducation,
            year_of_graduation: new Date(
                teacherEducation.year_of_graduation
            ).getFullYear(),
        };
        let errorArr = [];
        if (!userData.degree_level_id) {
            errorArr.push(t("Degree Level is required"));
        }
        if (!userData.title) {
            errorArr.push(t("Title is required"));
        }
        if (!userData.college) {
            errorArr.push(t("Collage is required"));
        }
        if (!userData.year_of_graduation) {
            errorArr.push(t("Year of graduation is required"));
        }
        if (errorArr.length > 0) {
            toastAlert(errorArr, undefined, "warning");
            setLoading(false);
            return false;
        }
        dispatch(editTeacherEducation(userData, setLoading, onHide, setView, forceLoad, setForceLoad));
    }
    const deleteEducation = () => {
        setLoading(true);
        dispatch(
            deleteTeacherEducation(teacherEducation?.education_id, setLoading, onHide, setView, forceLoad, setForceLoad)
        );
    }
    return (
        <Modal show={show} onHide={onHide} centered size="md" className="fade">
            <Modal.Header>
                <h3>{t('Edit Education')}</h3>
            </Modal.Header>
            <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
                {loading ? (
                    <div
                        style={{
                            minHeight: "500px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                    {t("Degree level")}
                                </label>
                                <select
                                    name="degree_level_id"
                                    className="form-control solid"
                                    value={teacherEducation?.degree_level_id}
                                    onChange={(e) =>
                                        setTeacherEducation({
                                            ...teacherEducation,
                                            degree_level_id: e.target.value,
                                        })
                                    }
                                >
                                    {degrees?.map((item, i) => (
                                        <option key={i} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-xl-6  col-md-6 mb-4">
                                <label className="form-label font-w600">
                                    {t("Field of study")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control solid"
                                    placeholder={t("Math")}
                                    name="title"
                                    value={teacherEducation?.title}
                                    onChange={(e) =>
                                        setTeacherEducation({
                                            ...teacherEducation,
                                            title: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12  col-md-12 mb-4">
                                <label className="form-label font-w600">
                                    {t("Place of Issurance")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control solid"
                                    placeholder={t("University Name")}
                                    name="college"
                                    value={teacherEducation?.college}
                                    onChange={(e) =>
                                        setTeacherEducation({
                                            ...teacherEducation,
                                            college: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12  col-md-12 mb-4">
                                <label className="form-label font-w600">
                                    {t("Year of graduation")}
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        className="form-control solid"
                                        name="year_of_graduation"
                                        // format="dd/MM/yyyy"
                                        views={["year"]}
                                        // maxDate={`${formatDate(new Date().setFullYear(new Date().getFullYear()-18))}`}
                                        disableFuture
                                        value={teacherEducation?.year_of_graduation}
                                        onChange={(date) =>
                                            setTeacherEducation({
                                                ...teacherEducation,
                                                year_of_graduation: date,
                                            })
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                style={{ display: "flex", gap: "7px" }}
                                className="col-xl-6  col-md-6 mb-4"
                            ></div>
                            <div className="card-footer text-end">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Link
                                        to={"#"}
                                        onClick={deleteEducation}
                                        className="btn btn-primary"
                                    >
                                        {t("Delete")}
                                    </Link>
                                    <Link
                                        to={"#"}
                                        onClick={submitEducationForm}
                                        className="btn btn-primary"
                                    >
                                        {t("Save")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    )
}

export default EditEducationModal