import { t } from "i18next";
import React from "react";

const ChildProtection = () => {
	let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div>
      <div className="child-protection">
        <h3>{t("Child Protection")}</h3>
        <div>
          <p>
            {t(
              "Madares is a proud member of the International Task Force on Child Protection (ITFCP) and were part of the core group of experts tasked with creating the Recommended Screening and Assessment Practices for International Teacher Recruitment."
            )}
          </p>
          <p>
            {t(
              "We invite you to join us and the rest of the international education community in taking additional steps, outlined in the documents below, toward making our schools safer spaces for children."
            )}
          </p>
        </div>
        <div className="child-protection-documents">
          <p className="child-protection-titles">
            <span>{t('Name')}</span>
            <span style={{ float: lng === "arab" ? 'left' : 'right' }}>{t('Last Modified')}</span>
          </p>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: '10px' }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t('International Task Force on Child Protection (ITFCP): Recommended Screening and Assessment Practices for International School Recruitment')}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab"? "left" : 'right' }}>Jun 1, 2018</p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default ChildProtection;
