export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const RE_AUTH = "RE_AUTH";
export const SIGN_UP_FIRST_AUTH = "SIGN_UP_FIRST_AUTH";
export const FETCH_ALL_JOB_LEVELS = "FETCH_ALL_JOB_LEVELS";
export const LOAD_JOB_LEVELS = "LOAD_JOB_LEVELS";
export const LOADED_JOB_LEVELS = "LOADED_JOB_LEVELS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FETCH_ALL_AVAILABILITES = "FETCH_ALL_AVAILABILITES";
export const FETCH_ALL_SKILLS = "FETCH_ALL_SKILLS";
export const LOAD_AVAILABILITIES = "LOAD_AVAILABILITIES";
export const LOADED_AVAILABILITIES = "LOADED_AVAILABILITIES";
export const LOAD_SKILLS = "LOAD_SKILLS";
export const LOADED_SKILLS = "LOADED_SKILLS";
export const AUTH_SUBTYPE = "AUTH_SUBTYPE";
export const LOADED_USER_INFO = "LOADED_USER_INFO";
export const LOADING_USER_INFO = "LOADING_USER_INFO";

// Job
export const FETCH_ALL_ACADEMY_JOBS = "FETCH_ALL_ACADEMY_JOBS";
export const ACADEMY_JOBS_LOAD = "ACADEMY_JOBS_LOAD";
export const ACADEMY_JOBS_LOADED = "ACADEMY_JOBS_LOADED";

export const FETCH_ALL_ACADEMY_DRAFTS = "FETCH_ALL_ACADEMY_DRAFTS";
export const ACADEMY_DRAFTS_LOAD = "ACADEMY_DRAFTS_LOAD";
export const ACADEMY_DRAFTS_LOADED = "ACADEMY_DRAFTS_LOADED";

export const FETCH_JOB = "FETCH_JOB";
export const JOB_LOADED = "JOB_LOADED";
export const LOAD_JOB = "LOAD_JOB";
export const FETCH_TEACHER_JOB = "FETCH_TEACHER_JOB";
export const TEACHER_JOBS_LOADED = "TEACHER_JOBS_LOADED";
export const LOAD_TEACHER_JOBS = "LOAD_TEACHER_JOBS";
export const UPDATE_JOB_STATUS = "UPDATE_JOB_STATUS";
export const DELETE_JOB = "DELETE_JOB";

export const LOADING_SUBJECTS = "LOADING_SUBJECTS";
export const FETCH_ALL_SUBJECTS = "FETCH_ALL_SUBJECTS";
export const LOADED_SUBJECTS = "LOADED_SUBJECTS";

export const LOADING_GRADES = "LOADING_GRADES";
export const GRADES = "GRADES";
export const LOADED_GRADES = "LOADED_GRADES";

export const LOADING_JOB_STATUS = "LOADING_JOB_STATUS";
export const JOB_STATUS = "JOB_STATUS";
export const LOADED_JOB_STATUS = "LOADED_JOB_STATUS";

export const LOAD_ALL_JOB_TITLE = "LOAD_ALL_JOB_TITLE";
export const FETCH_ALL_JOB_TITLES = "FETCH_ALL_JOB_TITLES";
export const APPLICANTS_STATUS_LOADED = "APPLICANTS_STATUS_LOADED";

export const LOADING_LANGUAGES = "LOADING_LANGUAGES";
export const LANGUAGES = "LANGUAGES";
export const LOADED_LANGUAGES = "LOADED_LANGUAGES";

export const LOADING_CURRICULUMS = "LOADING_CURRICULUMS";
export const CURRICULUMS = "CURRICULUMS";
export const LOADED_CURRICULUMS = "LOADED_CURRICULUMS";

export const FETCH_APPLICANTS_STATUS = "FETCH_APPLICANTS_STATUS";
export const LOAD_APPLICANTS_STATUS = "LOAD_APPLICANTS_STATUS";
export const ALL_JOB_TITLES_LOADED = "ALL_JOB_TITLES_LOADED";

export const ADD_TEMPO_JOB_POST_DETAILS = "ADD_JOB_POST_DETAILS";
export const CLEAR_TEMP = "CLEAR_TEMP";

export const JOB_TITLES = "JOB_TITLES";
export const LOADING_JOB_TITLES = "LOADING_JOB_TITLES";
export const LOADED_JOB_TITLES = "LOADED_JOB_TITLES";

export const FETCH_REQUIRED_CERT = "FETCH_REQUIRED_CERT";
export const LOADING_REQUIRED_CERT = "LOADING_REQUIRED_CERT";
export const LOADEN_REQUIRED_CERT = "LOADEN_REQUIRED_CERT";

export const CITIZENSHIPS = "CITIZENSHIPS";
export const LOADING_CITIZENSHIPS = "LOADING_CITIZENSHIPS";
export const LOADED_CITIZENSHIPS = "LOADED_CITIZENSHIPS";

export const DEGREES = "DEGREES";
export const LOADING_DEGREES = "LOADING_DEGREES";
export const LOADEN_DEGREES = "LOADEN_DEGREES";
//End Job

export const LOAD_SIZES = "LOAD_SIZES";
export const FETCH_ALL_SIZES = "FETCH_ALL_SIZES";
export const LOADED_SIZES = "LOADED_SIZES";

export const LOAD_YEARS = "LOAD_YEARS";
export const FETCH_ALL_YEARS = "FETCH_ALL_YEARS";
export const LOADED_YEARS = "LOADED_YEARS";

export const FETCH_HELP_REQUEST = "FETCH_HELP_REQUEST";
export const LOADED_HELP_REQUEST = "LOADED_HELP_REQUEST";
export const LOAD_HELP_REQUEST = "LOAD_HELP_REQUEST";

export const FETCH_BLOG = "FETCH_BLOG";
export const LOADED_BLOG = "LOADED_BLOG";
export const LOAD_BLOG = "LOAD_BLOG";

export const FETCH_APPLICANTS = "FETCH_APPLICANTS";
export const APPLICANTS_LOADED = "APPLICANTS_LOADED";
export const LOAD_APPLICANTS = "LOAD_APPLICANTS";

export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const LOAD_TEACHERS = "LOAD_TEACHERS";
export const LOADED_TEACHERS = "LOADED_TEACHERS";

export const FETCH_ANALYTICS = "FETCH_ANALYTICS";
export const ANALYTICS_LOADED = "ANALYTICS_LOADED";
export const LOAD_ANALYTICS = "LOAD_ANALYTICS";

export const FETCH_JOB_MINIMUM_EXPERIENCE = "FETCH_JOB_MINIMUM_EXPERIENCE";
export const JOB_MINIMUM_EXPERIENCE_LOADED = "JOB_MINIMUM_EXPERIENCE_LOADED";
export const LOAD_JOB_MINIMUM_EXPERIENCE = "LOAD_JOB_MINIMUM_EXPERIENCE";

export const FETCH_SALARY_RATE = "FETCH_SALARY_RATE";
export const SALARY_RATE_LOADED = "SALARY_RATE_LOADED";
export const LOAD_SALARY_RATE = "LOAD_SALARY_RATE";

export const FETCH_SALARY_TYPE = "FETCH_SALARY_TYPE";
export const SALARY_TYPE_LOADED = "SALARY_TYPE_LOADED";
export const LOAD_SALARY_TYPE = "LOAD_SALARY_TYPE";

export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const MESSAGE_LOADED = "MESSAGE_LOADED";
export const LOAD_MESSAGE = "LOAD_MESSAGE";

export const FETCH_READ_MESSAGE = "FETCH_READ_MESSAGE";
export const READ_MESSAGE_LOADED = "READ_MESSAGE_LOADED";
export const LOAD_READ_MESSAGE = "LOAD_READ_MESSAGE";

export const FETCH_SAVED_JOBS = "FETCH_SAVED_JOBS";
export const SAVED_JOBS_LOAD = "SAVED_JOBS_LOAD";
export const SAVED_JOBS_LOADED = "SAVED_JOBS_LOADED";

export const FETCH_TEACHER_APPLICANTIONS = "FETCH_TEACHER_APPLICANTIONS";
export const TEACHER_APPLICANTIONS_LOAD = "TEACHER_APPLICANTIONS_LOAD";
export const TEACHER_APPLICANTIONS_LOADED = "TEACHER_APPLICANTIONS_LOADED";

export const FETCH_ACADEMY_APPLICANTS = "FETCH_ACADEMY_APPLICANTS";
export const ACADEMY_APPLICANTS_LOAD = "ACADEMY_APPLICANTS_LOAD";
export const ACADEMY_APPLICANTS_LOADED = "ACADEMY_APPLICANTS_LOADED";

export const FETCH_ACADEMY_INFO = "FETCH_ACADEMY_INFO";
export const ACADEMY_INFO_LOAD = "ACADEMY_INFO_LOAD";
export const ACADEMY_INFO_LOADED = "ACADEMY_INFO_LOADED";

export const FETCH_ACADEMY_TYPE = "FETCH_ACADEMY_TYPE";
export const ACADEMY_TYPE_LOAD = "ACADEMY_TYPE_LOAD";
export const ACADEMY_TYPE_LOADED = "ACADEMY_TYPE_LOADED";

export const FETCH_TEACHER_INFO = "FETCH_TEACHER_INFO";
export const TEACHER_INFO_LOAD = "TEACHER_INFO_LOAD";
export const TEACHER_INFO_LOADED = "TEACHER_INFO_LOADED";

export const FETCH_GENDER = "FETCH_GENDER";
export const GENDER_LOAD = "GENDER_LOAD";
export const GENDER_LOADED = "GENDER_LOADED";

export const FETCH_LICENSE = "FETCH_LICENSE";
export const LICENSE_LOAD = "LICENSE_LOAD";
export const LICENSE_LOADED = "LICENSE_LOADED";

export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const DOCUMENT_LOAD = "DOCUMENT_LOAD";
export const DOCUMENT_LOADED = "DOCUMENT_LOADED";

export const FETCH_BEST_CANDIDATES = "FETCH_BEST_CANDIDATES";
export const BEST_CANDIDATES_LOAD = "BEST_CANDIDATES_LOAD";
export const BEST_CANDIDATES_LOADED = "BEST_CANDIDATES_LOADED";

export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const STATISTICS_LOAD = "STATISTICS_LOAD";
export const STATISTICS_LOADED = "STATISTICS_LOADED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const NOTIFICATIONS_LOAD = "NOTIFICATIONS_LOAD";
export const NOTIFICATIONS_LOADED = "NOTIFICATIONS_LOADED";

export const FETCH_STAR = "FETCH_STAR";
export const STAR_LOAD = "STAR_LOAD";
export const STAR_LOADED = "STAR_LOADED";

export const FETCH_STAR_APPLICANTS = "FETCH_STAR_APPLICANTS";
export const STAR_APPLICANTS_LOAD = "STAR_APPLICANTS_LOAD";
export const STAR_APPLICANTS_LOADED = "STAR_APPLICANTS_LOADED";

export const FETCH_SUGGESTED = "FETCH_SUGGESTED";
export const SUGGESTED_LOAD = "SUGGESTED_LOAD";
export const SUGGESTED_LOADED = "SUGGESTED_LOADED";