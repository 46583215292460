import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSuggested } from '../../../../store/actions/teachers';
import Loading from '../../../layouts/loadingComponents/Loading';
import { Jobs } from '../home/NewHomeTeacher';

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const Suggested = () => {
    const dispatch = useDispatch();
    const [loadingModalShow, setLoadingModalShow] = useState(true);
    const suggested = useSelector((state) => state?.suggested?.suggested);
    const suggestedLoading = useSelector((state) => state?.suggested?.loading);
    useEffect(() => {
        dispatch(getSuggested(lng === "arab" ? 1 : 2));
    }, []);
    useEffect(() => {
      setLoadingModalShow(suggestedLoading);
    }, [suggestedLoading]);
    console.log("suggested", suggested)
  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="home-teacher-page">
          <div className="home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">
                Suggested For You
              </div>
            </div>
            <Jobs
              title={"There are no jobs :("}
              data={suggested?.slice(0, 3)}
              type={"Suggested"}
            />
          </div>
        </>
      )}
    </Fragment>  )
}

export default Suggested