import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { getJobTitle, getDegreeLevels, getRequiredCertification, getCitizenships } from "../../../../../../store/actions/Jobs";
import Loading from '../../../../../layouts/loadingComponents/Loading'

const Requirements = ({editPostHandler, back, setRequirementsInfo, handleRequirementsInfoChange, requirementsInfo, submitNewJobStep2, editPost}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const jobTitles = useSelector((state) => state?.jobTitles?.jobTitles);
  const jobTitlesLoading = useSelector((state) => state?.jobTitles?.loading);
  const degrees = useSelector((state) => state?.degrees?.degrees);
  const degreesLoading = useSelector((state) => state?.degrees?.loading);
  const requiredCertification = useSelector((state) => state?.requiredCertification?.requiredCertification);
  const requiredCertificationLoading = useSelector((state) => state?.requiredCertification?.loading);
  const citizenships = useSelector((state) => state?.citizenships?.citizenships);
  const citizenshipsLoading = useSelector((state) => state?.citizenships?.loading);
  useEffect(() => {
    setLoadingModalShow(
        jobTitlesLoading ||
        degreesLoading ||
        requiredCertificationLoading ||
        citizenshipsLoading
    )
  }, [jobTitlesLoading, degreesLoading, requiredCertificationLoading, citizenshipsLoading])
  useEffect(() => {
    dispatch(getJobTitle(lng === 'arab'? 1:2))
    dispatch(getDegreeLevels(lng === 'arab'? 1:2))
    dispatch(getRequiredCertification(lng === 'arab'? 1:2))
    dispatch(getCitizenships(lng === 'arab'? 1:2))
  }, [])
  return (
    <>
    <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">
            {t("Requirements")}
        </h4>
    </div>
    <div className="row">
        <div className="col-xl-12">
            <div className="card">
            {loadingModalShow?
              <div
              style={{
                minHeight: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          : 
                <div className="card-body">
                  <div className="row">
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Types of educators who can apply for this job")}
                            </label>
                            <Select
                            value={requirementsInfo.types_of_educators}
                            name='types_of_educators'
                            onChange={(selectedOption)=> handleRequirementsInfoChange({target: {value:selectedOption, name:'types_of_educators'}})}
                            placeholder = {t('Select Type(s)')}
                            options={jobTitles?.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                            }))}
                            isMulti
                            />
                        </div>
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Min. level of education")}
                            </label>
                            <select
                            name="minimum_level_of_education_id"
                            value={requirementsInfo.minimum_level_of_education_id}
                            onChange={handleRequirementsInfoChange}
                            className="form-select form-control solid"
                            >
                                <option value="">{t("Select Level")}</option>
                                {degrees?.map((item, i) => (
                                <option key={i} value={item.id}>
                                {item?.name}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Specific Major")}
                            </label>
                            <input
                            type="text"
                            name="specific_major"
                            value={requirementsInfo.specific_major}
                            onChange={handleRequirementsInfoChange}
                            className="form-control solid"
                            aria-label="name"
                            />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Min. Teaching Experience")}
                            </label>
                            <input
                            name="minimum_teaching_experience"
                            value={requirementsInfo.minimum_teaching_experience}
                            onChange={(e) => handleRequirementsInfoChange({target: {value:e.target.value.replace(/\D/, ""), name:'minimum_teaching_experience'}})}
                            type="text"
                            className="form-control solid forceEnglishFont"
                            placeholder={t("Years")}
                            aria-label="name"
                            />
                        </div>
                    
                    <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w600">
                            {t("Certifications Required")}
                        </label>
                       <Select
                            value={requirementsInfo.required_certifications} 
                            name='required_certifications'
                            onChange={(selectedOption)=> handleRequirementsInfoChange({target: {value:selectedOption, name:'required_certifications'}})}
                            placeholder = {t('Select Certification(s)')}
                            options={requiredCertification?.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                            }))}
                            isMulti
                        />
                    </div>
                    </div>
                    <div className="row">
                    <div style={{display:'flex', gap:'7px'}} className="col-xl-12  col-md-12 mb-12">
                        <input  style={{float:'unset', marginRight:'unset', marginLeft:'unset'}}
                        checked={requirementsInfo.citizenship_match_required == '1'} 
                        value={requirementsInfo.citizenship_match_required} 
                        onChange={()=> handleRequirementsInfoChange({target: {value: requirementsInfo.citizenship_match_required == '0'? '1':'0', name:'citizenship_match_required'}})}
                        className="form-check-input" type="checkbox" />
                        <label className="form-label font-w600">
                            {t("Citizenship Match Required")}
                        </label>
                      </div>
                    <div className="col-xl-6  col-md-6 mb-4">
                    {requirementsInfo.citizenship_match_required == '1'?
                        <Select
                            value={requirementsInfo.citizenships} 
                            name='citizenships'
                            onChange={(selectedOption)=> handleRequirementsInfoChange({target: {value:selectedOption, name:'citizenships'}})}
                            placeholder = {t('Select Citizenship(s)')}
                            options={citizenships?.map((item) => ({
                                label: item?.name,
                                value: item?.id,
                            }))}
                            isMulti
                        />:null}
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-12 mb-4">
                      <label className="form-label font-w600">
                        {t("Other Requirements")}
                      </label>
                      <Rte 
                        changeFunc={setRequirementsInfo} 
                        obj={requirementsInfo}
                        name= 'other_requirements'
                      />
                    </div>
                    <div className="card-footer text-end">
                      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                      <button
                          className="btn btn-light"
                          onClick={() => back(0)}
                        >
                          {t("Back")}
                        </button>
                        <button
                          to={"#"}
                          className="btn btn-primary"
                          onClick={ editPost? editPostHandler : submitNewJobStep2}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </>
  );
};
export default Requirements;
