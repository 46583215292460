import React from "react";
import { Modal } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import image from "../../../../../assets/images/1.jpg";
const type = "jpg";
const ViewDocumentModal = ({ show, onHide, data }) => {
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  return (
    <Modal show={show} onHide={onHide} centered size="md" className="fade">
      <Modal.Header>View Document</Modal.Header>
      <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
        {/* <FileViewer fileType={data?.extension} filePath={"http://api.madaresweb.mvp-apps.ae" + data?.document_path} onError={onError} /> */}
        {/* <FileViewer fileType="docx" filePath="Resume.docx" onError={onError} /> */}
        <div style={{ height: "100%" }}>
        <FileViewer fileType={type} filePath={image} onError={onError} />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default ViewDocumentModal;
