import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";
import profileImg from "../../../../assets/images/1.jpg";
import arrow from "../../../../assets/images/side menu icons/right-arrow.png";
import share from "../../../../assets/images/side menu icons/share.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import saveAs from "save-as";
import { updateApplicationStatus } from "../../../../store/actions/applicants";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import Loading from "../../../layouts/loadingComponents/Loading";
import { getApplicationStatus } from "../../../../store/actions/Jobs";

const BestCandidateList = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApplicationStatus(lng === "arab" ? 1 : 2));
  }, []);
  const applicantsStatus = useSelector(
    (state) => state?.applicantsStatus?.applicantsStatus
  );
  const applicantsStatusLoading = useSelector(
    (state) => state?.applicantsStatus?.loading
  );
  const [openEducation, setOpenEducation] = useState(true);
  const [openCertificate, setOpenCertificate] = useState(true);
  const [openTeacher, setOpenTeacher] = useState(true);
  const [loadingModalShow, setLoadingModalShow] = useState(
    applicantsStatusLoading
  );
  const [displayedElement, setDisplayedElement] = useState(
    props?.data[props?.data?.findIndex((item) => item?.id === props?.item?.id)]
  );
  const [applicationStatusID, setApplicationStatusID] = useState(
    parseInt(displayedElement?.application_status_id)
  );
  useEffect(() => {
    setLoadingModalShow(applicantsStatusLoading);
  }, [applicantsStatusLoading]);
  useEffect(() => {
    setDisplayedElement(
      props?.data[
        props?.data?.findIndex((item) => item?.id === props?.item?.id)
      ]
    );
  }, [props?.item, props?.data]);
  useEffect(() => {
    setApplicationStatusID(parseInt(displayedElement?.application_status_id));
    if (displayedElement?.application_status_id == "1") {
      let userData = {
        changes: [
          {
            application_id: displayedElement?.id,
            job_id: displayedElement?.job?.id,
          },
        ],
        status: 2,
      };
      dispatch(
        updateApplicationStatus(
          userData,
          lng === "arab" ? 1 : 2,
          setLoadingModalShow,
          props.setForceLoad,
          props.forceLoad
        )
      );
    }
  }, [displayedElement]);
  const previousApplicant = () => {
    let cuurentElementIndex = props?.data?.findIndex(
      (item) => item?.id === displayedElement?.id
    );
    let nextIndex =
      0 === cuurentElementIndex
        ? props?.data?.length - 1
        : cuurentElementIndex - 1;
    setDisplayedElement(props?.data[nextIndex]);
  };
  const nextApplicant = () => {
    let cuurentElementIndex = props?.data?.findIndex(
      (item) => item?.id === displayedElement?.id
    );
    let nextIndex =
      props?.data?.length - 1 === cuurentElementIndex
        ? 0
        : cuurentElementIndex + 1;
    setDisplayedElement(props?.data[nextIndex]);
  };
  // const [statusData, setStatusData] = useState({
  //   changes: [],
  //   status: ''
  // })
  const handleSubmitStatus = () => {
    setLoadingModalShow(true);
    if (applicationStatusID == displayedElement?.application_status_id) {
      setLoadingModalShow(false);
      return toastAlert("Please select different status", undefined, "success");
    }
    let userData = {
      changes: [
        {
          application_id: displayedElement?.id,
          job_id: displayedElement?.job?.id,
        },
      ],
      status: applicationStatusID,
    };
    dispatch(
      updateApplicationStatus(
        userData,
        lng === "arab" ? 1 : 2,
        setLoadingModalShow,
        props.setForceLoad,
        props.forceLoad
      )
    );
  };
  async function viewFile() {
    fetch(
      `https://api.madaresweb.mvp-apps.ae/api/viewFile?path=${displayedElement?.teacher?.resume?.resume_url}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => window.open(URL.createObjectURL(blob), "_blank"));
  }
  async function downloadFile() {
    fetch(
      `https://api.madaresweb.mvp-apps.ae/api/download?path=${displayedElement?.teacher?.resume?.resume_url}&type=TR`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) =>
        saveAs(
          blob,
          `${
            displayedElement?.teacher?.first_name +
            "_" +
            displayedElement?.teacher?.last_name +
            "_resume"
          }`
        )
      );
  }

  return (
    <>
      {props?.data?.length === 0 ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h5>{t("No Data Found")}</h5>
        </div>
      ) : (
        <>
          <div className="c-modal TA">
            <div className="c-modal-header">
              <div className="c-modal-header-subtitle">
                <div style={{ display: "flex" }}>
                  <div className="c-modal-header-subtitle-bold">
                    {t("Applied For")}:
                  </div>
                  <div className="c-modal-header-subtitle-normal">
                    {displayedElement?.job?.title}
                  </div>
                  <div className="c-modal-header-subtitle-italic">
                    {"(" + displayedElement?.apply_date + ")"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  flexWrap: "wrap",
                  right: "16px",
                  top: "14px",
                  position: "absolute",
                }}
              >
                <button
                  type="button"
                  className="c-modal-close-button"
                  aria-label="Close modal"
                  onClick={() => props.onHide(true)}
                >
                  <span aria-hidden="true">
                    <img
                      src={share}
                      className="profile-modal-body-section-1-redirect-icon"
                    />
                  </span>
                </button>
                <button
                  type="button"
                  className="c-modal-close-button"
                  aria-label="Close modal"
                  onClick={() => props.onHide(true)}
                >
                  <span aria-hidden="true">
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxICg4OTU4MSkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQaGFzZS0xLS0tQ29tcG9uZW50LURldGFpbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9IjAuNSI+CiAgICAgICAgPGcgaWQ9IkFUUy1CZXRhLS0tQ29tcG9uZW50LWRldGFpbHMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODQxLjAwMDAwMCwgLTI1MzguMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTExLUNvcHktNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMxMS41MDAwMDAsIDI1MTkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSI1NDEuNSAyMC4yMDg1NzE0IDU0MC4yOTE0MjkgMTkgNTM1LjUgMjMuNzkxNDI4NiA1MzAuNzA4NTcxIDE5IDUyOS41IDIwLjIwODU3MTQgNTM0LjI5MTQyOSAyNSA1MjkuNSAyOS43OTE0Mjg2IDUzMC43MDg1NzEgMzEgNTM1LjUgMjYuMjA4NTcxNCA1NDAuMjkxNDI5IDMxIDU0MS41IDI5Ljc5MTQyODYgNTM2LjcwODU3MSAyNSI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=" />
                  </span>
                </button>
              </div>
            </div>
            {loadingModalShow ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "306px",
                  backgroundColor: "#fff",
                }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <div className="c-modal-body profile-modal-content">
                  <div className="profile-modal-body">
                    <div className="profile-modal-body-section-1">
                      <div className="profile-modal-body-container">
                        <div className="profile-modal-body-section-1-profile">
                          <div className="profile-modal-body-section-1-profile-img">
                            <img
                              src={
                                displayedElement?.teacher?.avatar || profileImg
                              }
                            />
                          </div>
                          <div>
                            <div className="profile-modal-body-section-1-name">
                              {displayedElement?.teacher?.first_name +
                                " " +
                                displayedElement?.teacher?.last_name}
                            </div>
                            <div className="profile-modal-body-section-1-description">
                              {t("Living in") +
                                " " +
                                displayedElement?.teacher?.location?.city +
                                ", " +
                                displayedElement?.teacher?.location?.country}
                            </div>
                          </div>
                        </div>
                        {displayedElement?.teacher?.allow_share_docs === "0" ? (
                          <label
                            className="mt-3 mb-3"
                            style={{ color: "gray", textAlign: "center" }}
                          >
                            {t("This teacher doesn't allow to share documents")}
                          </label>
                        ) : (
                          <div className="profile-modal-body-section-1-buttons">
                            <div className="profile-modal-body-section-1-resume-buttons">
                              <div className="profile-modal-body-section-1-resume">
                                {t("Resume")}
                              </div>
                              <div
                                style={
                                  displayedElement?.teacher
                                    ?.allow_share_docs === "0"
                                    ? { opacity: "0.4" }
                                    : null
                                }
                                className="profile-modal-body-section-1-content"
                              >
                                <button
                                  onClick={
                                    displayedElement?.teacher
                                      ?.allow_share_docs === "0"
                                      ? null
                                      : viewFile
                                  }
                                  className="profile-modal-body-section-1-view"
                                >
                                  <svg
                                    id="eye"
                                    data-name="eye"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 12"
                                    fill="#727272"
                                    fillRule="evenodd"
                                    data-testid="ta-web-ui-icon"
                                    role="img"
                                    className="profile-modal-body-section-1-icon"
                                    aria-label="eye"
                                    alt="eye"
                                  >
                                    <title>Eye icon</title>
                                    <path d="M9 12C12.8 12 16.2 9.8 17.9 6.5 18 6.3 18 6.1 18 6 18 5.9 18 5.7 17.9 5.5 16.2 2.2 12.8 0 9 0 5.2 0 1.8 2.2 0.1 5.5 0 5.7 0 5.9 0 6 0 6.1 0 6.3 0.1 6.5 1.8 9.8 5.2 12 9 12ZM9 10.5L9 10.5C6.5 10.5 4.5 8.5 4.5 6 4.5 3.5 6.5 1.5 9 1.5 11.5 1.5 13.5 3.5 13.5 6L13.5 6C13.5 8.5 11.5 10.5 9 10.5ZM9 9C10.7 9 12 7.6 12 6 12 4.3 10.7 3 9 3L9 3C8.8 3 8.4 3.1 8.2 3.1 8.4 3.3 8.5 3.7 8.5 4 8.5 4.8 7.8 5.5 7 5.5 6.7 5.5 6.3 5.4 6.1 5.2 6.1 5.4 6 5.8 6 6 6 7.6 7.4 9 9 9Z"></path>
                                  </svg>
                                </button>
                                <button
                                  onClick={
                                    displayedElement?.teacher
                                      ?.allow_share_docs === "0"
                                      ? null
                                      : downloadFile
                                  }
                                  className="profile-modal-body-section-1-view"
                                >
                                  <svg
                                    id="download"
                                    data-name="download"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#727272"
                                    fillRule="evenodd"
                                    viewBox="0 0 20 20"
                                    data-testid="ta-web-ui-icon"
                                    role="img"
                                    className="profile-modal-body-section-1-icon"
                                    aria-label="download"
                                    alt="download"
                                  >
                                    <title>Download Icon</title>
                                    <path
                                      id="Shape"
                                      className="cls-1"
                                      d="M2.08,19.61H17.92V17.35H2.08ZM17.92,7.17H13.39V.39H6.61V7.17H2.08L10,15.09Z"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="profile-modal-body-section-1-stats">
                          <div className="profile-modal-body-section-1-stats-info">
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQuOTY3IDguMzc5di0uNmgtNC4ydjIuNGMwIC42Ni41NCAxLjIgMS4yIDEuMmg4LjRjLjY2IDAgMS4yLS41NCAxLjItMS4ydi0yLjRoLTQuMnYuNmgtMi40em02LTUuNGgtMi40di0xLjJsLTEuMi0xLjJoLTIuNGwtMS4yIDEuMnYxLjJoLTIuNGMtLjY2IDAtMS4yLjU0LTEuMiAxLjJ2MS44YzAgLjY2LjU0IDEuMiAxLjIgMS4yaDMuNnYtMS4yaDIuNHYxLjJoMy42Yy42NiAwIDEuMi0uNTQgMS4yLTEuMnYtMS44YzAtLjY2LS41NC0xLjItMS4yLTEuMnptLTMuNiAwaC0yLjR2LTEuMmgyLjR2MS4yeiIgZmlsbD0iIzUzNTI1NCIvPjwvc3ZnPg==" />
                            <div>
                              {displayedElement?.teacher
                                ?.total_teaching_experience_years === "0" &&
                              displayedElement?.teacher
                                ?.total_teaching_experience_months === "0"
                                ? 0
                                : (displayedElement?.teacher
                                    ?.total_teaching_experience_years !== "0"
                                    ? displayedElement?.teacher
                                        ?.total_teaching_experience_years +
                                      t("Years")
                                    : "") +
                                  (displayedElement?.teacher
                                    ?.total_teaching_experience_years !== "0" &&
                                  displayedElement?.teacher
                                    ?.total_teaching_experience_months !== "0"
                                    ? t("and")
                                    : "") +
                                  (displayedElement?.teacher
                                    ?.total_teaching_experience_months !== "0"
                                    ? displayedElement?.teacher
                                        ?.total_teaching_experience_months +
                                      t("Months")
                                    : "")}
                              {" " + t("teaching experience")}
                            </div>
                          </div>
                          <div className="profile-modal-body-section-1-stats-info">
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzUzNTI1NCI+PHBhdGggZD0iTTExLjUuNjUyQTYuNTYgNi41NiAwIDAwOS41NTMuMzc1Yy0xLjA4NCAwLTIuMjUuMjIyLTMuMDU3LjgzM0M1LjY5MS41OTcgNC41MjQuMzc1IDMuNDQuMzc1Yy0xLjA4MyAwLTIuMjUuMjIyLTMuMDU2LjgzM1Y5LjM1YzAgLjE0LjEzOS4yNzguMjc4LjI3OC4wNTUgMCAuMDgzLS4wMjcuMTM4LS4wMjcuNzUtLjM2MiAxLjgzNS0uNjEyIDIuNjQtLjYxMiAxLjA4NCAwIDIuMjUxLjIyMyAzLjA1Ny44MzQuNzUtLjQ3MyAyLjExMi0uODM0IDMuMDU3LS44MzQuOTE3IDAgMS44NjIuMTY3IDIuNjQuNTg0YS4yNS4yNSAwIDAwLjEzOS4wMjguMjk5LjI5OSAwIDAwLjI3OC0uMjc4VjEuMjA4Yy0uMzM0LS4yNS0uNjk1LS40MTctMS4xMTItLjU1NnptMCA3LjUwNGE2LjQ1OCA2LjQ1OCAwIDAwLTEuOTQ2LS4yNzhjLS45NDUgMC0yLjMwNi4zNi0zLjA1Ny44MzNWMi4zMmMuNzUtLjQ3MyAyLjExMi0uODM0IDMuMDU3LS44MzRhNi40NCA2LjQ0IDAgMDExLjk0NS4yNzh2Ni4zOTJ6Ii8+PHBhdGggZD0iTTkuNTU0IDMuNzFjLjQ5IDAgLjk2Mi4wNSAxLjM5LjE0NHYtLjg0NWE3LjUwNSA3LjUwNSAwIDAwLTEuMzktLjEzM2MtLjk0NSAwLTEuOC4xNi0yLjUwMS40NnYuOTIzYy42MjgtLjM1NSAxLjUtLjU1IDIuNTAxLS41NXpNNy4wNTMgNC44MTV2LjkyM2MuNjI4LS4zNTYgMS41LS41NSAyLjUwMS0uNTUuNDkgMCAuOTYyLjA1IDEuMzkuMTQ0di0uODQ1YTcuNTA1IDcuNTA1IDAgMDAtMS4zOS0uMTMzYy0uOTQ1IDAtMS44LjE2Ny0yLjUwMS40NjF6bTIuNTAxIDEuMDIzYy0uOTQ1IDAtMS44LjE2MS0yLjUwMS40NjF2LjkyM2MuNjI4LS4zNTYgMS41LS41NSAyLjUwMS0uNTUuNDkgMCAuOTYyLjA1IDEuMzkuMTQ0di0uODQ1YTcuMDU3IDcuMDU3IDAgMDAtMS4zOS0uMTMzeiIvPjwvZz48L3N2Zz4=" />
                            <div>
                              {displayedElement?.teacher?.experiences?.length >
                              0
                                ? displayedElement?.teacher?.experiences?.map(
                                    (item) =>
                                      item?.subjects?.map(
                                        (innerItem, i) =>
                                          innerItem?.name +
                                          (i === item?.subjects?.length - 1
                                            ? ""
                                            : ", ")
                                      )
                                  )
                                : t("No Subjects")}
                            </div>
                          </div>
                          <div className="profile-modal-body-section-1-stats-info">
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNzM4IDEwLjA5OWExLjIwNCAxLjIwNCAwIDAwLTEuMTQtLjgzNGgtLjZ2LTEuOGEuNi42IDAgMDAtLjYtLjZoLTMuNnYtMS4yaDEuMmEuNi42IDAgMDAuNi0uNnYtMS4yaDEuMmExLjIgMS4yIDAgMDAxLjItMS4ydi0uMjQ2YTQuNzkgNC43OSAwIDAxMS43NCA3LjY4em0tNC4xNCAxLjUyNGE0Ljc5MyA0Ljc5MyAwIDAxLTQuMi00Ljc1OGMwLS4zNzIuMDQ4LS43MzIuMTI2LTEuMDc0bDIuODc0IDIuODc0di42YTEuMiAxLjIgMCAwMDEuMiAxLjJ2MS4xNTh6bS42LTEwLjc1OGE2IDYgMCAxMDAgMTIgNiA2IDAgMDAwLTEyeiIgZmlsbD0iIzUzNTI1NCIvPjwvc3ZnPg==" />
                            <div>
                              {displayedElement?.teacher?.citizenships?.length >
                              0
                                ? displayedElement?.teacher?.citizenships?.map(
                                    (item, i) =>
                                      item?.name +
                                      (i ===
                                      displayedElement?.teacher?.citizenships
                                        ?.length -
                                        1
                                        ? ""
                                        : ", ")
                                  )
                                : t("No Citizenships")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="profile-modal-body-section-2">
                      <div className="accordion">
                        <div className="accordion-div">
                          <div
                            className="accordion-div-header"
                            onClick={() => setOpenEducation(!openEducation)}
                          >
                            <div className="accordion-div-header-flex">
                              {t("Education")}
                            </div>
                            <div className="accordion-div-header-flex">
                              <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MSAoODk1ODEpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGhhc2UtMS0tLUNvbXBvbmVudC1EZXRhaWxzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQVRTLUJldGEtLS1Db21wb25lbnQtZGV0YWlscyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYxMS4wMDAwMDAsIC0xNzA1LjAwMDAwMCkiIGZpbGw9IiNDMkMyQzIiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjYxMi45MSAxNzA1LjQzMTQ2IDYxNy41IDE3MTAuMDIxNDYgNjIyLjA5IDE3MDUuNDMxNDYgNjIzLjUgMTcwNi44NTE0NiA2MTcuNSAxNzEyLjg1MTQ2IDYxMS41IDE3MDYuODUxNDYiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" />
                            </div>
                          </div>
                          <Collapse in={openEducation}>
                            <div
                              style={{ padding: "0px", margin: "0px" }}
                              className="accordion-div-body"
                            >
                              {displayedElement?.teacher?.educations?.length >
                              0 ? (
                                <div>
                                  <div className="accordion-card">
                                    <div className="accordion-card-h1">
                                      {
                                        displayedElement?.teacher
                                          ?.highest_education_degree?.title
                                      }
                                    </div>
                                    <div className="accordion-card-h2">
                                      {
                                        displayedElement?.teacher
                                          ?.highest_education_degree
                                          ?.degree_level?.name
                                      }
                                    </div>
                                    <div className="accordion-card-h3">
                                      {
                                        displayedElement?.teacher
                                          ?.highest_education_degree
                                          ?.year_of_graduation
                                      }
                                    </div>
                                  </div>
                                  {displayedElement?.teacher?.educations
                                    ?.length >= 2 ? (
                                    <div className="accordion-card-link">
                                      {"+ "}
                                      <span className="accordion-card-link-span">
                                        {displayedElement?.teacher?.educations
                                          ?.length -
                                          1 +
                                          " " +
                                          t("more")}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="accordion-card-h3">
                                  {t("No Data")}
                                </div>
                              )}
                            </div>
                          </Collapse>
                        </div>
                        <div className="accordion-div">
                          <div
                            className="accordion-div-header"
                            onClick={() => setOpenCertificate(!openCertificate)}
                          >
                            <div className="accordion-div-header-flex">
                              {t("Certificate and license")}
                            </div>
                            <div className="accordion-div-header-flex">
                              <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MSAoODk1ODEpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGhhc2UtMS0tLUNvbXBvbmVudC1EZXRhaWxzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQVRTLUJldGEtLS1Db21wb25lbnQtZGV0YWlscyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYxMS4wMDAwMDAsIC0xNzA1LjAwMDAwMCkiIGZpbGw9IiNDMkMyQzIiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjYxMi45MSAxNzA1LjQzMTQ2IDYxNy41IDE3MTAuMDIxNDYgNjIyLjA5IDE3MDUuNDMxNDYgNjIzLjUgMTcwNi44NTE0NiA2MTcuNSAxNzEyLjg1MTQ2IDYxMS41IDE3MDYuODUxNDYiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" />
                            </div>
                          </div>
                          <Collapse in={openCertificate}>
                            <div className="accordion-div-body">
                              {displayedElement?.teacher?.teaching_licenses
                                ?.length > 0 ? (
                                <div>
                                  <div className="accordion-card">
                                    <div className="accordion-card-h1">
                                      {t("Teaching License")}
                                    </div>
                                    <div className="accordion-card-h2">
                                      {
                                        displayedElement?.teacher?.teaching_licenses
                                          ?.slice(0, 1)
                                          ?.shift()?.teaching_license_type?.name
                                      }
                                    </div>
                                    <div className="accordion-card-h3">
                                      {displayedElement?.teacher?.teaching_licenses
                                        ?.slice(0, 1)
                                        ?.shift()
                                        ?.subjects?.slice(0, 2)
                                        ?.map(
                                          (innerItem, i) =>
                                            innerItem?.name +
                                            (i ===
                                            displayedElement?.teacher?.teaching_licenses
                                              ?.slice(0, 1)
                                              ?.shift()?.subjects?.length -
                                              1
                                              ? ""
                                              : ", ")
                                        )}
                                    </div>
                                    <div className="accordion-card-h3">
                                      {displayedElement?.teacher?.teaching_licenses
                                        ?.slice(0, 1)
                                        ?.shift()?.valid_from +
                                        " - " +
                                        (displayedElement?.teacher?.teaching_licenses
                                          ?.slice(0, 1)
                                          ?.shift()?.has_expiration_date === "1"
                                          ? displayedElement?.teacher?.teaching_licenses
                                              ?.slice(0, 1)
                                              ?.shift()?.valid_to
                                          : t("Present"))}
                                    </div>
                                  </div>
                                  {displayedElement?.teacher?.teaching_licenses
                                    ?.length >= 2 ? (
                                    <div className="accordion-card-link">
                                      {"+ "}
                                      <span className="accordion-card-link-span">
                                        {displayedElement?.teacher
                                          ?.teaching_licenses?.length -
                                          1 +
                                          " " +
                                          t("more")}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="accordion-card-h3">
                                  {t("No Data")}
                                </div>
                              )}
                            </div>
                          </Collapse>
                        </div>
                        <div className="accordion-div">
                          <div
                            className="accordion-div-header"
                            onClick={() => setOpenTeacher(!openTeacher)}
                          >
                            <div className="accordion-div-header-flex">
                              {t("Teaching experience")}
                            </div>
                            <div className="accordion-div-header-flex">
                              <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MSAoODk1ODEpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGhhc2UtMS0tLUNvbXBvbmVudC1EZXRhaWxzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQVRTLUJldGEtLS1Db21wb25lbnQtZGV0YWlscyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYxMS4wMDAwMDAsIC0xNzA1LjAwMDAwMCkiIGZpbGw9IiNDMkMyQzIiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjYxMi45MSAxNzA1LjQzMTQ2IDYxNy41IDE3MTAuMDIxNDYgNjIyLjA5IDE3MDUuNDMxNDYgNjIzLjUgMTcwNi44NTE0NiA2MTcuNSAxNzEyLjg1MTQ2IDYxMS41IDE3MDYuODUxNDYiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" />
                            </div>
                          </div>
                          <Collapse in={openTeacher}>
                            <div className="accordion-div-body">
                              {displayedElement?.teacher?.experiences?.filter(
                                (item) => item?.experience_type === "Teaching"
                              )?.length > 0 ? (
                                <div>
                                  <div className="accordion-card">
                                    <div className="accordion-card-h1">
                                      {
                                        displayedElement?.teacher?.experiences
                                          ?.filter(
                                            (item) =>
                                              item?.experience_type ===
                                              "Teaching"
                                          )
                                          .slice(0, 1)
                                          ?.shift()?.title
                                      }
                                    </div>
                                    <div className="accordion-card-h2">
                                      {displayedElement?.teacher?.experiences
                                        ?.filter(
                                          (item) =>
                                            item?.experience_type === "Teaching"
                                        )
                                        .slice(0, 1)
                                        ?.shift()?.school +
                                        ", " +
                                        displayedElement?.teacher?.experiences
                                          ?.filter(
                                            (item) =>
                                              item?.experience_type ===
                                              "Teaching"
                                          )
                                          .slice(0, 1)
                                          ?.shift()?.country}
                                    </div>
                                    <div className="accordion-card-h3">
                                      {displayedElement?.teacher?.experiences
                                        ?.filter(
                                          (item) =>
                                            item?.experience_type === "Teaching"
                                        )
                                        .slice(0, 1)
                                        ?.shift()?.start_date +
                                        " - " +
                                        (displayedElement?.teacher?.experiences
                                          ?.filter(
                                            (item) =>
                                              item?.experience_type ===
                                              "Teaching"
                                          )
                                          .slice(0, 1)
                                          ?.shift()?.still_work_there === "1"
                                          ? t("Present")
                                          : displayedElement?.teacher?.experiences
                                              ?.filter(
                                                (item) =>
                                                  item?.experience_type ===
                                                  "Teaching"
                                              )
                                              .slice(0, 1)
                                              ?.shift()?.end_date)}
                                    </div>
                                  </div>
                                  {displayedElement?.teacher?.experiences?.filter(
                                    (item) =>
                                      item?.experience_type === "Teaching"
                                  )?.length >= 2 ? (
                                    <div className="accordion-card-link">
                                      {"+ "}
                                      <span className="accordion-card-link-span">
                                        {displayedElement?.teacher
                                          ?.teaching_licenses?.length -
                                          1 +
                                          " " +
                                          t("more")}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="accordion-card-h3">
                                  {t("No Data")}
                                </div>
                              )}
                            </div>
                          </Collapse>
                        </div>
                      </div>

                      <div className="c-modal-header-subtitle-italic">
                        {t("(Aug 23, 2022)")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="c-modal-footer">
                  <div className="profile-modal-footer">
                    <select
                      disabled={
                        displayedElement?.application_status_id == 3 ||
                        displayedElement?.application_status_id == 7
                      }
                      onChange={(e) => setApplicationStatusID(e.target.value)}
                      value={applicationStatusID}
                      className="profile-modal-footer-select"
                    >
                      {applicantsStatus?.map((item, i) => (
                        <option
                          disabled={
                            item?.id == 1 ||
                            item?.id ==
                              displayedElement?.application_status_id ||
                            (displayedElement?.application_status_id == 4 &&
                              item?.id == 1) ||
                            (displayedElement?.application_status_id == 4 &&
                              item?.id == 2) ||
                            (displayedElement?.application_status_id == 5 &&
                              item?.id == 1) ||
                            (displayedElement?.application_status_id == 5 &&
                              item?.id == 2) ||
                            (displayedElement?.application_status_id == 5 &&
                              item?.id == 4) ||
                            (displayedElement?.application_status_id == 6 &&
                              item?.id == 1) ||
                            (displayedElement?.application_status_id == 6 &&
                              item?.id == 2) ||
                            (displayedElement?.application_status_id == 6 &&
                              item?.id == 4) ||
                            (displayedElement?.application_status_id == 6 &&
                              item?.id == 5)
                          }
                          key={i}
                          value={item.id}
                        >
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <Link
                      to={"#"}
                      onClick={handleSubmitStatus}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="btn btn-primary"
                    >
                      {t("Submit")}
                    </Link>
                  </div>
                  <div>
                    <div className="profile-modal-footer-applicants">
                      <div>
                        {props?.data?.length +
                          " " +
                          (props?.data?.length === 1
                            ? t("applicant to review")
                            : t("applicants to review"))}
                      </div>
                      <div
                        style={{ textAlign: lng === "arab" ? "left" : "right" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="arrows rightArrow"
                  onClick={nextApplicant}
                  style={{
                    position: "absolute",
                    top: "45%",
                    right: "-80px",
                    cursor: "pointer",
                  }}
                >
                  <img style={{ height: "30px", width: "30px" }} src={arrow} />
                </div>
                <div
                  className="arrows leftArrow"
                  onClick={previousApplicant}
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "-80px",
                    transform: "rotate(180deg)",
                    cursor: "pointer",
                  }}
                >
                  <img style={{ height: "30px", width: "30px" }} src={arrow} />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BestCandidateList;
