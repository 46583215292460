import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../../../layouts/loadingComponents/Loading";
import LogoFilePicker from "../../../Common/LogoFilePicker";
import upload from "../../../../../assets/images/upload.png";
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";
import { addDocument } from "../../../../../store/actions/teachers";
import SignleFilePicker from "../../../Common/SignleFilePicker";

const AddDocumentModal = ({ show, onHide, documentTypes, setView, forceLoad, setForceLoad }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [documentState, setDocumentState] = useState(false);
  const [documentType, setDocumentType] = useState({
    document_type_id: "",
    is_consent: "1",
  });

  // Button Submit Handler's
  const submitDocumentForm = () => {
    setLoading(true);
    let userData = {
      ...documentType,
      document: documentState,
    };
    let errorArr = [];
    if (!userData.document_type_id) {
      errorArr.push(t("Document type is required"));
    }
    if (!userData.document) {
      errorArr.push(t("document is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    dispatch(addDocument(userData, setLoading, onHide, setView, forceLoad, setForceLoad));
  };

  return (
    <Modal show={show} onHide={onHide} centered size="md" className="fade">
      <Modal.Header>
        <h3>{t('Add Document')}</h3>
      </Modal.Header>
      <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
        {loading ? (
          <div
            style={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12 col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Document type")}
                </label>
                <select
                  value={documentType?.document_type_id}
                  name="experience_type"
                  onChange={(e) =>
                    setDocumentType({
                      ...documentType,
                      document_type_id: e.target.value,
                    })
                  }
                  className="form-control solid"
                >
                  <option value="">
                    {t("Select")}
                  </option>
                  {documentTypes?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12  col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Upload document")}
                </label>
                <ul style={{ listStyle: "none" }}>
                  <li>{t('Maximum Size 10 MB')}</li>
                  <li>{t('Recommended Files:- doc, docx, pdf')}</li>
                </ul>
                <SignleFilePicker
                  FileState={documentState}
                  setFileState={setDocumentState}
                  type="Logo"
                >
                  <div style={{ cursor: "pointer" }} className="userProfile">
                    <img src={upload} alt="upload_image" />
                  </div>
                </SignleFilePicker>
              </div>
            </div>
            <div className="row">
              <div
                style={{ display: "flex", gap: "7px" }}
                className="form-check"
              >
                <input
                  style={{
                    float: "unset",
                    marginRight: "unset",
                    marginLeft: "unset",
                  }}
                  checked={documentType.is_consent == "1"}
                  value={documentType.is_consent}
                  onChange={() =>
                    setDocumentType({
                      ...documentType,
                      is_consent: documentType.is_consent == "0" ? "1" : "0",
                    })
                  }
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label">
                  {t(
                    "I consent to Madares receiving this document and sharing it with employers"
                  )}
                </label>
              </div>
            </div>
            <div className="row">
              <div
                style={{ display: "flex", gap: "7px" }}
                className="col-xl-6  col-md-6 mb-4"
              ></div>
              <div className="card-footer text-end">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Link
                    to={"#"}
                    onClick={submitDocumentForm}
                    className="btn btn-primary"
                  >
                    {t("Save")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default AddDocumentModal;
