import { t } from "i18next";
import React from "react";

const OrderResources = () => {
  return (
    <div>
      <div className="order-panel order-default-panel">
        <div className="order-heading"> {t('Your Orders')} </div>
        <div className="order-table-responsive">
          <table className="responsive views-tables cols-5 dashboard tables tables-striped">
            <thead>
              <tr className="order-tr">
                <th>{t("Order")} #</th>
                <th>{t('Date')}</th>
                <th>{t('Payment Method')}</th>
                <th>{t('Amount')}</th>
                <th>{t('Status')}</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderResources;
