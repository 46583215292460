import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherApplicantions } from "../../../../store/actions/teachers";
import Loading from "../../../layouts/loadingComponents/Loading";
import { Jobs } from "../home/NewHomeTeacher";

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const Applications = () => {
  const dispatch = useDispatch();

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const { teacherApplicantions, loading } = useSelector(
    (state) => state?.teacherApplicantions
  );

  useEffect(() => {
    dispatch(getTeacherApplicantions(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
    setLoadingModalShow(loading);
  }, [loading]);

  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="home-teacher-page">
            <div className="home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">
                Applications
              </div>
            </div>
            <Jobs
              title={"There are no applications :("}
              data={teacherApplicantions?.data}
              type={"Applications"}
            />
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Applications;
