export const TRANSLATIONS_ARAB = {
    "Dashboard": "لوحة التحكم",
    "Search here": "ابحث هنا",
    "New Job": "وظيفة جديدة",
    "New Article": "مقال جديد",
    "Dr.fahad Send you Photo": "دكتور فهد ارسل لك صورة",
    "Resport created successfully": "تم إنشاء إعادة الاستيراد بنجاح",
    "Reminder : Treatment Time!": "تذكير: وقت العلاج!",
    "See all notifications": "اطلع على جميع الإشعارات",
    "MADARES": "المدرس",
    "Job Admin Dashboard": "لوحة تحكم مسؤول الوظيفة",
    "Academy": "أكادمية",
    "Superadmin": "مشرف متميز",
    "Profile": "الملف الشخصي",
    "Saved Jobs": "الوظائف المحفوظة",
    "Inbox": "صندوق الوارد",
    "Calender": "تقويم",
    "Upload Certifications": "إرفاق الشهادات",
    "Upload Resume": "إرفاق السيرة الذاتية",
    "Get The Best Candidate": " احصل على أفضل مرشح",
    "Resources and Tools":" الموارد والأدوات",
    "My Profile":"ملفي الشخصي",
    "Logout": "تسجيل الخروج",
    "Search Jobs": "بحث وظائف",
    "search jobs": "بحث وظائف",
    "Search Applicants": "البحث عن المتقدمين",
    "search applicants": "البحث عن المتقدمين",
    "Applicants": "المتقدمون",
    "Applications": "التقديمات",
    "applications": "التقديمات",
    "Jobs": "وظائف",
    "New Job Post": "نشر وظيفة جديدة",
    "Job title": "المسمى الوظيفي",
    "Subjects": "الموضوعات",
    "Teaching level": "المستوى الدراسي",
    'Select Subject(s)': "اختر من الموضوعات",
    'Select Level(s)': "اختر من المستويات",
    "Select Country": "اختر دولة",
    "Select State": "اختر مدينة",
    "Minimum Salary / Month": "الحد الأدنى من الراتب الشهري",
    "Maximum Salary / Month": "الحد الأقصى من الراتب الشهري",
    "Don't publish salary information": "لا تنشر تفاصيل الراتب",
    "Contract length": "مدة العقد",
    "Years": "سنوات",
    "None": "غير محدد",
    "Months": "شهور",
    "jobs": "وظائف",
    "Manage Jobs": "إدارة الوظائف",
    "manage jobs": "إدارة الوظائف",
    "Database Access": "الوصول إلى قاعدة البيانات",
    "Blog": "مقالات",
    "blog": "مقالات",
    "Browse Articles": "تصفح المقالات",
    "browse articles": "تصفح المقالات",
    "Create an Article": "إنشاء مقال",
    "create an article": "إنشاء مقال",
    "create article": "إنشاء مقال",
    "Hiring Process": "التوظيف",
    "Short List": "القائمة القصيرة",
    "Analytics": "تحليلات",
    "analytics": "تحليلات",
    "Settings": "إعدادات",
    "settings": "إعدادات",
    "help": "مساعدة",
    "new job": "وظيفة جديدة",
    "my profile": "الملف الشخصي",
    "app calender": "جدول المواعيد",
    "inbox": "صندوق البريد",
    "app profile": "ملف شخصي",
    "read": "قراءة البريد",
    "compose": "ارسال بريد",
    "post details": "تفاصيل المقال",
    "Job details": "تفاصيل الوظيفة",
    "saved jobs": "الوظائف المحفوظة",
    "Help": "مساعدة",
    "Profile Viewed": "مشاهدة الملف الشخصي",
    "Unread Messages": "رسائل غير مقروءة",
    "Application Received": "تم استلام الطلب",
    "App. Answered": "تطبيق. أجاب",
    "Interviewed": "تمت المقابلة",
    "Hired": "استأجرت",
    "Interview Schedules": "جداول المقابلة",
    "Application Summary": "ملخص التطبيق",
    "Location": "موقع",
    "Jeddah": "جدة",
    "Riyadh": "الرياض",
    "Madinah": "المدينة المنورة",
    "Arabic Teacher": "مدرس لغة عربية",
    "English Teacher": "معلم لغة انجليزية",
    "Recent Activity": "النشاط الأخير",
    "Newest": "الأحدث",
    "Oldest": "الاقدم",
    "8 min ago": "قبل 8 دقائق",
    "You opened a Math Teacher position on Jobs.": "لقد فتحت منصب مدرس الرياضيات في الوظائف.",
    "You opened a Nursery Teacher position on Jobs.": "لقد فتحت منصب مدرس الحضانة في الوظائف.",
    "You opened a Class Teacher position on Jobs.": "لقد فتحت منصب مدرس الفصل في الوظائف.",
    "You opened a Algebra 1 Professor position on Jobs.": "لقد فتحت منصب أستاذ الجبر 1 في الوظائف.",
    "View more": "عرض المزيد",
    'Kleon Team': "فريق كلاون",
    "Ziro Studios Inc.": "زيرو ستوديوز إنك",
    'Qerza': "قرزة",
    'Kripton Studios': "استوديوهات كريبتون",
    "Uena Foods": "يوينا فودز",
    "Featured Applicants": "المتقدمون المميزون",
    "Desgin Team": "فريق التصميم",
    "Applicants Stats": "احصائيات المتقدمين",
    "Vacany Stats": "احصائيات الوظيفة الشاغرة",
    "Daily": "اليومي",
    "Weekly": "أسبوعي",
    "Monthly": "شهريا",
    "Application Recieved": "تم استلام الطلب",
    "Application Sent": "تم إرسال الطلب",
    "Interviews": "المقابلات",
    "Chart": "جدول",
    "Following": "التالية",
    "Followers": "متابعون",
    "Choose Location": "اختيار موقع",
    "London": "لندن",
    "France": "فرنسا",
    "search job title here...": "ابحث عن المسمى الوظيفي هنا",
    "Filter": "منقي",
    "Search": "بحث",
    "Profile Datatable": "جدول البيانات",
    "Name": "اسم",
    "Current Position": "الموقف الحالى",
    "Gender": "الجنس",
    "Date of Birth": "تاريخ الميلاد",
    "Re-send Verification Email": "أعد إرسال بريد التفعيل",
    "Education": "تعليم",
    "Citizenship": "المواطنة",
    "Experince": "خبرة",
    "Action": "عمل",
    //TODO:
    // remove from here later
    "Paul Byrd": "بول بيرد",
    "Chief Financial Officer (CFO)": "المدير المالي",
    "Male": "ذكر",
    "Egypt": "مصر",
    "12 years": "12 سنة",
    "Gloria Little": "غلوريا ليتل",
    "Systems Administrator": "مدير نظم",
    "England": "إنكلترا",
    "Bradley Greer": "برادلي جرير",
    "Software Engineer": "مهندس برمجيات",
    "Sudan": "السودان",
    "1 year": "سنة",
    // till here 
    "Showing": "إظهار",
    "to": "إلى",
    "of": "من",
    "entries": "الإدخالات",
    "Mitchell C. Shay": "ميتشل سي شاي",
    "UX / UI Designer": "مصمم واجهة المستخدم",
    "Email": "البريد الإلكتروني",
    "Follower": "تابع",
    "Place Stay": "مكان البقاء",
    "Reviews": "المراجعات",
    "Follow": "يتبع",
    "Send Message": "إرسال رسالة",
    "Posts": "المشاركات",
    "About Me": "عني",
    "Setting": "ضبط",
    "Please type what you want": "الرجاء كتابة ما تريد",
    "Comment": "تعليق",
    "Author": "مؤلف",
    "Post Comment": "أضف تعليقا",
    "Today Highlights": "يسلط الضوء على اليوم",
    "Darwin Creative Agency Theme": "موضوع وكالة داروين الإبداعية",
    "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.": "يتدفق نهر صغير يُدعى دودن من مكانه ويمدها بالراحة اللازمة. إنها دولة الفردوسية ، حيث تتطاير الأجزاء المحمصة من الجمل في فمك.",
    "Interest": "فائدة",
    "Our Latest News": "آخر أخبارنا",
    "Collection of textile samples": "جمع عينات النسيج",
    "I shared this on my fb wall a few months back, and I thought.": "لقد شاركت هذا على حائط الفيسبوك الخاص بي قبل بضعة أشهر ، وفكرت.",
    "Social Links": "روابط اجتماعية",
    "Upload images": "تحميل الصور",
    "Upload": "تحميل",
    "Post": "بريد",
    "Collection of textile samples lay spread": "انتشار جمع عينات النسيج",
    "A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.": "استحوذ الصفاء الرائع على روحي بالكامل مثل صباح الفراغ الحلو الذي ينعم بالقلب الكامل. استحوذ الصفاء الرائع على روحي بالكامل مثل صباح الفراغ الجميل الذي ينعم بالقلب الكامل.",
    "Like": "يحب",
    "Reply": "رد",
    "View profile": "عرض الصفحة الشخصية",
    "Add to close friends": "أضف إلى الأصدقاء المقربين",
    "Add to group": "إضافة إلى المجموعة",
    "Block": "حاجز",
    "Post Reply": "الرد آخر",
    "Message": "رسالة",
    "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence was created for the bliss of souls like mine.I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.": "استحوذ الصفاء الرائع على روحي بأكملها ، مثل صباح الربيع الحلو الذي أستمتع به من كل قلبي. أنا وحدي ، وأشعر أن سحر الوجود قد خلق لنعمة النفوس مثل لي ، أنا سعيد جدًا ، صديقي العزيز ، منغمس جدًا في الشعور الرائع بالوجود الهادئ ، لدرجة أنني أهمل مواهبي.",
    "A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.": "انتشرت مجموعة من عينات النسيج على الطاولة - كان سامسا بائعًا متجولًا - وفوقها علق صورة كان قد قطعها مؤخرًا من مجلة مصورة ووضعها في إطار مذهل جميل.",
    "Skills": "مهارات",
    "Admin": "مسؤل",
    "Photoshop": "محل تصوير",
    "Bootstrap": "التمهيد",
    "Responsive": "متجاوب",
    "Crypto": "تشفير",
    "Language": "اللغة",
    "English": "الإنجليزية",
    "French": "فرنسي",
    "Bangla": "البنغالية",
    "Personal Information": "معلومات شخصية",
    "Mitchell C.Shay": "ميتشل سي شاي",
    "Availability": "التوفر",
    "Full Time (Free Lancer)": "دوام كامل (فري لانسر)",
    "Age": "سن",
    "Rosemont Avenue Melbourne, Florida": "شارع روزمونت ملبورن ، فلوريدا",
    "Year Experience": "سنة خبرة",
    "07 Year Experiences": "07 سنة من الخبرات",
    "Account Setting": "إعدادات الحساب",
    "Password": "كلمة المرور",
    "Address": "العنوان",
    "1234 Main St": "1234 الشارع الرئيسي",
    "Apartment, studio, or floor": "شقة أو استوديو أو طابق",
    "City": "المدينة",
    "Contact Number": "رقم التواصل",
    "Enter phone number": "رقم الهاتف",
    "About You": "نبذة عنك",
    "Please make sure that the files are named with its related name": "من فضلك قم بالتأكد من أن الملفات المرفقة تحمل نفس الاسم المتعلق بها",
    "Street":"الشارع",
    "Or": "أو",
    "State": "المدينة",
    "Choose": "اختر",
    "Select Country": "اختر دولة",
    "Select State": "اختر مدينة",
    'Select Skills': "اختر المهارات",
    "MAJOR INFORMATION": "معلومات التخصص",
    "Which of the following levels can you teach in?": "أي من المستويات الدراسية الآتية يمكنك التدريس فيها؟",
    "Subject": "الموضوع",
    "Degree": "الدرجة العلمية",
    "Years of Teaching": "أعوام التدريس",
    "Choose your Education Levels": "اختر مستويات التعليم",
    "Any": "أيهما",
    "Arabic": "العربية",
    "Bachelor": "البكالريوس",
    "Master": "الماستر",
    "Doctorate": "الدكتوراه",
    "Highschool": "الثانوية",
    "Adavnced": "متقدم",
    "Certification": "شهادة",
    "Bachelor's Degree in ..": "درجة البكالريوس في ..",
    "Still Study There": "مازلت أدرس هناك",
    "Years Of Experience": "سنوات الخبرة",
    "Fresh Graduated": "حديث التخرج",
    "Less than 1 year": "أقل من عام",
    "1-2 years": "١ - ٢ سنوات",
    "2-5 years": "٢ - ٥ سنوات",
    "+5 years": "٥+ سنوات",
    "Zip": "أَزِيز",
    "Check me out": "تفقدني",
    "Sign in": "تسجيل الدخول",
    "By Admin": "بواسطة مسؤول",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimencenturies.": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. لقد كان النص القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لصنع عينات من القرون.",
    "Leave a Reply": "اترك رد",
    "Request Customer Service": "طلب خدمة العملاء",
    "First Name": "الاسم الاول",
    "Last Name": "الكنية",
    "Phone Number": "رقم الهاتف",
    "What type of assistance do you require?": "ما نوع المساعدة التي تحتاجها؟",
    "Customer Service for schools": "خدمة العملاء للمدارس",
    "Customer Service for teachers": "خدمة العملاء للمعلمين",
    "Please include any additional information that may help us assist you!": "يرجى تضمين أي معلومات إضافية قد تساعدنا في مساعدتك!",
    "Submit": "إرسال",
    "Academy Edit Profile": "أكاديمية تحرير الملف الشخصي",
    "Confirm password": "تأكيد كلمة المرور",
    "Current password": "كلمة المرور الحالية",
    "Email ID": "عنوان الايميل",
    "John": "جون",
    "Hazim": "حازم",
    "Save": "يحفظ",
    "Card title": "عنوان البطاقة",
    "He lay on his armour-like back, and if he lifted his head alittle": "كان مستلقيًا على ظهره الذي يشبه الدروع ، وإذا رفع رأسه ضعيفًا",
    "Card footer": "تذييل البطاقة",
    "1 hr ago": "منذ 1 ساعة",
    "Gawish": "جاويش",
    "test": "اختبار",
    "test test test test test test test test test test test test test test test test test test test test test": "الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار الاختبار",
    "Summernote Editor": "محرر سمرنوت",
    "Experience": "خبرة",
    "Highest Degree": "أعلى درجة",
    "Major": "رئيسي",
    "Last active": "آخر نشاط",
    "Mahmoud Gawish": "محمود جاويش",
    "Egyptian": "مصري",
    "3 Years": "3 سنوات",
    "Bachelor's Degree": "درجة باكلريوس",
    "today": "اليوم",
    "Job Application": "طلب وظيفي",
    "Add New Job": "إضافة وظيفة جديدة",
    "Closed": "مغلق",
    'N/A': "غير متاح",
    "Actions": "تغيير الحالة",
    "Boost Job": "تعزيز الوظيفة",
    "Close Job": "أغلق الوظيفة",
    "Pause Job": "وقف الوظيفة",
    "Delete Job": "إزالة الوظيفة",
    "Review Public Post": "مراجعة المنشور العام",
    "Edit Job": "تحرير الوظيفة",
    "Awaiting": "في الانتظار",
    "Reviewed": "تمت مراجعته",
    "Contacting": "تم التواصل",
    "Rejected": "رفض",
    "Manchester, England": "مانشستر ، إنجلترا",
    "Company Name": "اسم الشركة",
    "Position": "المسمى الوظيفي",
    'Math Teacher': "معلم رياضيات",
    "Job Category": "تصنيف الوظيفة",
    "QA Analyst": "محلل سؤال وجواب",
    "IT Manager": "مدير تكنولوجيا المعلومات",
    "Systems Analyst": "محلل أنظمة",
    "Job Type": "نوع الوظيفة",
    "Part-Time": "دوام جزئى",
    "Full-Time": "وقت كامل",
    "Freelancer": "مستقل",
    "No. of Vancancy": "عدد الأماكن الشاغرة",
    "Close Date": "تاريخ الإغلاق",
    "Post Date": "تاريخ النشر",
    "Minimum Experience": "الحد الأدنى للخبرة",
    "Select Experience": "حدد الخبرة",
    "1 yr": "1 سنة",
    "2 Yr": "2 سنة",
    "3 Yr": "3 سنة",
    "4 Yr": "4 سنة",
    "Posted Date": "تاريخ النشر",
    "Last Date To Apply": "آخر موعد للتقديم",
    "Select Gender": "اختر نوع الجنس",
    "Comunication Email": "بريد التواصل",
    "Salary type": "نوع الراتب",
    "Salary Rate": "معدل الراتب",
    "Salary Amount": "قيمة الراتب",
    "Salary From": "يبدأ الراتب من",
    "Salary To": "ينتهي الراتب عند",
    "Female": "أنثى",
    "Salary Form": "نموذج الراتب",
    "Enter City": "أدخل المدينة",
    "Enter State": "إدخال الدولة",
    "Enter Counter": "أدخل العداد",
    "Education Level": "المستوى التعليمي",
    "Description": "الوصف",
    "Are you able to relocate?": "هل أنت متاح للتنقل؟",
    "SAR": "ر.س",
    "My Jobs": "وظائفي",
    "Previous": "السابقة",
    "You Don't Have Any Job Posts": "ليس لديك أية وظائف",
    "Next": "متابعة",
    "Types of educators who can apply for this job": "نوعية المتعلمين الذين يمكنهم الترشح للوظيفة",
    "Min. level of education": "مستوى التعليم الأدنى",
    "Select Type(s)": " اختر من الأنواع",
    "Select Level": "اختر مستوى",
    "Status": "الحالة",
    "Paused":"متوقف",
    "Custom Questions": "أسئلة مخصصة",
    "Specific Major": "تخصص معين",
    "Min. Teaching Experience": "الحد الأدنى من الخبرة",
    "Certifications Required": "الشهادات المطلوبة",
    "Select Certification(s)": "اختر من بين الشهادات",
    "Citizenship Match Required": "مطلوب مطابقة الجنسية",
    "Select Citizenship(s)": "اختر من بين الجنسيات",
    "Other Requirements": "متطلبات أخرى",
    "Requirements": "المتطلبات",
    "Airfare": "السفر بالطائرة",
    "Job Benefits": "مزايا الوظيفة",
    "Other Information": "معلومات أخرى",
    "Number of vacancies": "عدد الأماكن",
    "Candidates will apply by visiting our website": "المرشح سوف يقوم بالتقديم من خلال موقعنا الإلكتروني",
    "Job Application Link": "رابط طلب الوظيفة",
    "Display a video on your job postings": "عرض فيديو داخل منشور الوظيفة",
    "Notes to Madares Team": "ملاحظات لفريق مدارس",
    "I agree to the": "أنا موافق على",
    "Job posting Terms and Conditions": "الشروط والأحكام الخاصة بنشر الوظائف",
    "You don't have any live jobs": "ليس لديك أية وظائف نشطة",
    "Video Full URL": "رابط الفيديو الكامل",
    "E.g., Full airfare reimbursement for an economy ticket and airport pickup": "على سبيل المثال ، السداد الكامل لتذكرة السفر جواً لتذكرة اقتصادية واستقبال من المطار",
    "Accommodation": "الإقامة",
    "E.g., Housing allowance provided on a monthly basis": "على سبيل المثال ، بدل السكن المقدم على أساس شهري",
    "All other benefits": "جميع المميزات الأخرى",
    "Active": "نشط",
    "Suspended": "موقوف",
    "In Active": "غير نشط",
    "Close": "أغلق",
    "Showing 12 of 124 Jobs Results": "عرض 12 من أصل 124 نتائج وظائف",
    "Based your preferences": "بناء على تفضيلاتك",
    "Physics Teacher": "مدرس الفيزياء",
    "Electronics Teacher": "مدرس الكترونيات",
    "Kelon inc.": "شركة كيلون.",
    "Foodia Studios": "استوديوهات فوديا",
    "Bubble Studios": "استوديوهات الفقاعة",
    "Model School": "المدرسة النموذجية",
    "Applied": "مطبق",
    "Showing 5 of 102 Data": "عرض 5 من أصل 102 بيانات",
    "All Status": "كل الحالات",
    "Pending": "قيد الانتظار",
    "On-Hold": "في الانتظار",
    "Candidate": "مرشح",
    "Date": "تاريخ",
    "Job Applied": "وظيفة تطبيق",
    "2 Years": "سنتان",
    "Postgraduate Diploma": "دبلوم الدراسات العليا",
    "Programing Teacher": "مدرس البرمجة",
    "ON HOLD": "في الانتظار",
    "Manager": "مدير",
    "A data analyst collects, interprets and visualizes data to uncover insights. A data analyst assigns a numerical value to business functions so performance.": "يقوم محلل البيانات بجمع البيانات وتفسيرها وتصورها لكشف الرؤى. يقوم محلل البيانات بتعيين قيمة عددية لوظائف الأعمال حتى الأداء.",
    "Progress": "تقدم",
    "Indonesia": "إندونيسيا",
    "Full Name": "الاسم الكامل",
    "Resume Title": "استئناف عنوان",
    "Current Designation": "تعيين الحالي",
    "Annual Salary": "الراتب السنوي",
    "Current Company": "الشركة الحالية",
    "Preferred Location": "الموقع المفضل",
    "Qualification": "مؤهل",
    "Key Skills": "المهارات الاساسية",
    "Launguages": "اللغات",
    "Phone": "هاتف",
    "Industry": "صناعة",
    "Date Of Birth": "تاريخ الولادة",
    "Marital Status": "الحالة الزوجية",
    "Permanent Address": "عنوان دائم",
    "Zip code": "الرمز البريدي",
    "Currently Working at": "تعمل حاليا في",
    "3 Yrs Of Working Experience in": "3 سنوات من الخبرة العملية في",
    "Download Ruseme": "تنزيل السيرة الذاتية",
    "Change Status": "تغيير الوضع",
    "Searching For PHP Doveloper": "البحث عن PHP Doveloper",
    "PHP Programmer": "مبرمج PHP",
    "3 Yrs": "3 سنوات",
    "USA": "الولايات المتحدة الأمريكية",
    "B.Tech(CSE)": "بي تك (CSE)",
    "Good Communication, Planning and research skills": "مهارات الاتصال والتخطيط والبحث الجيد",
    "English, German, Spanish": "الإنجليزية ، الألمانية ، الإسبانية",
    "IT Software/ Developer": "برنامج تكنولوجيا المعلومات / المطور",
    "Unmarried": "اعزب",
    "Interview Schedule": "جدول مقابلة",
    "UI / UX Designer": "مصمم UI / UX",
    "Medan, Sumatera Utara - ID": "ميدان ، شمال سومطرة - ID",
    "Update Profile": "تحديث الملف",
    "Recomended Jobs": "وظائف الموصى بها",
    "View All": "مشاهدة الكل",
    "Featured Schools & Academies": "مدارس وأكاديميات مميزة",
    "Fulltime Math Teacher": "مدرس رياضيات بدوام كامل",
    "One Academy": "أكاديمية واحدة",
    "monthly": "شهريا",
    "London, England": "لندن، إنجلترا",
    "View Maps": "مشاهدة الخرائط",
    "Full TIME": "وقت كامل",
    "Herman-Carter Academy": "أكاديمية هيرمان كارتر",
    "21 Vacancy": "21 شاغر",
    "Teacher Edit Profile": "المعلم تحرير ملف التعريف",
    "UI Designer": 'مصمم واجهة المستخدم',
    "Programmer": "مبرمج",
    "UX Researcher": "باحث UX",
    "Data Scientist": "عالم البيانات",
    "Animator": "الرسوم المتحركة",
    "Lead Design Rese..": "ريس تصميم الرصاص ..",
    "Lead Design": "تصميم الرصاص",
    "Bubbles Studios": "استوديوهات بابلز",
    'Kleon Studios': "استوديوهات كليون",
    "Apply Now": "قدم الآن",
    "Monthly Salary": "راتب شهري",
    "Figma": "فيجما",
    "Adobe XD": "XD أدوبي",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum": "الألم بحد ذاته هو حب الألم ، المشاكل البيئية الرئيسية ، لكني أعطي هذا النوع من الوقت للتراجع ، بحيث يكون هناك بعض الألم والألم العظيمين. من أجل ذلك ، سيأتي أي واحد منا في الغالب لممارسة أي نوع من العمل باستثناء الاستفادة من الأهداف منه. لكن الألم في الفيلم من الصعب إدانته ، لأنه يريد ، بسرور ، الهروب من ألم الشعور بالألم ، بلا نتيجة. أولئك الذين يتوقون للسود هم الاستثناء ، لا يرون ، هم مخطئون لمن يتخلون عن مسؤولياتهم ، تلك هي المشقة التي تلين عقولهم",
    "Contact": "اتصال",
    "Socials": "الاجتماعية",
    "Upload your curriculum vitae": "قم بتحميل سيرتك الذاتية",
    "Compose": "مؤلف موسيقى",
    "Sent": "أرسلت",
    "Important": "مهم",
    "Draft": "مسودة",
    "Trash": "قمامة",
    "Categories": "فئات",
    "Work": "عمل",
    "Private": "خاص",
    "Support": "الدعم",
    "Social": "اجتماعي",
    "More": "أكثر",
    "Mark as Unread": "وضع علامة كغير مقروءة",
    "Add to Tasks": "أضف إلى المهام",
    "Add Star": "أضف نجمة",
    "Mute": 'صامت',
    "Promotions": "الترقيات",
    "Updates": "التحديثات",
    "Forums": "المنتديات",
    "Send": "إرسال",
    "Discard": "تجاهل",
    "Attatchment": "المرفق",
    "Drop files here to upload": "قم بإسقاط الملفات هنا للتحميل",
    " To": "إلى",
    " Subject": 'موضوعات',
    " Enter text": "أدخل النص",
    "Events": "الأحداث",
    "Event 1": "حدث 1",
    "Event 2": "حدث 2",
    "Event 3": "حدث 3",
    "Event 4": "حدث 4",
    "Event 5": "حدث 5",
    "LOGIN": "تسجيل الدخول",
    "Forget Password?": "نسيت كلمة المرور؟",
    "Login": "تسجيل الدخول",
    "or": "أو",
    "Don't Have Account?": "ليس لديك حساب؟",
    "SIGN UP": "اشتراك",
    "Email Address": "البريد الإلكتروني",
    "Re-Enter-Password": "إعادة إدخال كلمة المرور",
    "Have an Account?": "لديك حساب؟",
    "Teacher": "معلم",
    "Who are You?": "من أنت؟",
    "LogOut": "تسجيل خروج",
    "Place of Issurance": "مكان الإصدار" ,
    "BASIC INFORMATION": "معلومات اساسية",
    "ZIP Code": "الرمز البريدي",
    "About": "حول",
    "Upload Photo": "حمل الصورة",
    "Drag an image here": "اسحب الصورة هنا",
    "Choose File": "اختر ملف",
    "SKILLS INFORMATION": "معلومات المهارات",
    "Add Skill": "أضف مهارة",
    "Add Skills": "أضف مهارات",
    "JOB INFORMATION": "معلومات مهمة",
    "Willing to Travel": "النية للسفر",
    "Start Immediately": "ابدأ حالا",
    "10 DAYS": "10 أيام",
    "20 DAYS": "20 أيام",
    "WORK EXPERIENCE": "خبرة في العمل",
    "Choose your position": "اختر منصبك",
    "Date Started": "ميعاد البدء",
    "Date Ended": "تاريخ الانتهاء",
    "Years of Experience": "سنوات من الخبرة",
    "Level of Education": "مستوى التعليم",
    "School, Academy": "المدرسة، الأكاديمية",
    "5 Year of experience": "5 سنوات خبرة",
    "Add Experience": "أضف الخبرة",
    "Required": "مطلوب",
    "dashboard": "لوحة التحكم",
    "File Upload": "تحميل الملف",
    "Drag and drop a file here or click": "قم بسحب وإسقاط ملف هنا أو انقر",
    "Back": "العودة",
    'Still Work There': "مازلت أعمل هناك",
    "Email Verification": "تفعيل البريد الإلكتروني",
    "Done": "تم التفعيل",
    "Email Verified successfully": "تم تفعيل البريد الإلكتروني بنجاح",
    "Please Check Your Email For A Verification Link": "من فضلك راجع بريدك الإلكتروني بحثًا عن رابط التفعيل",
    "Thanks For Registeration, Admins Are Reviewing Your Account Details Right Now": "شكرًا للتسجيل، إن المدراء يعملون حاليًا على مراجعة حسابك",
    "Account Activation": "تفعيل الحساب",
    "General Information": "معلومات عامة",
    'Online Certification Url': "رابط الشهادة عبر الانترنت",
    "Register": "تسجيل",
    'Education / Certification': "الدراسة / الشهادات",
    "Upload Certification": "إرفاق الشهادة",
    'Add More': "أضف المزيد",
    "Name of School": "اسم المدرسة",
    "Contact NO": "رقم الاتصال",
    "Add a branch location": "إضافة موقع الفرع",
    "Details": "تفاصيل",
    "Upload Logo": "تحميل الشعار",
    "Upload Profile": "تحميل الصورة الشخصية",
    "Country": "الدولة",
    "Mobile No": "رقم الموبايل",
    "Valentino High Academy": "أكاديمية فالنتينو الثانوية",
    "SCHOOL INFORMATION": "معلومات المدرسة",
    "Choose your Curriculum": "اختر منهجك",
    "Pre-Primary Education": "التعليم قبل الابتدائي",
    "Primary Education": "تعليم ابتدائي",
    "intermediate Education": "التعليم المتوسط",
    "Secondary Education": "التعليم الثانوي",
    "General / Regular Secondary Education": "التعليم الثانوي العام / العادي",
    "Technical and Vocational Secondary Education": "التعليم الثانوي الفني والمهني",
    "College": "كلية",
    "University": "جامعة",
    "Year of Teaching": 'عام التدريس',
    "5-10 years": "5-10 سنوات",
    "11-15 years": "11-15 سنوات",
    "16-20 years": "16-20 سنوات",
    "Size of School": "حجم المدرسة",
    "Small School": "مدرسة صغيرة",
    "Midsize School": "مدرسة متوسطة الحجم",
    "Large School": "مدرسة كبيرة",
    "Upload necessary documents": "قم بتحميل المستندات اللازمة",
    "Lorem ipsum dolor sit amet": "الكثير من الألم كثير",
    "Let MADARES Managed": "دع المدارس يديرها",
    "Your Resume Easily": "سيرتك الذاتية بسهولة",
    "Forgot Password": "هل نسيت كلمة السر",
    "Reset Password": "إعادة تعيين كلمة المرور",
    "Verification link is send to your email": "يتم إرسال رابط التحقق إلى بريدك الإلكتروني",
    "Email has been sent successfully": "لقد تم إرسال البريد الإلكتروني بنجاح",
    "Ingredia Nutrisha": "أدخل نوتريشا",
    "20 May 2021": "20 مايو 2021",
    "07:23 AM": "07:23 صباحًا",
    "A collection of textile samples lay spread": "انتشرت مجموعة من عينات النسيج",
    "Hi,Ingredia,": "مرحبا ، أحمد",
    "Ingredia Nutrisha,": "أدخل نوتريشا",
    "A collection of textile samples lay spread out on the table -Samsa was a travelling salesman - and above it there hung a picture": "مجموعة من عينات النسيج منتشرة على المنضدة - كان سمسا بائع متجول - وفوقها كانت هناك صورة معلقة.",
    "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.": "حتى التأشير القوي لا يتحكم في النصوص العمياء ، فهي حياة غير تقليدية تقريبًا ذات يوم ، لكن سطرًا صغيرًا من النص الأعمى باسم Lorem Ipsum قرر المغادرة إلى عالم القواعد البعيدة. Aenean vulputate الفيل تيلوس. إينيس ليو ليجولا ، بورتيتور الاتحاد الأوروبي ، كونيكوات فيتاي ، إليفيند إيه سي ، إنيم. Aliquam lorem ante ، منزل dapibus ، حضانة quis ، feugiat a ، tellus.",
    "Kind Regards": "أطيب التحيات",
    "Mr Smith": "السيد سميث",
    "Attachments": "المرفقات",
    "It's really an amazing.I want to know more about it..!": "إنه لأمر مدهش حقًا. أريد أن أعرف المزيد عنه ..!",
    "Job View": "عرض الوظيفة",
    "Overview": "ملخص",
    "Job Title": "المسمى الوظيفي",
    "Title": "الإسم",
    "Vacancy": "شاغر",
    "Last Apply Date": "تاريخ آخر تطبيق",
    "Closed Date": "تاريخ مغلق",
    "Contact Now": "اتصل الآن",
    "Job Details": "تفاصيل الوظيفة",
    "Apply": "يتقدم",
    "Share Job": "مشاركة الوظيفة",
    "Print" : "مطبعة",
    "Job Role": "الدور الوظيفي",
    "Role": "دور",
    "Min Salary" : "الحد الأدنى للراتب",
    "Max Salary": "الحد الأقصى للراتب",
    "Job Tags": "علامات الوظائف",
    "Job Experience": "خبرة العمل",
    "Launges": "يغسل",
    "Locality": "المنطقة",
    "Eligibility": "جدارة - أهلية",
    "Company": "شركة",
    "Job ID": "معرف الوظيفة",
    "Posted By": "منشور من طرف",
    "You Logged In Successfully": "لقد قمت بتسجيل الدخول بنجاح",
    "Login credentials are invalid.": "بيانات اعتماد تسجيل الدخول غير صالحة.",
    "You Changed Your Password Successfully": "لقد قمت بتغيير كلمة المرور الخاصة بك بنجاح",
    "The page you were looking for is not found!":  "الصفحة التي تبحث عنها غير موجودة!",
    "You may have mistyped the address or the page may have moved.": "ربما أخطأت في كتابة العنوان أو ربما تم نقل الصفحة.",
    "Back to Home": "العودة إلى الصفحة الرئيسية",
    "Live Jobs" : "وظائف  نشطة",
    "All Jobs": "جميع الوظائف",
    "Post New Job": "انشر وظيفة جديدة",
    "Total Applicants": "إجمالي المتقدمين",
    "Total Views": "عدد المشاهدات",
    "Expires": "تنتهي",
    "View all active jobs": "عرض جميع الوظائف النشطة",
    "Something Went Wrong!": "حدث خطأ ما!",
    "An error occurred while processing data": "لقد وقع خطأ ما أثناء معالجة بعض البيانات",
    "Please try again later.": "من فضلك أعد المحاولة لاحقًا",
    "Archived": "الأرشيف" ,
    "Drafts": "المسودات",
    "Edit Job Post": "تعديل منشور وظيفة",
    "Are you sure?": "هل أنت متأكد؟",
    "You didn't complete these jobs": "أنت لم تكمل تلك الوظائف",
    "You don't have any drafts": "ليس لديك أية مستودات",
    "Date Of adding": "تاريخ الإضافة",
    "Next step": "الخطوة التالية" ,
    "Exp": "خبرة",
    "All Applicants": "جميع المتقدمين",
    "Most Recent Applicants": "أحدث المتقدمين",
    "View all recent applicants": "عرض جميع المتقدمين الجدد",
    "Applicants for": "المتقدمون ل",
    "Open Jobs": "افتح الوظائف",
    "All New Applicants": "جميع المتقدمين الجدد",
    "Tell us about your issue so we can help.": "أخبرنا عن مشكلتك حتى نتمكن من مساعدتك.",
    "View Job": "مشاهدة الوظيفة",
    "Teacher Settings": "إعدادات المعلم",
    "Change Password": "غير كلمة السر",
    "Change Name": "تغيير الإسم",
    "Change Location": "تغيير الموقع",
    "Current Postion": "الموقف الحالى",
    "Willing To Travel": "النية للسفر",
    "Main Subject": "الموضوع الرئيسي",
    "All": "الكل",
    "Jobs Results": "نتائج الوظائف",
    "Click on the options below to access additional resources and information related to your Madares account.": "انقر فوق الخيارات أدناه للوصول إلى موارد ومعلومات إضافية متعلقة بحساب Madares الخاص بك.",
    "CHILD PROTECTION": "حماية الطفل",
    "RECRUITMENT RESOURCES": "موارد التوظيف",
    "REPORTS": "التقارير",
    "ORDERS": "الطلب",
    "DIVERSITY": "تنوع",
    "Make your school safer with these recommended recruitment best practices from the international Task Force on Child Protection.": "اجعل مدرستك أكثر أمانًا من خلال أفضل ممارسات التوظيف الموصى بها من فريق العمل الدولي المعني بحماية الطفل.",
    "Stay up to date with the latest in recruitment best practices, industry insights and trends.": "ابق على اطلاع بأحدث ممارسات التوظيف ورؤى الصناعة والاتجاهات.",
    "Make informed decisions to optimize your recruitment with on-demand reports and detailed job posting analytics.": "اتخذ قرارات مستنيرة لتحسين التوظيف من خلال التقارير عند الطلب وتحليلات نشر الوظائف التفصيلية.",
    "Review your complete order history, access invoices and view payment status.": "قم بمراجعة سجل الطلبات الكامل الخاص بك ، والوصول إلى الفواتير وعرض حالة الدفع.",
    "At Teach Away, we Embrace Diversity. In fact, it’s one of our values.": "في Teach Away ، نتبنى التنوع. في الواقع ، إنها إحدى قيمنا.",
    "Last Modified": "آخر تعديل",
    "We invite you to join us and the rest of the international education community in taking additional steps, outlined in the documents below, toward making our schools safer spaces for children.": "ندعوك للانضمام إلينا وإلى بقية مجتمع التعليم الدولي في اتخاذ خطوات إضافية ، موضحة في الوثائق أدناه ، نحو جعل مدارسنا أماكن أكثر أمانًا للأطفال.",
    "Madares is a proud member of the International Task Force on Child Protection (ITFCP) and were part of the core group of experts tasked with creating the Recommended Screening and Assessment Practices for International Teacher Recruitment.": "Madares عضو فخور في فرقة العمل الدولية لحماية الطفل (ITFCP) وكانت جزءًا من المجموعة الأساسية من الخبراء المكلفين بإنشاء ممارسات الفحص والتقييم الموصى بها لتوظيف المعلمين الدوليين.",
    "Child Protection": "حماية الطفل",
    "International Task Force on Child Protection (ITFCP): Recommended Screening and Assessment Practices for International School Recruitment": "فرقة العمل الدولية المعنية بحماية الطفل (ITFCP): ممارسات الفحص والتقييم الموصى بها لتوظيف المدارس الدولية",
    "Resources": "موارد",
    "Stay up-to-date with the latest in recruitment best practices, industry insights and trends from the world of international education recruitment. Access our latest resources below.": "ابق على اطلاع دائم بأحدث ممارسات التوظيف ورؤى الصناعة والاتجاهات من عالم التوظيف الدولي في مجال التعليم. الوصول إلى أحدث مواردنا أدناه.",
    "The Impact of COVID-19 on Teachers (Infographic)": "تأثير COVID-19 على المعلمين (إنفوجرافيك)",
    "Online Job Fairs: A Guide to Successful Digital Recruitment": "معارض الوظائف عبر الإنترنت: دليل للتوظيف الرقمي الناجح",
    "The 2020 International Education Recruitment Report": "تقرير التوظيف الدولي لعام 2020",
    "How to write a job description that attracts the candidates you’re looking for": "كيفية كتابة وصف وظيفي يجذب المرشحين الذين تبحث عنهم",
    "K-12 International Education Recruitment Report 2021": "k-12 تقرير التوظيف في التعليم الدولي 2021",
    "Diversity is one of our hallmarks": "التنوع هو إحدى السمات المميزة لدينا",
    "At Madares, we Embrace Diversity. In fact, it’s one of our values. We also believe that the best way to create an inclusive world is through education and cultural exchange. That’s why we’re committed to building an internal culture and a community of partners that promote knowledge, diversity and inclusivity.": "في المدارس ، نتبنى التنوع. في الواقع ، إنها إحدى قيمنا. نعتقد أيضًا أن أفضل طريقة لخلق عالم شامل هي من خلال التعليم والتبادل الثقافي. لهذا السبب نحن ملتزمون ببناء ثقافة داخلية ومجتمع من الشركاء الذين يعززون المعرفة والتنوع والشمولية.",
    "“It is time for parents to teach young people early on that in diversity there is beauty and there is strength.”": '"لقد حان الوقت للآباء لتعليم الشباب في وقت مبكر أنه في التنوع يوجد جمال وهناك قوة."',
    "We’re all different – and that’s the way we like it": "نحن جميعًا مختلفون - وهذه هي الطريقة التي نحبها",
    "We celebrate our differences. ": " نحتفل باختلافاتنا.",
    " is made up of people from an assortment of countries across the globe, from different cultures, religions, ages, genders, colours and physical appearances. We embrace this diversity because we know that it is these differences make us stronger, smarter, better.": " يتكون من أشخاص من مجموعة متنوعة من البلدان في جميع أنحاء العالم ، من مختلف الثقافات والأديان والأعمار والأجناس والألوان والمظاهر الجسدية. نحن نحتضن هذا التنوع لأننا نعلم أن هذه الاختلافات تجعلنا أقوى وأكثر ذكاءً وأفضل.",
    "We encourage our community to express who they are with pride, respect and without fear. We’re proud to stand for equality and fairness and we encourage our partners to join us as we move along this path.": "نشجع مجتمعنا على التعبير عن هويتهم بكل فخر واحترام وبدون خوف. نحن فخورون بالدفاع عن المساواة والإنصاف ونشجع شركائنا على الانضمام إلينا ونحن نمضي على هذا الطريق.",
    "The road is long but we’re committed to traveling it": "الطريق طويل لكننا ملتزمون بالسير فيه",
    "We know we still have a lot of work to do on our journey, but we’re committed to continuing to focus on diversity and equality. We’re also passionate about sharing what we learn with our partners.": "نعلم أنه لا يزال لدينا الكثير من العمل الذي يتعين علينا القيام به في رحلتنا ، لكننا ملتزمون بمواصلة التركيز على التنوع والمساواة. نحن أيضًا متحمسون لمشاركة ما نتعلمه مع شركائنا.",
    "Contact us": "اتصل بنا",
    "Our team": "فريقنا",
    " to learn more about our commitment to creating an inclusive community, without discrimination.": " لمعرفة المزيد حول التزامنا بإنشاء مجتمع شامل دون تمييز.",
    "Canadian School Boards Diversity Statements": "بيانات التنوع في مجالس المدارس الكندية",
    "US School Boards Diversity Statemenents": "هيئات التنوع في مجالس المدارس الأمريكية",
    "UK School Boards Diversity Statements": "بيانات التنوع في مجالس المدارس في المملكة المتحدة",
    "Maya Angelou": "مايا أنجيلو",
    "British Council: Inclusion and Diversity in Education: Guidelines for Diversity and Inclusion in Schools": "المجلس الثقافي البريطاني: الشمول والتنوع في التعليم: إرشادات للتنوع والدمج في المدارس",
    "Fresno United School District: Diversity, Equity, and Inclusion": "مقاطعة مدرسة فريسنو المتحدة: التنوع والمساواة والشمول",
    "Ohio State Board of Education: Diversity Strategies for Successful Schools Policies": "مجلس ولاية أوهايو للتعليم: استراتيجيات التنوع لسياسات المدارس الناجحة",
    "Peel District School Board: Equity and Inclusive Education": "مجلس مدرسة مقاطعة بيل: الإنصاف والتعليم الشامل",
    "British Columbia Ministry of Education: Diversity in BC Schools: A Framework": "وزارة التعليم في كولومبيا البريطانية: التنوع في مدارس كولومبيا البريطانية: إطار",
    "Toronto District School Board: Elementary School Equitable and Inclusive Education": "مجلس مدرسة مقاطعة تورونتو: التعليم الابتدائي المنصف والشامل",
    "Your Orders": "طلباتك",
    "Order": "ترتيب",
    "Payment Method": "طريقة الدفع او السداد",
    "Amount": "مقدار",
    "Job title is required": "المسمى الوظيفي مطلوب",
    "Country is required": "الدولة مطلوبة",
    "City is required": "المدينة مطلوبة",
    "Job description is required": "الوصف الوظيفي مطلوب",
    "Please add subjects": "الرجاء إضافة الموضوعات",
    "Please add education levels": "الرجاء إضافة مستويات التعليم",
    "Minimum salary is required": "يشترط حد أدنى للراتب",
    "Job start date and close date can't be the same": "لا يمكن أن يكون تاريخ بدء الوظيفة وتاريخ الإغلاق متماثلين",
    "Job start date can't come after the end date": "لا يمكن أن يأتي تاريخ بدء الوظيفة بعد تاريخ الانتهاء",
    "Job close date can't be today": "لا يمكن أن يكون تاريخ إغلاق الوظيفة اليوم",
    "You must accept Madares job posting terms and conditions before posting a job": "يجب عليك قبول شروط وأحكام نشر الوظائف الخاصة بـ Madares قبل نشر الوظيفة",
    "You don't have any Active jobs": "ليس لديك أي وظائف نشطة",
    "Yes": "نعم",
    "No": "لا",
    "drafts": "المسودات",
    "resources": "موارد",
    "Applicant preview: New": "معاينة مقدم الطلب: جديد",
    "Female Early Years Teacher": "معلمة السنوات الأولى للإناث",
    "5 applicants to review": "5 متقدمين للمراجعة",
    "Living in Johannesburg, ZA": "الذين يعيشون في جوهانسبرغ ، ZA",
    "Resume": "سيرة ذاتية",
    "5 years teaching experience": "5 سنوات خبرة في التدريس",
    "South Africa": "جنوب أفريقيا",
    "3 Referees": "3 حكام",
    "Bachelor, B.Ed (intermediate/senior )": "بكالوريوس ، B.Ed (متوسط ​​/ كبير)",
    "UNISA": "UNISA",
    "1 more": "1 أكثر",
    "Certificate and license": "الشهادة والترخيص",
    "Teacher Qualification": "تأهيل المعلم",
    "SACE": "سرقة",
    "Teaching experience": "خبرة في التدريس",
    "Referees": "المراجع",
    "Nurul Islam school , South Africa": "مدرسة نور الإسلام ، جنوب إفريقيا",
    "Tasneem Sheik": "تسنيم شيك",
    "Department head - Nirvana secondary school - Sout...": "رئيس قسم - مدرسة نيرفانا الثانوية - صوت ...",
    "Edit Academy Profile": "تحرير ملف الأكاديمية",
    "Change General Information": "تغيير المعلومات العامة",
    "Change Files": "تغيير الملفات",
    "Academy Name": "اسم الاكاديمية",
    "Grades": "درجات",
    "Academy website": "موقع الأكاديمية",
    "Organization Type": "نوع المنظمة",
    "Specialize in": "متخصص في",
    "Academy Description": "وصف الأكاديمية",
    "Upload Academy Logo": "تحميل شعار الاكاديمية",
    "Maximum Size 10 MB": "أقصى حجم 10 ميجا بايت",
    "Recommended Files:- jpg, png, jpeg": "الملفات الموصى بها: - jpg، png، jpeg",
    "Upload Academy Banner": "تحميل شعار الأكاديمية",
    "International Accreditation": "الاعتماد الدولي",
    "We are a recognised educational institution by our Federal or State/Provincial Government": "نحن مؤسسة تعليمية معترف بها من قبل الحكومة الفيدرالية أو حكومة الولاية / المقاطعة",
    "Files": "الملفات",
    "Upload new files": "تحميل ملفات جديدة",
    "Recommended Files:- doc, docx, pdf, jpg, png, jpeg": "الملفات الموصى بها: - doc، docx، pdf، jpg، png، jpeg",
    "Academy Settings": "إعدادات الأكاديمية",
    "New Password": "كلمة السر الجديدة",
    "Current Password": "كلمة المرور الحالية",
    "Confirm Password": "تأكيد كلمة المرور",
    "Change Email": "تغيير الايميل",
    "Old Email": "البريد الإلكتروني القديم",
    "New Email": "بريد إلكتروني جديد",
    "First name": "الاسم الاول",
    "Last name": "الكنية",
    "Information": "معلومة",
    "Edit Teacher Profile": "تحرير ملف تعريف المعلم",
    "Change Education": "تغيير التعليم",
    "Change Experience": "تغيير التجربة",
    "Change License": "تغيير الترخيص",
    "Change Documents": "تغيير المستندات",
    "Profile Picture": "الصوره الشخصيه",
    "Banner Picture": "صورة لافتة",
    "Which job title fits you best?": "ما هو المسمى الوظيفي الأنسب لك؟",
    "Citizenship(s)": "المواطنة",
    "Fluent Languages": "لغات بطلاقة",
    "Recommended Files:- doc, docx, pdf": "الملفات الموصى بها: - doc، docx، pdf",
    "I consent to Madares receiving this document and sharing it with employers": "أوافق على تلقي المدارس هذا المستند ومشاركته مع أصحاب العمل",
    "Add Education": "أضف التعليم",
    "Degree level": "مستوى الدرجة",
    "Field of study": "مجال الدراسة",
    "Year of graduation": "سنة التخرج",
    "Cancel": "إلغاء",
    "Select": "يختار",
    "Math": "رياضيات",
    "University Name": "اسم الجامعة",
    "Edit Education": "تحرير التعليم",
    "Delete": "حذف",
    "Ongoing": "جاري التنفيذ",
    "Experience Type": "نوع الخبرة",
    "Position Title": "المسمى الوظيفي",
    "School or District": "مدرسة أو منطقة",
    "Curriculum you taught": "المنهج الذي قمت بتدريسه",
    "Grade(s) you taught": "الصف الذي قمت بتدريسه",
    "Subject(s) you taught": "المادة التي قمت بتدريسها",
    "From": "من",
    "To": "إلى",
    "I am currently working in this role": "أنا أعمل حاليًا في هذا الدور",
    "Select Grade(s)": "حدد الدرجة",
    "Edit Experience": "تحرير التجربة",
    "Licenses": "التراخيص",
    "Add License": "إضافة ترخيص",
    "License Type": "نوع الرخصة",
    "Grade(s) you are licensed to teach": "الدرجة المرخصة لك للتدريس",
    "Subject(s) you are licensed to teach": "الموضوع المرخص لك للتدريس",
    "Country your license was issued in": "البلد الذي صدر فيه الترخيص الخاص بك",
    "Valid from": "صالح من تاريخ",
    "Expiry": "انقضاء",
    "No expiration date": "لا تاريخ انتهاء الصلاحية",
    "Edit License": "تحرير الترخيص",
    "Teaching License": "رخصة تدريس",
    "Document": "وثيقة",
    "Add Document": "أضف وثيقة",
    "Document type": "نوع الوثيقة",
    "Upload document": "تحميل الوثيقة",
    " year of graduation": "سنة التخرج"
}