import React, { Fragment, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

// import { getAllTeachers } from "../../../../store/actions/teachers";
import JobDataTable from "../academyJobFlow/ManageJobs/JobDataTable";
import ApplicantionDataTable from "../academyJobFlow/Applicants/ApplicantsDataTable";
import { Modal } from "react-bootstrap";
import UserProfile from "../academyJobFlow/Applicants/UserProfile";
import { getAcademyJobs } from "../../../../store/actions/Jobs";
import { getAcademyApplicants } from "../../../../store/actions/applicants";
import Statistics from "../analytics/Statistics";

const Home = () => {

  const search = useLocation().search;
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [pageNumber, setPageNumber] = useState(1)
	const currentPage = useSelector((state) => state?.academyJobs?.currentPage);
	const nextPage = useSelector((state) => state?.academyJobs?.nextPage);
	const lastPage = useSelector((state) => state?.academyJobs?.lastPage);
	const academyJobs = useSelector((state) => state?.academyJobs?.academyJobs);
  const academyJobsLoading = useSelector((state) => state?.academyJobs?.loading);

  const [pageNumberApp, setPageNumberApp] = useState(1)
	const currentPageApp = useSelector((state) => state?.academyApplicants?.currentPage);
	const nextPageApp = useSelector((state) => state?.academyApplicants?.nextPage);
	const lastPageApp = useSelector((state) => state?.academyApplicants?.lastPage);
	const applicants = useSelector((state) => state?.academyApplicants?.academyApplicants);
  const applicantsLoading = useSelector((state) => state?.academyApplicants?.loading);

  const [loadingModalShowApp, setLoadingModalShowApp] = useState(applicantsLoading);
  const [loadingModalShow, setLoadingModalShow] = useState(academyJobsLoading);

  const [forceLoad, setForceLoad] = useState(0);
  const [forceLoadApplicants, setForceLoadApplicants] = useState(0);

  useEffect(() => {
    dispatch(getAcademyJobs('', lng === "arab" ? 1 : 2, '1'));
  }, [forceLoad])
  useEffect(() => {
    dispatch(getAcademyApplicants({job_status: 1}, lng === "arab" ? 1 : 2, '1'));
  }, [forceLoadApplicants])

  useEffect(() => {
    setLoadingModalShow(academyJobsLoading);

  }, [academyJobsLoading]);
  useEffect(() => {
    setLoadingModalShowApp(applicantsLoading);
  }, [applicantsLoading]);

  const dispatch = useDispatch();
  const [numbersOfPages, setNumbersOfPages] = useState([]);
  const [numbersOfPagesApp, setNumbersOfPagesApp] = useState([]);

  const [applicantPopUp, setApplicantPopUp] = useState(false);

  const [applicantsData, setApplicantsData] = useState();

	useEffect(() => {
		let array = []
		let pages = lastPage
		while (pages > 0 ) {
			array.push(pages)
			pages--
		}
	  setNumbersOfPages(array.reverse())
  }, [lastPage])
  useEffect(() => {
		let array = []
		let pages = lastPageApp
		while (pages > 0 ) {
			array.push(pages)
			pages--
		}
	  setNumbersOfPagesApp(array.reverse())
  }, [lastPageApp])

  // useEffect(() => {

  //   dispatch(getAllTeachers(pageNumber));

  // }, [pageNumber]);


  return (

    <Fragment>

      <div className="row pb-5">

        <div className="col-xl-12">

          <JobDataTable
            forceLoad={forceLoad}

            setForceLoad={setForceLoad}

            firstFive = {true}

            jobs={academyJobs}

            setPageNumber={setPageNumber}

            numbersOfPages={numbersOfPages}

            loadingModalShow={loadingModalShow}

          />

        </div>

      </div>

      <div className="row pb-5">

        <div className="col-xl-12">

          <ApplicantionDataTable
            firstFive={true}
            applicants={applicants.slice(0,5)}
            setPageNumber={setPageNumberApp}
            numbersOfPages={numbersOfPagesApp}
            setApplicantPopUp={setApplicantPopUp}
            setApplicantsData={setApplicantsData}
            loadingModalShow={loadingModalShowApp}
          />

        </div>

      </div>

      {/* <div className="row">
        <div className="col-xl-12">
          <Statistics search={true}/>
        </div>
      </div> */}
      <ApplicantsModal
        show={applicantPopUp}
        onHide={() => setApplicantPopUp(false)}

        data={applicantsData}
      />

    </Fragment>

  );

};

export default Home;


const ApplicantsModal = (props) => {

  return (

    <Modal {...props} centered className="fade applicantModal">
      <Modal.Body>

      <UserProfile data={props.data} onHide={props.onHide}/>

      </Modal.Body>
    </Modal>

  );

};
