import { t } from "i18next";
import React from "react";
import { useHistory } from "react-router-dom";

const Resources = () => {
  const history = useHistory();
  return (
    <div>
      <div className="resource-tool">
        <h3>{t('Resources and Tools')}</h3>
        <div className="resource-description">
          <p>
            {t('Click on the options below to access additional resources and information related to your Madares account.')}
          </p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="attends" onClick={() => history.push("/resources-child-protection")}>
                  <div className="inner-image">
                    <img src="https://d3fcz7ljffveq5.cloudfront.net/sites/all/themes/ats/css/images/resources/child_protection.svg" />
                  </div>
                  <div className="inner">
                    <h3 className="resource-header">{t('CHILD PROTECTION')}</h3>
                    <p className="resource-paragraph">
                      {t('Make your school safer with these recommended recruitment best practices from the international Task Force on Child Protection.')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="attends" onClick={() => history.push("/recruitment-resources")}>
                  <div className="inner-image">
                    <img src="https://d3fcz7ljffveq5.cloudfront.net/sites/all/themes/ats/css/images/resources/recruitment_resources.svg" />
                  </div>
                  <div className="inner">
                    <h3 className="resource-header">{t('RECRUITMENT RESOURCES')}</h3>
                    <p className="resource-paragraph">
                      {t('Stay up to date with the latest in recruitment best practices, industry insights and trends.')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="attends" onClick={() => history.push("/analytics")}>
                  <div className="inner-image">
                    <img src="https://d3fcz7ljffveq5.cloudfront.net/sites/all/themes/ats/css/images/resources/reports.svg" />
                  </div>
                  <div className="inner">
                    <h3 className="resource-header">{t('REPORTS')}</h3>
                    <p className="resource-paragraph">
                      {t('Make informed decisions to optimize your recruitment with on-demand reports and detailed job posting analytics.')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 resource-second-line">
                <div className="attends" onClick={() => history.push("/order-resources")}>
                  <div className="inner-image">
                    <img src="https://d3fcz7ljffveq5.cloudfront.net/sites/all/themes/ats/css/images/resources/orders.svg" />
                  </div>
                  <div className="inner">
                    <h3 className="resource-header">{t('ORDERS')}</h3>
                    <p className="resource-paragraph">
                      {t('Review your complete order history, access invoices and view payment status.')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="attends" onClick={() => history.push('/diversity-resources')}>
                  <div className="inner-image">
                    <img src="https://d3fcz7ljffveq5.cloudfront.net/sites/all/themes/ats/css/images/resources/diversity.svg" />
                  </div>
                  <div className="inner">
                    <h3 className="resource-header">{t('DIVERSITY')}</h3>
                    <p className="resource-paragraph">
                      {t('At Teach Away, we Embrace Diversity. In fact, it’s one of our values.')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
