import React, { Fragment, useEffect, useState } from "react";
import "./HomeTeacher.css";
import bannerDefault from "../../../../assets/images/svg/banner.svg";
import logoDefault from "../../../../assets/images/svg/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSuggested, getTeacherApplicantions } from "../../../../store/actions/teachers";
import Loading from "../../../layouts/loadingComponents/Loading";
import moment from "moment";
import noData from "../../../../assets/images/svg/noData.svg";
import { useHistory } from "react-router-dom";
import { savedJob, unSaveJob } from "../../../../store/actions/Jobs";
let lng = JSON.parse(localStorage.getItem("language"))?.lang;

const NewHomeTeacher = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const { teacherApplicantions, loading } = useSelector(
    (state) => state?.teacherApplicantions
  );
  const savedJobs = useSelector((state) => state?.savedJobs?.savedJobs);
  const savedJobsLoading = useSelector((state) => state?.savedJobs?.loading);
  const suggested = useSelector((state) => state?.suggested?.suggested);
  const suggestedLoading = useSelector((state) => state?.suggested?.loading);
  const [forceLoad, setForceLoad] = useState(0);

  useEffect(() => {
    dispatch(getTeacherApplicantions(lng === "arab" ? 1 : 2));
    dispatch(getSuggested(lng === "arab" ? 1 : 2));
  }, []);
  useEffect(() => {
      dispatch(savedJob(lng === "arab" ? 1 : 2));
  }, [forceLoad]);
  const handleUnSave = (item) => {
    setLoadingModalShow(true);
    dispatch(unSaveJob(item.id, setForceLoad, forceLoad, setLoadingModalShow));
  }
  useEffect(() => {
    setLoadingModalShow(loading || savedJobsLoading || suggestedLoading);
  }, [loading || savedJobsLoading || suggestedLoading]);
  return (
    <Fragment>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="home-teacher-page">
            {/* Group 1 */}
            <div className="home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">
                Applications
              </div>
              {teacherApplicantions?.data?.length === 0 ? null : (
                <button
                  className="home-teacher-page-group-1-btn"
                  onClick={() => history.push("/applications")}
                >
                  View All Applicantions
                </button>
              )}
            </div>
            <Jobs
              title={"There are no applications :("}
              data={teacherApplicantions?.data?.slice(0, 5)}
              type={"Applications"}
            />

            {/* Group 2 */}
            <div className="saved-jobs home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">Saved Jobs</div>
              {savedJobs?.length === 0 ? null : (
                <button
                  className="home-teacher-page-group-1-btn"
                  onClick={() => history.push("/saved-jobs")}
                >
                  View All Saved Jobs
                </button>
              )}
            </div>
            <Jobs
              title={"There are no saved jobs :("}
              data={savedJobs?.slice(0, 5)}
              type={"Saved"}
              handleUnSave={handleUnSave}
            />

            {/* Group 3 */}
            <div className="suggested-for-you home-teacher-page-group-1">
              <div className="home-teacher-page-group-1-label">
                Suggested For You
              </div>
              {suggested?.length === 0 ? null : (
                <button className="home-teacher-page-group-1-btn" onClick={() => history.push('/suggested')}>
                  View All Jobs
                </button>
              )}
            </div>
            <Jobs
              title={"There are no jobs :("}
              data={suggested?.slice(0, 5)}
              type={"Suggested"}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewHomeTeacher;

export const Jobs = ({ title, data, type, handleUnSave }) => {

  return (
    <Fragment>
      <div className="home-teacher-page-group-2">
        <div className="home-teacher-page-group-2-main">
          {data?.length === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img src={noData} />
                <div
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </div>
              </div>
            </>
          ) : (
            data?.map((item, index) => (
              <Fragment>
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-2-1">
                      <img
                        src={
                          item?.job?.academy?.banner ||
                          item?.academy?.banner ||
                          bannerDefault
                        }
                        alt={"banner"}
                        className="home-teacher-page-2-1-img"
                      />
                      <img
                        src={
                          item?.job?.academy?.avatar ||
                          item?.academy?.avatar ||
                          logoDefault
                        }
                        alt={"logo"}
                        className="home-teacher-page-2-1-logo-frame"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-2">
                      <div>
                        <div className="home-teacher-page-group-2-2-label-1" style={{ textTransform: "capitalize" }}>
                          {item?.job?.title || item?.title || "N/A"}
                        </div>
                        <div className="home-teacher-page-group-2-2-label-2">
                          {`Start in ${
                            item?.job?.location?.city || item?.location?.city
                          } - ${moment(
                            item?.job?.start_date || item?.start_date
                          ).format("MMM YYYY")}`}
                        </div>
                      </div>
                      <div>
                        <div className="home-teacher-page-group-2-2-label-3">
                          Eligible Candidates:
                        </div>
                        <div className="home-teacher-page-group-2-2-label-4">
                          {item?.job?.types_of_educators
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.types_of_educators
                              ?.map((item) => item?.name)
                              ?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-3">
                      <div>
                        <div className="home-teacher-page-group-2-3-label-1">
                          Grade Level:
                        </div>
                        <div className="home-teacher-page-group-2-3-label-2">
                          {item?.job?.grades
                            ?.map((item) => item?.name)
                            ?.join(", ") ||
                            item?.grades?.map((item) => item?.name)?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="home-teacher-page-group-2-4">
                      <div className="home-teacher-page-group-2-4-label-1" onClick={() => window.location.replace(`https://cvsa.mvp-apps.ae/job-details/${item?.job_id || item?.id}`)}>
                        View Job Details
                        <svg
                          style={{ marginLeft: "5px" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.522"
                          height="16.522"
                          viewBox="0 0 16.522 16.522"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-dropup-circle"
                            data-name="Icon ionic-ios-arrow-dropup-circle"
                            d="M8.261,16.522A8.261,8.261,0,1,0,0,8.261,8.26,8.26,0,0,0,8.261,16.522Zm0-9.711L5.04,10.064A.767.767,0,0,1,3.956,8.98l3.8-3.789a.766.766,0,0,1,1.056-.024L12.558,8.9a.765.765,0,1,1-1.08,1.084Z"
                            transform="translate(16.522) rotate(90)"
                            fill="#fc563e"
                          />
                        </svg>
                      </div>
                      <div>
                        {type === "Applications" ? (
                          <Fragment>
                            <div className="home-teacher-page-group-2-4-label-2">
                              Application Status
                            </div>
                            <div className="home-teacher-page-group-2-4-label-3-container">
                              <div className="home-teacher-page-group-2-4-label-3">
                                {item?.status?.name || "N/A"}
                              </div>
                            </div>
                          </Fragment>
                        ) : type === "Saved" ? (
                          <Fragment>
                            <button className="saved-jobs-btn" onClick={() => handleUnSave(item)}>Unsave</button>
                          </Fragment>
                        ) : type === "Suggested" ? (
                          <Fragment>
                            <button className="apply-now-btn" onClick={() => window.location.replace(`https://cvsa.mvp-apps.ae/job-details/${item?.job_id || item?.id}`) }>
                              Apply Now
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.494"
                                height="14.494"
                                viewBox="0 0 14.494 14.494"
                              >
                                <g
                                  id="Icon_feather-plus"
                                  data-name="Icon feather-plus"
                                  transform="translate(-6.5 -6.5)"
                                >
                                  <path
                                    id="Path_931"
                                    data-name="Path 931"
                                    d="M18,7.5V19.994"
                                    transform="translate(-4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  />
                                  <path
                                    id="Path_932"
                                    data-name="Path 932"
                                    d="M7.5,18H19.994"
                                    transform="translate(0 -4.253)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  />
                                </g>
                              </svg>
                            </button>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {data?.length > index + 1 ? (
                  <hr style={{ margin: "30px 0px" }}></hr>
                ) : null}
              </Fragment>
            ))
          )}
        </div>
      </div>
    </Fragment>
  );
};
