import { t } from "i18next";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { createHelpRequest } from "../../../store/actions/helpRequest";
import Loading from "../../layouts/loadingComponents/Loading";
import { toastAlert } from "../../layouts/toastAlert/toastAlert";

let assistanceType = [
  { id: "1", name: "Customer Service for schools" },
  { id: "2", name: "Customer Service for teachers" },
  { id: "3", name: "Customer Service for accessibility" },
];

let schoolServices = [
  { id: "1", name: "Technical Support" },
  { id: "2", name: "Edits to my job posting" },
  { id: "3", name: "Upgrade my subscription" },
  { id: "4", name: "Feedback and suggestions" },
  { id: "5", name: "Delete my account" },
  { id: "6", name: "Other" },
];
let teacherServices = [
  { id: "1", name: "Technical Support" },
  { id: "5", name: "Delete my account" },
  { id: "6", name: "Other" },
];

const Help = () => {
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [helpRequest, setHelpRequest] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    assistance_type: "",
    assistance_sub_type: "",
    additional_info: "",
  });
  const submitForm = () => {
    console.log("helpRequest", helpRequest);
    setLoadingModalShow(true);
    let errorArr = [];
    let userData = {
      ...helpRequest,
    };
    if (!userData.first_name) {
      errorArr.push(t("First name is required"));
    }
    if (!userData.last_name) {
      errorArr.push(t("Last name is required"));
    }
    if (!userData.email) {
      errorArr.push(t("Email is required"));
    }
    if (!userData.assistance_type) {
      errorArr.push(t("assistance type is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(createHelpRequest(userData, setLoadingModalShow));
  };
  return (
    <>
      {loadingModalShow ? (
        <div
          style={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="teacher_reg_steps pb-5" />
          <div className="d-flex align-items-center mb-4">
            <h4 className="fs-20 font-w600 mb-0 me-auto">
              {t("Tell us about your issue so we can help.")}
            </h4>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="row">
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w600">
                          {t("First Name")}
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control solid"
                          placeholder={t("First name")}
                          value={helpRequest.first_name}
                          name="first_name"
                          onChange={(e) =>
                            setHelpRequest({
                              ...helpRequest,
                              first_name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w600">
                          {t("Last Name")}
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control solid"
                          placeholder={t("Last name")}
                          value={helpRequest.last_name}
                          name="last_name"
                          onChange={(e) =>
                            setHelpRequest({
                              ...helpRequest,
                              last_name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 mb-4">
                        <label className="form-label font-w600">
                          {t("Email")}
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control solid"
                          placeholder={t("Example@example.com")}
                          name="email"
                          value={helpRequest.email}
                          onChange={(e) =>
                            setHelpRequest({
                              ...helpRequest,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-xl-6  col-md-6 mb-4">
                        <label className="form-label font-w600">
                          {t("Contact Number")}
                        </label>
                        <PhoneInput
                          id="phone"
                          className="form-control solid"
                          style={{ display: "flex" }}
                          placeholder={t("Enter phone number")}
                          value={helpRequest.phone_number}
                          onChange={(e) =>
                            setHelpRequest({ ...helpRequest, phone_number: e })
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-md-12 mb-4">
                        <label className="form-label font-w600">
                          {t("What type of assistance do you require?")}
                          <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <select
                          name="assistance_type"
                          className="form-control solid"
                          value={helpRequest.assistance_type}
                          onChange={(e) =>
                            setHelpRequest({
                              ...helpRequest,
                              assistance_type: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {t("Please select assistance type")}
                          </option>
                          {assistanceType?.map((item, i) => (
                            <option key={i} value={item?.name}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {helpRequest.assistance_type ===
                    "Customer Service for schools" ? (
                      <div className="row">
                        <div className="col-xl-12 col-md-12 mb-4">
                          <label className="form-label font-w600">
                            {t("Type of teacher assistance required")}
                          </label>
                          <select
                            name="assistance_sub_type"
                            className="form-control solid"
                            value={helpRequest.assistance_sub_type}
                            onChange={(e) =>
                              setHelpRequest({
                                ...helpRequest,
                                assistance_sub_type: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              {t("Please select assistance sub type")}
                            </option>
                            {schoolServices?.map((item, i) => (
                              <option key={i} value={item?.name}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {helpRequest.assistance_type ===
                    "Customer Service for teachers" ? (
                      <div className="row">
                        <div className="col-xl-12 col-md-12 mb-4">
                          <label className="form-label font-w600">
                            {t("Type of teacher assistance required")}
                          </label>
                          <select
                            name="assistance_sub_type"
                            className="form-control solid"
                            value={helpRequest.assistance_sub_type}
                            onChange={(e) =>
                              setHelpRequest({
                                ...helpRequest,
                                assistance_sub_type: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              {t("Please select assistance sub type")}
                            </option>
                            {teacherServices?.map((item, i) => (
                              <option key={i} value={item?.name}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-xl-12 col-md-12 mb-4">
                        <label className="form-label font-w600">
                          {t(
                            "Please include any additional information that may help us assist you!"
                          )}
                        </label>
                        <textarea
                          className="form-control solid"
                          rows="4"
                          placeholder={t("Information")}
                          name="newPassword"
                          value={helpRequest.additional_info}
                          onChange={(e) =>
                            setHelpRequest({
                              ...helpRequest,
                              additional_info: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        style={{ display: "flex", gap: "7px" }}
                        className="col-xl-6  col-md-6 mb-4"
                      ></div>
                      <div className="card-footer text-end">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            to={"#"}
                            className="btn btn-primary"
                            onClick={submitForm}
                          >
                            {t("Save")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Help;
