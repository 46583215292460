import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllTeachers } from "../../../../../store/actions/teachers";
import ApplicantionDataTable from "./ApplicantsDataTable";
import { Modal } from "react-bootstrap";
import UserProfile from "./UserProfile";
import { t } from "i18next";
import { getAcademyApplicants } from "../../../../../store/actions/applicants";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../../../layouts/loadingComponents/Loading";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { updateApplicationStatus } from "../../../../../store/actions/applicants";
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";
import {
  getAllJobTitles,
  getApplicationStatus,
  getGrades,
  getNewSubjects,
  getJobTitle,
  getDegreeLevels,
  getCitizenships,
  getLanguages,
  getCurriculums,
} from "../../../../../store/actions/Jobs";
import NewApplicantionDataTable from "./NewApplicantsDataTable";
import "../../homePage/HomePage.css"

const NewApplicants = (props) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const pg = new URLSearchParams(search).get("pg");
  const id = new URLSearchParams(search).get("id");
  const allJobTitles = useSelector(
    (state) => state?.allJobTitles?.allJobTitles
  );
  const allJobTitlesLoading = useSelector(
    (state) => state?.allJobTitles?.loading
  );
  const history = useHistory();
  useEffect(() => {
    dispatch(getAllJobTitles(lng === "arab" ? 1 : 2));
    dispatch(getApplicationStatus(lng === "arab" ? 1 : 2));
  }, []);
  const [dropDownElement, setDropDownElement] = useState(
    id && allJobTitles?.filter((item) => item?.id == id)?.length > 0
      ? allJobTitles?.filter((item) => item?.id == id)?.shift()?.title
      : "All Jobs"
  );
  useEffect(() => {
    if (id && !allJobTitlesLoading && allJobTitles.length > 0) {
      if (allJobTitles?.filter((item) => item?.id == id)?.length === 0) {
        history.push("/applicants");
      } else {
        setDropDownElement(
          id && allJobTitles?.filter((item) => item?.id == id)?.length > 0
            ? allJobTitles?.filter((item) => item?.id == id)?.shift()?.title
            : "All Jobs"
        );
      }
    } else if (!allJobTitlesLoading && allJobTitles.length === 0) {
      console.log(allJobTitlesLoading);
      console.log(allJobTitles);
    }
  }, [id, allJobTitles]);
  const [pageNumber, setPageNumber] = useState(pg || 1);
  const [jobIdRoute, setJobIdRoute] = useState(id || "");
  const [searchData, setSearchData] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [submitFilterStatus, setSubmitFilterStatus] = useState(0);
  const [forceLoad, setForceLoad] = useState(0);
  const [filterData, setFilterData] = useState({
    licenses_and_certifications: [],
    citizenships: [],
    educations: [],
    experience: "",
    date_applied: "",
    subjects: [],
    grades: [],
    curriculums: [],
    locations: [],
    languages: [],
    teacher_categories: [],
  });
  const [applicantsDataForm, setApplicantsDataform] = useState({
    job_id: "",
    search: "",
    application_status: "",
    job_status: "",
    filters: {},
  });
  useEffect(() => {
    setPageNumber(pg || 1);
  }, [pg]);
  useEffect(() => {
    setJobIdRoute(id || "");
  }, [id]);
  const [checkedUsers, setCheckedUseres] = useState([]);

  useEffect(() => {
    let formData = {
      ...applicantsDataForm,
      job_id: jobIdRoute,
      search: searchData,
      application_status: applicationStatus,
      job_status: jobStatus,
      filters: filterData,
    };
    setCheckedUseres([]);
    dispatch(
      getAcademyApplicants(formData, lng === "arab" ? 1 : 2, "1", pageNumber)
    );
  }, [
    pageNumber,
    jobIdRoute,
    searchData,
    applicationStatus,
    jobStatus,
    submitFilterStatus,
    forceLoad,
  ]);

  const currentPage = useSelector(
    (state) => state?.academyApplicants?.currentPage
  );
  const nextPage = useSelector((state) => state?.academyApplicants?.nextPage);
  const lastPage = useSelector((state) => state?.academyApplicants?.lastPage);
  const [numbersOfPages, setNumbersOfPages] = useState([]);
  const [applicantPopUp, setApplicantPopUp] = useState(false);
  const [applicantsData, setApplicantsData] = useState({});
  const applicants = useSelector(
    (state) => state?.academyApplicants?.academyApplicants
  );
  const applicantsLoading = useSelector(
    (state) => state?.academyApplicants?.loading
  );
  const applicantsStatus = useSelector(
    (state) => state?.applicantsStatus?.applicantsStatus
  );
  const applicantsStatusLoading = useSelector(
    (state) => state?.applicantsStatus?.loading
  );
  const [loadingModalShow, setLoadingModalShow] = useState(
    applicantsLoading || allJobTitlesLoading || applicantsStatusLoading
  );
  const [filterModalState, setFilterModalState] = useState(false);
  const [changeAllTo, setChangeAllTo] = useState(applicationStatus);

  console.log(nextPage);
  useEffect(() => {
    setLoadingModalShow(
      applicantsLoading || allJobTitlesLoading || applicantsStatusLoading
    );
  }, [applicantsLoading, allJobTitlesLoading, applicantsStatusLoading]);
  useEffect(() => {
    let array = [];
    let pages = lastPage;
    while (pages > 0) {
      array.push(pages);
      pages--;
    }
    setNumbersOfPages(array.reverse());
  }, [lastPage]);

  const handleDropDown = (data) => {
    if (data === "All Jobs") {
      setDropDownElement(data);
      setJobIdRoute("");
      setJobStatus("");
    } else if (data === "Active Jobs") {
      setJobIdRoute("");
      setJobStatus(1);
    } else if (data === "Closed Jobs") {
      setJobIdRoute("");
      setJobStatus(3);
    } else {
      setJobIdRoute(data);
      setJobStatus("");
    }
  };

  const handleJobStatus = (data) => {
    if (data === "All Status") {
      setApplicationStatus("");
    } else {
      setApplicationStatus(data);
    }
  };
  const handleCheck = (jobId, applicantId) => {
    let arr = checkedUsers.slice(0);
    if (arr.findIndex((item) => item.application_id === applicantId) >= 0) {
      arr.splice(
        arr.findIndex((item) => item.application_id === applicantId),
        1
      );
    } else {
      arr.push({ application_id: applicantId, job_id: jobId });
    }
    setCheckedUseres(arr);
  };
  const handleSubmitStatus = () => {
    setLoadingModalShow(true);
    if (applicationStatus == changeAllTo) {
      setLoadingModalShow(false);
      return toastAlert("Please select different status", undefined, "success");
    }
    let userData = {
      changes: checkedUsers,
      status: changeAllTo,
    };
    dispatch(
      updateApplicationStatus(
        userData,
        lng === "arab" ? 1 : 2,
        setLoadingModalShow,
        setForceLoad,
        forceLoad
      )
    );
  };
  return (
    <Fragment>
      <div className="home-page container">
        <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
          <div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-3 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0  style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {dropDownElement}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              {id ? null : (
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                  <Dropdown.Item
                    onClick={() => handleDropDown("All Jobs")}
                    className=" text-primary"
                  >
                    {t("All Jobs")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleDropDown("Active Jobs")}
                    className=" text-primary"
                  >
                    {t("Active Jobs")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleDropDown("Closed Jobs")}
                    className=" text-primary"
                  >
                    {t("Closed Jobs")}
                  </Dropdown.Item>
                  {allJobTitles?.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => handleDropDown(item.id)}
                      className=" text-primary"
                    >
                      {item?.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
            </Dropdown>
          </div>
          <div className="col-xl-10 col-xxl-9 col-lg-9 col-sm-9 col-12 d-md-flex job-title-search pe-0">
            <div className="input-group search-area">
              <input
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                type="text"
                className="form-control h-auto"
                placeholder={t("search job title here...")}
              />
              <span
                style={{ gap: "5px", padding: "6px" }}
                className="input-group-text"
              >
                {searchData && searchData?.trim() ? (
                  <Link
                    onClick={() => setSearchData("")}
                    style={{
                      padding: "10px",
                      borderRadius: "50%",
                      display: "flex",
                      gap: "5px",
                    }}
                    to={"#"}
                    className="btn btn-dark"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      dataName="Layer 1"
                      fill="#fff"
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                    >
                      <path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z" />
                    </svg>
                  </Link>
                ) : null}
                <button
                  onClick={() => setFilterModalState(true)}
                  style={{
                    padding: "8px 25px 8px 25px",
                  }}
                  to={"#"}
                  className="btn-2 semi-bold-white"
                >
                  <i
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="fi fi-sr-settings-sliders"
                  ></i>
                  {t("Filter")}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="d-sm-flex d-block justify-content-between align-items-center mb-4">
          <div className="card-action coin-tabs mt-3 mt-sm-0">
            <Nav as="ul" className="nav nav-tabs" role="tablist">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  style={
                    applicationStatus === ""
                      ? {
                          borderBottom: "2px solid #008C77",
                          color: "#008C77",
                        }
                      : null
                  }
                  className="nav-link"
                  onClick={() => handleJobStatus("All Status")}
                >
                  {t("All Status")}
                </Nav.Link>
              </Nav.Item>
              {applicantsStatus?.map((item, i) => (
                <Nav.Item key={i} as="li" className="nav-item">
                  <Nav.Link
                    style={
                      applicationStatus === item?.id
                        ? {
                            borderBottom: "2px solid #008C77",
                            color: "#008C77",
                          }
                        : null
                    }
                    className="nav-link"
                    onClick={() => handleJobStatus(item?.id)}
                  >
                    {item?.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        </div>
        <NewApplicantionDataTable
          applicants={applicants}
          setPageNumber={setPageNumber}
          numbersOfPages={numbersOfPages}
          setApplicantPopUp={setApplicantPopUp}
          setApplicantsData={setApplicantsData}
          flag
          loadingModalShow={loadingModalShow}
          currentPage={currentPage}
          pageNumber={pageNumber}
          nextPage={nextPage}
          handleCheck={handleCheck}
          checkedUsers={checkedUsers}
          applicantsStatus={applicantsStatus}
          applicationStatus={applicationStatus}
          setChangeAllTo={setChangeAllTo}
          handleSubmitStatus={handleSubmitStatus}
          changeAllTo={changeAllTo}
        />
      </div>
      <ApplicantsModal
        show={applicantPopUp}
        onHide={() => setApplicantPopUp(false)}
        data={applicantsData}
        setForceLoad={setForceLoad}
        forceLoad={forceLoad}
      />
      <FilterModal
        show={filterModalState}
        onHide={() => setFilterModalState(false)}
        submitFilterStatus={submitFilterStatus}
        setSubmitFilterStatus={setSubmitFilterStatus}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </Fragment>
  );
};
export default NewApplicants;
const FilterModal = ({
  onHide,
  filterData,
  setFilterData,
  submitFilterStatus,
  setSubmitFilterStatus,
  show,
}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  useEffect(() => {
    dispatch(getNewSubjects(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getJobTitle(lng === "arab" ? 1 : 2));
    dispatch(getDegreeLevels(lng === "arab" ? 1 : 2));
    dispatch(getCitizenships(lng === "arab" ? 1 : 2));
    dispatch(getLanguages(lng === "arab" ? 1 : 2));
    dispatch(getCurriculums(lng === "arab" ? 1 : 2));
  }, []);
  const newSubjects = useSelector((state) => state?.newSubjects?.subjects);
  const newSubjectsLoading = useSelector(
    (state) => state?.newSubjects?.newLoading
  );
  const grades = useSelector((state) => state?.grades?.grades);
  const gradesLoading = useSelector((state) => state?.grades?.innerLoading);
  const jobTitles = useSelector((state) => state?.jobTitles?.jobTitles);
  const jobTitlesLoading = useSelector((state) => state?.jobTitles?.loading);
  const degrees = useSelector((state) => state?.degrees?.degrees);
  const degreesLoading = useSelector((state) => state?.degrees?.loading);
  const citizenships = useSelector(
    (state) => state?.citizenships?.citizenships
  );
  const citizenshipsLoading = useSelector(
    (state) => state?.citizenships?.loading
  );
  const languages = useSelector((state) => state?.languages?.languages);
  const languagesLoading = useSelector((state) => state?.languages?.loading);
  const curriculums = useSelector((state) => state?.curriculums?.curriculums);
  const curriculumsLoading = useSelector(
    (state) => state?.curriculums?.loading
  );

  const [loadingModalShow, setLoadingModalShow] = useState(
    newSubjectsLoading || gradesLoading,
    jobTitlesLoading ||
      degreesLoading ||
      citizenshipsLoading ||
      languagesLoading ||
      curriculumsLoading
  );
  useEffect(() => {
    setLoadingModalShow(
      newSubjectsLoading || gradesLoading,
      jobTitlesLoading ||
        degreesLoading ||
        citizenshipsLoading ||
        languagesLoading ||
        curriculumsLoading
    );
  }, [
    newSubjectsLoading,
    gradesLoading,
    jobTitlesLoading,
    degreesLoading,
    citizenshipsLoading,
    languagesLoading,
    curriculumsLoading,
  ]);
  const licence = [
    { name: lng === "arab" ? "رخصة تدريس" : "Teacher License", value: "TL" },
    { name: lng === "arab" ? "رخصة ESL" : "ESL Certificate", value: "ESL" },
  ];
  const experienceData = [
    { name: lng === "arab" ? "بدون خبرة" : "No Experience", value: "N" },
    { name: lng === "arab" ? "عام" : "1+ Years", value: 1 },
    { name: lng === "arab" ? "عامين" : "2+ Years", value: 2 },
    { name: lng === "arab" ? "ثلاثة أعوام" : "3+ Years", value: 3 },
    { name: lng === "arab" ? "أربعة أعوام" : "4+ Years", value: 4 },
    { name: lng === "arab" ? "خمسة أعوام" : "5+ Years", value: 5 },
    { name: lng === "arab" ? "ستة أعوام" : "6+ Years", value: 6 },
    { name: lng === "arab" ? "سبعة أعوام" : "7+ Years", value: 7 },
    { name: lng === "arab" ? "ثمانية أعوام" : "8+ Years", value: 8 },
    { name: lng === "arab" ? "تسعة أعوام" : "9+ Year", value: 9 },
    { name: lng === "arab" ? "عشرة أعوام" : "10+ Year", value: 10 },
  ];
  const dispatch = useDispatch();

  const handleFilter = () => {
    setSubmitFilterStatus(submitFilterStatus + 1);
    onHide();
  };
  const handleCancel = () => {
    setFilterData({
      licenses_and_certifications: [],
      citizenships: [],
      educations: [],
      experience: "",
      date_applied: "",
      subjects: [],
      grades: [],
      curriculums: [],
      locations: [],
      languages: [],
      teacher_categories: [],
    });
    setSubmitFilterStatus(submitFilterStatus + 1);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="fade filterModal"
    >
      <Modal.Body style={{ padding: "0px" }}>
        <div className="filter_modal_body">
          <div className="filter_modal_header">
            <h3>{t("Filter Applicants")}</h3>
            <Link
              onClick={() => onHide()}
              style={{
                padding: "10px",
                borderRadius: "50%",
                display: "flex",
                gap: "5px",
                backgroundColor: "#008C77",
              }}
              to={"#"}
              className="btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                dataName="Layer 1"
                fill="#fff"
                viewBox="0 0 24 24"
                width="15"
                height="15"
              >
                <path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z" />
              </svg>
            </Link>
          </div>
          {loadingModalShow ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "306px",
              }}
            >
              <Loading />
            </div>
          ) : (
            <div className="filterBody">
              <div className="row">
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("License and Certifications")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({
                        ...filterData,
                        licenses_and_certifications: selectedOption,
                      })
                    }
                    value={filterData.licenses_and_certifications}
                    placeholder={t("Select Certification(s)")}
                    options={licence?.map((item) => ({
                      label: item?.name,
                      value: item?.value,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Citizenship")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({
                        ...filterData,
                        citizenships: selectedOption,
                      })
                    }
                    value={filterData.citizenships}
                    placeholder={t("Select Citizenship(s)")}
                    options={citizenships?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Education")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({
                        ...filterData,
                        educations: selectedOption,
                      })
                    }
                    value={filterData.educations}
                    placeholder={t("Select Skills")}
                    options={degrees?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Experience")}*
                  </label>
                  <select
                    className="form-select form-control solid"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        experience: e.target.value,
                      })
                    }
                    value={filterData.experience}
                  >
                    <option value="">{t("Select Experience")}</option>
                    {experienceData.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Date Applied")}*
                  </label>
                  <select
                    className="form-select form-control solid"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        date_applied: e.target.value,
                      })
                    }
                    value={filterData.date_applied}
                  >
                    <option value="">{t("All Time")}</option>
                    <option value="D">{t("Past Day")}</option>
                    <option value="W">{t("Past Week")}</option>
                    <option value="M">{t("Past Month")}</option>
                  </select>
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Subject(s)")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({ ...filterData, subjects: selectedOption })
                    }
                    value={filterData.subjects}
                    placeholder={t("Select Subject(s)")}
                    options={newSubjects?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Grade level(s)")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({ ...filterData, grades: selectedOption })
                    }
                    value={filterData.grades}
                    placeholder={t("Select level(s)")}
                    options={grades?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Curriculum")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({
                        ...filterData,
                        curriculums: selectedOption,
                      })
                    }
                    value={filterData.curriculums}
                    placeholder={t("Select Curriculum(s)")}
                    options={curriculums?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Language")}*
                  </label>
                  <Select
                    onChange={(selectedOption) =>
                      setFilterData({
                        ...filterData,
                        languages: selectedOption,
                      })
                    }
                    value={filterData.languages}
                    placeholder={t("Select Language(s)")}
                    options={languages?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label card-label">
                    {t("Teacher Category")}*
                  </label>
                  <Select
                    onChange={(data) =>
                      setFilterData({ ...filterData, teacher_categories: data })
                    }
                    value={filterData.teacher_categories}
                    placeholder={t("Select Category(ies)")}
                    options={jobTitles?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isMulti
                  />
                </div>
              </div>
              <div className="card-footer text-end">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button className="all-job-btn" onClick={handleCancel}>
                    {t("Cancel")}
                  </button>
                  <button
                    // className="btn btn-primary"
                  className="btn-2 semi-bold-white"
                    onClick={handleFilter}
                  >
                    {t("Filter")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
const ApplicantsModal = (props) => {
  return (
    <Modal {...props} centered size="md" className="fade applicantModal">
      <Modal.Body>
        <UserProfile
          forceLoad={props.forceLoad}
          setForceLoad={props.setForceLoad}
          data={props.data}
          onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
  );
};
