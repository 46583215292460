import { t } from "i18next";
import React, { Fragment } from "react";

const DiversityResources = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <Fragment>
      <div className="diversity">
        <h1 className="diversity-heading">
          {t("Diversity is one of our hallmarks")}
        </h1>
        <div>
          <p>
            {t(
              "At Madares, we Embrace Diversity. In fact, it’s one of our values. We also believe that the best way to create an inclusive world is through education and cultural exchange. That’s why we’re committed to building an internal culture and a community of partners that promote knowledge, diversity and inclusivity."
            )}
          </p>
          <div className="diversity-quotes">
            <p className="diversity-quote">
              {t(
                "“It is time for parents to teach young people early on that in diversity there is beauty and there is strength.”"
              )}
            </p>
            <p className="diversity-quote">{t("Maya Angelou")}</p>
          </div>
        </div>
        <h1 className="diversity-heading" style={{ marginTop: "40px" }}>
          {t("We’re all different – and that’s the way we like it")}
        </h1>
        <div>
          <p>
            {t("We celebrate our differences. ")}
            <a target="_blank" href="" title="About Madares">
              {t("Our team")}
            </a>
            {t(
              " is made up of people from an assortment of countries across the globe, from different cultures, religions, ages, genders, colours and physical appearances. We embrace this diversity because we know that it is these differences make us stronger, smarter, better."
            )}
          </p>
          <p>
            {t(
              "We encourage our community to express who they are with pride, respect and without fear. We’re proud to stand for equality and fairness and we encourage our partners to join us as we move along this path."
            )}
          </p>
        </div>
        <h1 className="diversity-heading" style={{ marginTop: "40px" }}>
          {t("The road is long but we’re committed to traveling it")}
        </h1>
        <div>
          <p>
            {t(
              "We know we still have a lot of work to do on our journey, but we’re committed to continuing to focus on diversity and equality. We’re also passionate about sharing what we learn with our partners."
            )}
          </p>
          <p>
            <a target="_blank" href="" title="About Madares">
              {t("Contact us")}
            </a>
            {t(
              " to learn more about our commitment to creating an inclusive community, without discrimination."
            )}
          </p>
        </div>
        <h1 className="diversity-heading" style={{ marginTop: "40px" }}>
          {t("Canadian School Boards Diversity Statements")}
        </h1>
        <div style={{ marginTop: "25px" }}>
          <p className="child-protection-titles">
            <span>{t("Name")}</span>
            <span style={{ float: lng === "arab" ? "left" : "right" }}>
              {t("Last Modified")}
            </span>
          </p>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "Toronto District School Board: Elementary School Equitable and Inclusive Education"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  2014
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "British Columbia Ministry of Education: Diversity in BC Schools: A Framework"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  Nov 2014
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "Peel District School Board: Equity and Inclusive Education"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  Aug 2010
                </p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="diversity-heading" style={{ marginTop: "60px" }}>
          {t("US School Boards Diversity Statemenents")}
        </h1>
        <div style={{ marginTop: "25px" }}>
          <p className="child-protection-titles">
            <span>{t("Name")}</span>
            <span style={{ float: lng === "arab" ? "left" : "right" }}>
              {t("Last Modified")}
            </span>
          </p>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "Ohio State Board of Education: Diversity Strategies for Successful Schools Policies"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  N/A
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "Fresno United School District: Diversity, Equity, and Inclusion"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  Oct 23, 2013
                </p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="diversity-heading" style={{ marginTop: "60px" }}>
          {t("UK School Boards Diversity Statements")}
        </h1>
        <div style={{ marginTop: "25px", marginBottom: "50px" }}>
          <p className="child-protection-titles">
            <span>{t("Name")}</span>
            <span style={{ float: lng === "arab" ? "left" : "right" }}>
              {t("Last Modified")}
            </span>
          </p>
          <hr />
          <div className="child-protection-container">
            <div className="row">
              <div className="col-xs-1">
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "10px" }}
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
              <div className="col-xs-8">
                <p className="child-protection-paragraph">
                  {t(
                    "British Council: Inclusion and Diversity in Education: Guidelines for Diversity and Inclusion in Schools"
                  )}
                </p>
              </div>
              <div className="col-xs-3">
                <p style={{ textAlign: lng === "arab" ? "left" : "right" }}>
                  2010
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DiversityResources;