import { t } from "i18next";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../layouts/loadingComponents/Loading";
import { toastAlert } from "../../../layouts/toastAlert/toastAlert";
import "react-phone-number-input/style.css";
import "./Help.css";
import { createHelpRequest } from "../../../../store/actions/helpRequest";

let assistanceType = [
  { id: "1", name: "Customer Service for schools" },
  { id: "2", name: "Customer Service for teachers" },
  { id: "3", name: "Customer Service for accessibility" },
];
let schoolServices = [
  { id: "1", name: "Technical Support" },
  { id: "2", name: "Edits to my job posting" },
  { id: "3", name: "Upgrade my subscription" },
  { id: "4", name: "Feedback and suggestions" },
  { id: "5", name: "Delete my account" },
  { id: "6", name: "Other" },
];
let teacherServices = [
  { id: "1", name: "Technical Support" },
  { id: "5", name: "Delete my account" },
  { id: "6", name: "Other" },
];

const Help = () => {
  const dispatch = useDispatch();
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const useAuth = useSelector((state) => state?.userDetails?.subTypeData);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    assistance_type: "",
    assistance_sub_type: "",
    additional_info: "",
  });

  const handleSubmit = () => {
    console.log("userData", userData);
    setLoadingModalShow(true);
    let errorArr = [];
    let data = {
      ...userData,
      email: useAuth?.email,
    };
    if (!data.first_name) {
      errorArr.push(t("First name is required"));
    }
    if (!data.last_name) {
      errorArr.push(t("Last name is required"));
    }
    if (!data.email) {
      errorArr.push(t("Email is required"));
    }
    if (!data.assistance_type) {
      errorArr.push(t("assistance type is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(createHelpRequest(data, setLoadingModalShow));
    setUserData({
      first_name: "",
      last_name: "",
      phone_number: "",
      assistance_type: "",
      assistance_sub_type: "",
      additional_info: "",
    });
  };

  return (
    <div className="help">
      <div className="responsive-help">
        <div className="help-title">
          Tell us about your issue so we can help.
          <div className="help-form">
            {loadingModalShow ? (
              <div
                style={{
                  minHeight: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <div className="help-main">
                  <label className="help-label-1">First Name</label>
                  <input
                    type="text"
                    className="help-input-field"
                    name="current_password"
                    value={userData?.first_name}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        first_name: e.target.value,
                      })
                    }
                  />
                  <label className="help-label-1 help-label-2">Last Name</label>
                  <input
                    type="text"
                    className="help-input-field"
                    name="new_password"
                    value={userData?.last_name}
                    onChange={(e) =>
                      setUserData({ ...userData, last_name: e.target.value })
                    }
                  />
                  <label className="help-label-1 help-label-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="help-input-field"
                    name="email"
                    value={useAuth?.email}
                    disabled
                  />
                  <label className="help-label-1 help-label-2">
                    Contact Number
                  </label>
                  <PhoneInput
                    className="help-input-field"
                    value={userData.phone_number}
                    onChange={(e) =>
                      setUserData({ ...userData, phone_number: e })
                    }
                  />
                  <label className="help-label-1 help-label-2">
                    What type of assistance do you require?
                  </label>
                  <select
                    name="assistance_type"
                    className="help-input-field"
                    value={userData.assistance_type}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        assistance_type: e.target.value,
                      })
                    }
                  >
                    <option value="">{t("Please select assistance type")}</option>
                    {assistanceType?.map((item, i) => (
                      <option key={i} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>

                  {userData.assistance_type === "Customer Service for schools" ? (
                    <>
                      <label className="help-label-1 help-label-2">
                        {t("Type of teacher assistance required")}
                      </label>
                      <select
                        name="assistance_sub_type"
                        className="help-input-field"
                        value={userData.assistance_sub_type}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            assistance_sub_type: e.target.value,
                          })
                        }
                      >
                        <option value="">
                          {t("Please select assistance sub type")}
                        </option>
                        {schoolServices?.map((item, i) => (
                          <option key={i} value={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}
                  {userData.assistance_type ===
                    "Customer Service for teachers" ? (
                    <>
                      <label className="help-label-1 help-label-2">
                        {t("Type of teacher assistance required")}
                      </label>
                      <select
                        name="assistance_sub_type"
                        className="help-input-field"
                        value={userData.assistance_sub_type}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            assistance_sub_type: e.target.value,
                          })
                        }
                      >
                        <option value="">
                          {t("Please select assistance sub type")}
                        </option>
                        {teacherServices?.map((item, i) => (
                          <option key={i} value={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}

                  <label className="help-label-1 help-label-2">
                    Please include any additional information!
                  </label>
                  <textarea
                    className="help-text-area-field"
                    rows="4"
                    value={userData.additional_info}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        additional_info: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="help-btn">
                  <button
                    className="help-submit-btn"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
