import React, { useContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./index.css";
import "../assets/css/chart.css";
import "../assets/css/rtl.css";
import "../assets/css/step.css";
import "../assets/css/dashboardStyle.css";
import "../assets/css/responsive.css";
/// Layout
import { ThemeContext } from "../context/ThemeContext";
/// DashboardAcademy
import Home from "./components/DashboardAcademy/homePage/Home";
import Drafts from "./components/DashboardAcademy/academyJobFlow/drafts/Drafts";
import Applicants from "./components/DashboardAcademy/academyJobFlow/Applicants/Applicants";
import ManageJobs from "./components/DashboardAcademy/academyJobFlow/ManageJobs/ManageJobs";
import NewJob from "./components/DashboardAcademy/academyJobFlow/newJob/NewJob";
import Error404 from "./pages/Error404";
import Resources from "./components/DashboardAcademy/Resources/Resources";
import ChildProtection from "./components/DashboardAcademy/Resources/ChildProtection";
import RecruitmentResources from "./components/DashboardAcademy/Resources/RecruitmentResources";
import DiversityResources from "./components/DashboardAcademy/Resources/DiversityResources";
import OrderResources from "./components/DashboardAcademy/Resources/OrderResources";
import DashboardHeader from "./layouts/header/dashboardHeader";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CLEAR_TEMP } from "../store/actions/Constants";
import AuthError from "./pages/authError";
import HomeTeacher from "./components/DashboardTeacher/home/HomeTeacher";
import NewHomeTeacher from "./components/DashboardTeacher/home/NewHomeTeacher";
// import MyProfile from "./components/Common/MyProfile";
// import MyProfileAcademy from "./components/DashboardAcademy/myProfile/MyProfile";
import MyProfileAcademy from "./components/DashboardAcademy/myProfile/NewMyProfile";
import AcademySettings from "./components/DashboardAcademy/settings/Setting";
import TeacherSettings from "./components/DashboardTeacher/settings/Setting";
import Help from "./components/Common/Help";
import MyProfileTeacher from "./components/DashboardTeacher/myProfile/MyProfile";
import BestCandidate from "./components/DashboardAcademy/bestCandidate/BestCandidate";
import SavedJob from "./components/DashboardTeacher/SavedJobs/SavedJob";
import Statistics from "./components/DashboardAcademy/analytics/Statistics";
import ApplicantProfile from "./components/DashboardAcademy/applicantProfile/ApplicantProfile";
import HomePage from "./components/DashboardAcademy/homePage/HomePage";
import NewManageJobs from "./components/DashboardAcademy/academyJobFlow/ManageJobs/NewManageJobs";
import NewDrafts from "./components/DashboardAcademy/academyJobFlow/drafts/NewDraft";
import NewApplicants from "./components/DashboardAcademy/academyJobFlow/Applicants/NewApplicants"
import NewSetting from "./components/DashboardAcademy/settings/NewSetting";
import DatabaseAccess from "./components/DashboardAcademy/databaseAccess/DatabaseAccess";
import NewHelp from "./components/Common/Help/Help"
import PostJob from "./components/DashboardAcademy/academyJobFlow/postJob/PostJob";
import Applications from "./components/DashboardTeacher/applications/Applications";
import NewSavedJobs from "./components/DashboardTeacher/newSavedJobs/NewSavedJobs";
import Suggested from "./components/DashboardTeacher/suggested/Suggested";

const Markup = (props) => {
  const { menuToggle } = useContext(ThemeContext);
  const [logedInAs, setLogedInAs] = useState(props?.userType);
  const [routes, setRoutes] = useState([]);
  const postTempo = useSelector((state) => state?.tempoJob);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (postTempo?.details) {
      dispatch({ type: CLEAR_TEMP });
    }
  }, [location]);
  const academyRoutes = [
    { url: "manage-jobs", component: NewManageJobs },
    // { url: "manage-jobs", component: ManageJobs },
    // { url: "applicants", component: Applicants },
    { url: "applicants", component: NewApplicants },
    // { url: "drafts", component: Drafts },
    { url: "drafts", component: NewDrafts },
    // { url: "new-job", component: NewJob },
    { url: "new-job", component: PostJob },
    { url: "analytics", component: Statistics },
    { url: "resources", component: Resources },
    { url: "resources-child-protection", component: ChildProtection },
    { url: "recruitment-resources", component: RecruitmentResources },
    { url: "diversity-resources", component: DiversityResources },
    { url: "order-resources", component: OrderResources },
    // { url: "hiring-process", component: HiringProcess },
    { url: "", component: HomePage },
    // { url: "", component: Home },
    { url: "dashboard", component: Home },
    // { url: "settings", component: AcademySettings },
    { url: "settings", component: NewSetting },
    // { url: "help", component: Help },
    { url: "help", component: NewHelp },
    { url: "my-profile", component: MyProfileAcademy },
    { url: "best-candidate", component: BestCandidate },
    { url: `applicant-profile`, component: ApplicantProfile },
    { url: `database-access`, component: DatabaseAccess },
    // { url: "profile/:id", component: AppProfile },
  ];

  const teacherRoutes = [
    // { url: "", component: HomeTeacher },
    { url: "", component: NewHomeTeacher },
    { url: "applications", component: Applications },
    { url: "saved-jobs", component: NewSavedJobs },
    { url: "suggested", component: Suggested },
    // { url: "dashboard", component: HomeTeacher },
    { url: "my-profile", component: MyProfileTeacher },
    { url: "settings", component: TeacherSettings },
    { url: "help", component: Help },
    // { url: "saved-jobs", component: SavedJob },

  ];
  const noType = [];

  useEffect(() => {
    if (!props.loading && props?.userType !== 3 && props?.userType !== 4) {
      setLogedInAs(props?.userType);
      if (props?.userType === 0) {
        setRoutes(academyRoutes);
      } else if (props?.userType === 1) {
        setRoutes(teacherRoutes);
      }
    } else if (!props.loading && props?.userType === 4) {
      setRoutes(noType);
    }
  }, [props?.userType, props?.loading]);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = routes.filter((item) => path === item?.url).length
    ? true
    : false;
  return (
    <>
      {props?.userType === 0 ? (
        <Switch>
          {routes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`/${data.url}`}
              component={data.component}
            />
          ))}
          {routes.length > 0 ? <Route component={Error404} /> : null}
        </Switch>
      ) : (
        <Switch>
          {routes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`/${data.url}`}
              component={data.component}
            />
          ))}
          {routes.length > 0 ? <Route component={Error404} /> : null}
        </Switch>
        // <div
        //   id={`${pagePath ? "main-wrapper" : ""}`}
        //   className={`${pagePath ? "show" : "mh100vh"}  ${
        //     menuToggle ? "menu-toggle" : ""
        //   }`}
        // >
        //   {pagePath && <DashboardHeader logedInAs={logedInAs} />}

        //   <div className={`${pagePath ? "content-body" : ""}`}>
        //     <div
        //       className={`${pagePath ? "container-fluid" : ""}`}
        //       style={
        //         pagePath ? { paddingTop: "10px", maxWidth: "1200px" } : null
        //       }
        //     >
        //       <Switch>
        //         {routes.map((data, i) => (
        //           <Route
        //             key={i}
        //             exact
        //             path={`/${data.url}`}
        //             component={data.component}
        //           />
        //         ))}
        //         {routes.length > 0 ? <Route component={Error404} /> : null}
        //       </Switch>
        //     </div>
        //   </div>
        // </div>
       )}
    </>
  );
};

export default Markup;
