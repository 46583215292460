import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../../layouts/loadingComponents/Loading";
import { Modal } from "react-bootstrap";
import PostJob from "../postJob/PostJob";
import { useDispatch } from "react-redux";
import {
  ADD_TEMPO_JOB_POST_DETAILS,
  CLEAR_TEMP,
} from "../../../../../store/actions/Constants";
import { deleteJob, changeJobStatus } from "../../../../../store/actions/Jobs";
import { t } from "i18next";
import "../../homePage/HomePage.css";

const NewJobDataTable = ({
  setPageNumber,
  jobs,
  numbersOfPages,
  loadingModalShow,
  firstFive,
  page,
  drafts,
  forceLoad,
  setForceLoad,
  currentPage,
  pageNumber,
  nextPage,
  liveJob,
}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const history = useHistory();
  const [editJob, setEditJob] = useState(false);
  const [deleteJob, setDeleteJob] = useState(false);
  const [jobData, setJobData] = useState();
  const [jobDataDelete, setJobDataDelete] = useState();
  const [profilesTable, setProfilesTable] = useState(
    drafts
      ? {
          columns: [t("Job Title"), t("Date Of adding"), t("Next step")],
        }
      : liveJob
      ? {
          columns: [
            t("Job Title"),
            "",
            t("Total Applicants"),
            t("Total Views"),
            t("Expires"),
          ],
        }
      : {
          columns: [
            t("Job Title"),
            t("Total Applicants"),
            t("Total Views"),
            t("Expires"),
          ],
        }
  );

  useEffect(() => {
    setProfilesTable(
      drafts
        ? {
            columns: [t("Job Title"), t("Date Of adding"), t("Next step")],
          }
        : liveJob
        ? {
            columns: [
              t("Job Title"),
              "",
              t("Total Applicants"),
              t("Total Views"),
              t("Expires"),
            ],
          }
        : {
            columns: [
              t("Job Title"),
              t("Total Applicants"),
              t("Total Views"),
              t("Expires"),
            ],
          }
    );
  }, [jobs]);

  console.log("jobs", jobs);
  console.log("profilesTable", profilesTable);

  const deleteThisJob = (jobDetails) => {
    setJobDataDelete(jobDetails);
    setDeleteJob(true);
  };
  const editThisJob = (jobDetails) => {
    setJobData(jobDetails);
    setEditJob(true);
  };
  return (
    <>
      {/* Live jobs */}
      {!liveJob ? null : (
        <div className="row d-flex align-items-center">
          <div className="col-lg-4 col-sm-12">
            <p className="title semi-bold-black">Live Jobs</p>
          </div>
          <div className="col-lg-8 col-sm-12 d-flex justify-content-end">
            <button
              className="all-job-btn"
              onClick={() => history.push("/manage-jobs")}
            >
              All Job
            </button>
            <button
              className="btn-2 semi-bold-white"
              onClick={() => history.push("/new-job")}
            >
              Post New Job
            </button>
          </div>
        </div>
      )}

      {/* Table 1 */}
      {loadingModalShow ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "306px",
          }}
        >
          <Loading />
        </div>
      ) : !loadingModalShow && jobs.length === 0 ? (
        <div className="no-data-message">
          <h5
            style={{
              color: "black",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "15px",
            }}
          >
            {drafts
              ? t("You don't have any drafts")
              : t("You don't have any Active jobs!")}
          </h5>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                {profilesTable.columns.map((d, i) => (
                  <th key={i}>{d}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jobs?.map((item, index) => (
                <>
                  {drafts ? (
                    <>
                      <tr>
                        <td
                          onClick={() =>
                            history.push(`/applicants?id=${item?.id}`)
                          }
                        >
                          {item?.title || "title"}
                        </td>
                        <td>{item?.start_date}</td>
                        <td>
                          {item?.requirements_done === "0"
                            ? t("Requirements")
                            : item?.benefits_done === "0"
                            ? t("Benefits")
                            : t("Submitting")}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "30px",
                            }}
                          >
                            <div
                              className="circle"
                              onClick={() =>
                                item?.job_status_id && item?.job_status_id != 1
                                  ? null
                                  : deleteThisJob(item)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_18425"
                                      data-name="Rectangle 18425"
                                      width="16"
                                      height="16"
                                      transform="translate(0.375 0.375)"
                                      fill="#f80b0b"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Mask_Group_10"
                                  data-name="Mask Group 10"
                                  transform="translate(-0.375 -0.375)"
                                  clipPath="url(#clip-path)"
                                >
                                  <g
                                    id="surface1"
                                    transform="translate(1.628 -0.142)"
                                  >
                                    <path
                                      id="Path_992"
                                      data-name="Path 992"
                                      d="M9.2.132A.412.412,0,0,0,8.907,0H2.262A2.248,2.248,0,0,0,.023,2.234V14.8a2.248,2.248,0,0,0,2.238,2.234h9.019A2.248,2.248,0,0,0,13.519,14.8V4.822a.443.443,0,0,0-.119-.288Zm.119,1.321,2.81,2.95H10.306a.98.98,0,0,1-.983-.979Zm1.958,14.759H2.262A1.425,1.425,0,0,1,.846,14.8V2.234A1.425,1.425,0,0,1,2.262.823H8.5v2.6a1.8,1.8,0,0,0,1.806,1.8H12.7V14.8A1.422,1.422,0,0,1,11.281,16.211Zm0,0"
                                      transform="translate(-0.023)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_993"
                                      data-name="Path 993"
                                      d="M11.364,18.841H4.591a.411.411,0,1,0,0,.823h6.777a.411.411,0,1,0,0-.823Zm0,0"
                                      transform="translate(-1.23 -5.469)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_994"
                                      data-name="Path 994"
                                      d="M10.357,10.154a.41.41,0,0,0-.58,0l-.9.9-.9-.9a.41.41,0,1,0-.58.58l.9.9-.9.9a.41.41,0,0,0,0,.58.4.4,0,0,0,.292.119.421.421,0,0,0,.292-.119l.9-.9.9.9a.4.4,0,0,0,.292.119.421.421,0,0,0,.292-.119.41.41,0,0,0,0-.58l-.9-.9.9-.9A.411.411,0,0,0,10.357,10.154Zm0,0"
                                      transform="translate(-2.128 -2.912)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_995"
                                      data-name="Path 995"
                                      d="M7.816,6.383a4.193,4.193,0,1,0,4.193,4.193A4.2,4.2,0,0,0,7.816,6.383Zm0,7.562a3.37,3.37,0,1,1,3.37-3.37A3.371,3.371,0,0,1,7.816,13.945Zm0,0"
                                      transform="translate(-1.068 -1.853)"
                                      fill="#f80b0b"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div
                              className="circle"
                              onClick={() => editThisJob(item)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.642"
                                height="13.642"
                                viewBox="0 0 13.642 13.642"
                              >
                                <path
                                  id="Icon_material-edit"
                                  data-name="Icon material-edit"
                                  d="M4.5,15.3v2.842H7.342l8.381-8.381L12.881,6.916ZM17.921,7.56a.755.755,0,0,0,0-1.068L16.147,4.718a.755.755,0,0,0-1.068,0L13.692,6.1l2.842,2.842L17.921,7.56Z"
                                  transform="translate(-4.5 -4.496)"
                                  fill="#fc563e"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          onClick={() =>
                            history.push(`/applicants?id=${item?.id}`)
                          }
                        >
                          {item?.title || "title"}
                        </td>
                        {liveJob ? (
                          <td>
                            <div className="live-jobs-count">
                              {item?.applied_count || 228}
                            </div>
                          </td>
                        ) : null}

                        <td>{item?.applications_count || 0}</td>

                        <td>{item?.views || 0}</td>
                        <td>{item?.close_date || 2022 - 10 - 15}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "30px",
                            }}
                          >
                            <div
                              className="circle"
                              onClick={() =>
                                item?.job_status_id && item?.job_status_id != 1
                                  ? null
                                  : deleteThisJob(item)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_18425"
                                      data-name="Rectangle 18425"
                                      width="16"
                                      height="16"
                                      transform="translate(0.375 0.375)"
                                      fill="#f80b0b"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Mask_Group_10"
                                  data-name="Mask Group 10"
                                  transform="translate(-0.375 -0.375)"
                                  clipPath="url(#clip-path)"
                                >
                                  <g
                                    id="surface1"
                                    transform="translate(1.628 -0.142)"
                                  >
                                    <path
                                      id="Path_992"
                                      data-name="Path 992"
                                      d="M9.2.132A.412.412,0,0,0,8.907,0H2.262A2.248,2.248,0,0,0,.023,2.234V14.8a2.248,2.248,0,0,0,2.238,2.234h9.019A2.248,2.248,0,0,0,13.519,14.8V4.822a.443.443,0,0,0-.119-.288Zm.119,1.321,2.81,2.95H10.306a.98.98,0,0,1-.983-.979Zm1.958,14.759H2.262A1.425,1.425,0,0,1,.846,14.8V2.234A1.425,1.425,0,0,1,2.262.823H8.5v2.6a1.8,1.8,0,0,0,1.806,1.8H12.7V14.8A1.422,1.422,0,0,1,11.281,16.211Zm0,0"
                                      transform="translate(-0.023)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_993"
                                      data-name="Path 993"
                                      d="M11.364,18.841H4.591a.411.411,0,1,0,0,.823h6.777a.411.411,0,1,0,0-.823Zm0,0"
                                      transform="translate(-1.23 -5.469)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_994"
                                      data-name="Path 994"
                                      d="M10.357,10.154a.41.41,0,0,0-.58,0l-.9.9-.9-.9a.41.41,0,1,0-.58.58l.9.9-.9.9a.41.41,0,0,0,0,.58.4.4,0,0,0,.292.119.421.421,0,0,0,.292-.119l.9-.9.9.9a.4.4,0,0,0,.292.119.421.421,0,0,0,.292-.119.41.41,0,0,0,0-.58l-.9-.9.9-.9A.411.411,0,0,0,10.357,10.154Zm0,0"
                                      transform="translate(-2.128 -2.912)"
                                      fill="#f80b0b"
                                    />
                                    <path
                                      id="Path_995"
                                      data-name="Path 995"
                                      d="M7.816,6.383a4.193,4.193,0,1,0,4.193,4.193A4.2,4.2,0,0,0,7.816,6.383Zm0,7.562a3.37,3.37,0,1,1,3.37-3.37A3.371,3.371,0,0,1,7.816,13.945Zm0,0"
                                      transform="translate(-1.068 -1.853)"
                                      fill="#f80b0b"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="circle">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.081"
                                height="13.388"
                                viewBox="0 0 20.081 13.388"
                              >
                                <path
                                  id="Icon_awesome-eye"
                                  data-name="Icon awesome-eye"
                                  d="M19.96,10.685A11.182,11.182,0,0,0,10.041,4.5,11.184,11.184,0,0,0,.121,10.685a1.128,1.128,0,0,0,0,1.018,11.182,11.182,0,0,0,9.919,6.185A11.184,11.184,0,0,0,19.96,11.7,1.128,1.128,0,0,0,19.96,10.685Zm-9.919,5.529a5.02,5.02,0,1,1,5.02-5.02A5.02,5.02,0,0,1,10.041,16.214Zm0-8.367a3.323,3.323,0,0,0-.882.132,1.668,1.668,0,0,1-2.332,2.332,3.339,3.339,0,1,0,3.215-2.465Z"
                                  transform="translate(0 -4.5)"
                                  fill="#008c77"
                                />
                              </svg>
                            </div>
                            <div
                              className="circle"
                              onClick={() => editThisJob(item)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.642"
                                height="13.642"
                                viewBox="0 0 13.642 13.642"
                              >
                                <path
                                  id="Icon_material-edit"
                                  data-name="Icon material-edit"
                                  d="M4.5,15.3v2.842H7.342l8.381-8.381L12.881,6.916ZM17.921,7.56a.755.755,0,0,0,0-1.068L16.147,4.718a.755.755,0,0,0-1.068,0L13.692,6.1l2.842,2.842L17.921,7.56Z"
                                  transform="translate(-4.5 -4.496)"
                                  fill="#fc563e"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
          {firstFive || !setPageNumber || !numbersOfPages ? null : (
            <div className="table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Link
                      onClick={() =>
                        pageNumber === 1 ? null : setPageNumber(currentPage - 1)
                      }
                      className={
                        pageNumber === 1
                          ? "paginate_button previous disabled"
                          : "paginate_button previous"
                      }
                      to="#"
                    >
                      {lng === "arab" ? (
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      )}
                    </Link>
                    <span>
                      {numbersOfPages.map((number, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={
                            pageNumber === number
                              ? "paginate_button active"
                              : "paginate_button"
                          }
                          onClick={() =>
                            pageNumber === number ? null : setPageNumber(number)
                          }
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      onClick={() =>
                        nextPage === 1 ? null : setPageNumber(nextPage)
                      }
                      className={
                        nextPage === 1
                          ? "paginate_button next disabled"
                          : "paginate_button next"
                      }
                      to="#"
                    >
                      {lng === "arab" ? (
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* View All Live Job */}
      {!liveJob ? null : (
        <div
          className="view-title semi-bold-black"
          onClick={() => history.push("/manage-jobs")}
        >
          View All Live Job
        </div>
      )}

      <EditJobModal
        show={editJob}
        onHide={() => {
          dispatch({ type: CLEAR_TEMP });
          setForceLoad(forceLoad + 1);
          setEditJob(false);
        }}
        data={jobData}
      />
      <DeleteJobModal
        show={deleteJob}
        onHide={() => {
          setForceLoad(forceLoad + 1);
          setDeleteJob(false);
        }}
        data={jobDataDelete}
      />
    </>
  );
};

export default NewJobDataTable;

const EditJobModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [jobModalLoading, setJobLoadingModal] = useState(true);
  const getJobData = async (element) => {
    await dispatch({ type: ADD_TEMPO_JOB_POST_DETAILS, data: [element] });
    setJobLoadingModal(false);
  };
  useEffect(() => {
    if (props.data) {
      getJobData(props.data);
    }
  }, [props.data]);
  return (
    <Modal
      {...props}
      centered
      size="lg"
      className={`fade applicantModal ${
        lng === "arab" ? "forceArabicFont" : "forceEnglishFont"
      }`}
    >
      <Modal.Body style={{ padding: "15px" }}>
        <PostJob jobModalLoading={jobModalLoading} editPost jobId={props.data} />
      </Modal.Body>
    </Modal>
  );
};

const DeleteJobModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(true);
  const getJobData = async (element) => {
    setDeleteLoading(false);
  };
  const closeJobInner = async (jobID) => {
    setDeleteLoading(true);
    if (props.data?.job_status_id == 1) {
      await dispatch(changeJobStatus(jobID, "Closed", setDeleteLoading));
    } else {
      await dispatch(deleteJob(jobID, setDeleteLoading));
    }
    props.onHide();
  };
  useEffect(() => {
    if (props.data) {
      getJobData(props.data);
    }
  }, [props.data]);
  return (
    <Modal
      {...props}
      centered
      size="sm"
      className={`fade applicantModal ${
        lng === "arab" ? "forceArabicFont" : "forceEnglishFont"
      }`}
    >
      <Modal.Body>
        {deleteLoading ? (
          <div
            style={{
              minHeight: "98px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <div className="delet_modal_body">
            <h4 className="mb-4">{t("Are you sure?")}</h4>
            <div className="delete_btns">
              <button onClick={() => props.onHide()} className="btn btn-light">
                {t("No")}
              </button>
              <button
                onClick={() => closeJobInner(props.data?.id)}
                className="btn btn-primary"
              >
                {t("Yes")}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
