import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../../../layouts/loadingComponents/Loading";
import saveAs from 'save-as'

const ApplicantionDataTable = ({
  setPageNumber,
  applicants,
  numbersOfPages,
  setApplicantPopUp,
  setApplicantsData,
  flag,
  firstFive,
  loadingModalShow,
  forAPage,
  currentPage,
  pageNumber,
  nextPage,
  handleCheck, 
  checkedUsers,
  applicantsStatus,
  applicationStatus,
  setChangeAllTo,
  handleSubmitStatus,
  changeAllTo
}) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [checkedElements, setCheckedElements] = useState(checkedUsers)
  useEffect(() => {
    setCheckedElements(checkedUsers)
    console.log(checkedUsers)
  }, [checkedUsers])
  const [profilesTable, setProfilesTable] = useState({
    data: [],
    columns: [
      "",
      t("Name"),
      t("Citizenship"),
      t("Exp."),
      t("Education"),
      t("Job Applied"),
      t("Date"),
      "",
    ],
  });
  const history = useHistory();

  useEffect(() => {
    setProfilesTable({
      data: applicants?.map((item) => {
        return [
          item?.name,
          item?.citizenship,
          item?.exp,
          item?.education,
          item?.job_applied,
          item?.date,
          "",
        ];
      }),
      columns: [
        "",
        t("Name"),
        t("Citizenship"),
        t("Exp."),
        t("Education"),
        t("Job Applied"),
        t("Date"),
        "",
      ],
    });
  }, [applicants]);

  const popFun = (item) => {
    setApplicantPopUp(true);
    setApplicantsData(item);
  };
  async function downloadFile(displayedElement) {
    fetch(`https://api.madaresweb.mvp-apps.ae/api/download?path=${displayedElement?.teacher?.resume?.resume_url}&type=TR`, {
      headers: { 'Authorization' : `Bearer ${localStorage.getItem("sess")}` },             
      method: "GET"
    })
    .then(response => response.blob())
    .then(
      blob => saveAs(blob, `${displayedElement?.teacher?.first_name + '_' + displayedElement?.teacher?.last_name + '_resume'}`)
      );
  }
  return (
    <div className="col-12">
      <div style={{backgroundColor:'transparent', boxShadow:'unset'}} className="card">
        {!flag ? (
        <div style={{padding:'0px', backgroundColor:'transparent', borderBottom: 'none'}} className="card-header">
          <h4 className="card-title">{t("Most Recent Applicants")}</h4>
            <button type="button" class="btn btn-outline-primary" onClick={() => history.push('/applicants')}>
              {t("All Applicants")}
            </button>
          </div>
        ) : null}
        <div style={{padding:'0px', backgroundColor:'#fff', borderRadius:'10px', boxShadow: '#d3d3d340 1px 0px 8px', minHeight:'20vh', display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}} className="card-body mt-3">
        {loadingModalShow?
				<div style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight:'306px'}}>
				<Loading/>
				</div>:
        !loadingModalShow && applicants.length === 0?
        <h5>{t("You don't have any Applicants")}</h5>:
          <div style={{borderRadius: '10px', width:'100%'}} className="table-responsive">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table id="example" className="display dataTable">
                <thead>
                  <tr role="row" style={{ backgroundColor: "#d7e0d7", borderRadius: '10px' }}>
                    {/* {profilesTable.columns.map((d, i) => (
                      <th key={i}>{d}</th>
                    ))} */}
                    {firstFive || !applicationStatus ?null:<th></th>}
                    <th>{t("Name")}</th>
                    <th>{t("Citizenship")}</th>
                    <th>{t("Exp")}</th>
                    <th>{t("Education")}</th>
                    <th>{t("Job Applied")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("")}</th>
                  </tr>
                </thead>
                <tbody>
                  {applicants?.map((d, i) => (
                    <tr
                      key={i}
                      style={{ cursor: "pointer" }}
                      className="odd"
                      role="row"
                    >
                      {firstFive?
                      null:
                      !applicationStatus ?null:
                      <td style={{ fontWeight: 500, padding:'10px', display:'flex', justifyContent:'center' }}>
                        <input
                          type="checkbox"
                          onChange={() => handleCheck(d?.job?.id, d?.id)}
                          checked={checkedElements.filter(item => item.application_id == d?.id).length > 0}
                          className="form-check-input mt-2"
                          id="check1"
                          required
                        />
                      </td>
                    }
                      
                      <td onClick={() => popFun(d)} style={{ fontWeight: 500, padding:'10px' }}>{d?.teacher?.first_name + ' ' + d?.teacher?.last_name}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.teacher?.citizenships?.slice(0,1)?.shift()?.name || '-'}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>
                        {
                          d?.teacher?.total_teaching_experience_years === '0' && d?.teacher?.total_teaching_experience_months === '0'?
                          '-':
                        (d?.teacher?.total_teaching_experience_years !== '0'?  (d?.teacher?.total_teaching_experience_years + t("Years")): '')
                        +
                        (d?.teacher?.total_teaching_experience_years !== '0' && d?.teacher?.total_teaching_experience_months !== '0'? t("and"): '')
                        +
                        (d?.teacher?.total_teaching_experience_months !== '0'?  (d?.teacher?.total_teaching_experience_months + t("Months")): '')
                        }
                      </td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.teacher?.highest_education_degree?.title || '-'}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.job?.title || '-'}</td>
                      <td style={{ fontWeight: 500, padding:'10px' }}>{d?.apply_date || '-'}</td>
                      <td>
                        {/* <button type="button" class="btn btn-outline-primary"> */}
                        <i
                        onClick={() => d?.teacher?.allow_share_docs === '0'? null:downloadFile(d)}
                        style={d?.teacher?.allow_share_docs === '0'? {opacity: '0.4'}:null}
                          class="fa fa-download"
                          aria-hidden="true"
                          title="download resume"
                        ></i>
                        {/* </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {firstFive?
              null:
              <>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info"></div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link onClick={() => pageNumber === 1? null: setPageNumber(currentPage - 1)} className={pageNumber === 1? "paginate_button previous disabled":"paginate_button previous"} to="#">
                    {lng === "arab" ? (
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-double-left"
                        aria-hidden="true"
                      ></i>
                    )}
                  </Link>
                  <span>
                    {numbersOfPages.map((number, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={pageNumber === number? "paginate_button active":"paginate_button"}
                        onClick={() => pageNumber === number? null:setPageNumber(number)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>

                  <Link onClick={() => nextPage === 1? null:setPageNumber(nextPage)} className={nextPage === 1? "paginate_button next disabled":"paginate_button next"} to="#">
                    {lng === "arab" ? (
                      <i
                        className="fa fa-angle-double-left"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    )}
                  </Link>
                </div>
              </div>
              {!applicationStatus ?null:
              <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%'}}>
                {checkedElements.length > 0?
                <>
                <label style={{padding: '10px 10px 0px 10px', margin:'0px'}}>{t("Items Selected:") + ' ' + checkedElements.length}</label>
                <div className="profile-modal-footer">
                <select disabled={applicationStatus == 3 || applicationStatus == 7} value={changeAllTo} onChange={(e) => setChangeAllTo(e.target.value)} style={{margin:'0px'}} className="profile-modal-footer-select">
                  {applicantsStatus?.map((item, i) => (
                    <option
                    disabled={item?.id == 1 || 
                      (item?.id == applicationStatus) ||
                      (applicationStatus == 4 && item?.id == 1) || 
                      (applicationStatus == 4 && item?.id == 2) || 
                      (applicationStatus == 5 && item?.id == 1) || 
                      (applicationStatus == 5 && item?.id == 2) || 
                      (applicationStatus == 5 && item?.id == 4) || 
                      (applicationStatus == 6 && item?.id == 1) ||
                      (applicationStatus == 6 && item?.id == 2) || 
                      (applicationStatus == 6 && item?.id == 4) || 
                      (applicationStatus == 6 && item?.id == 5)
                      }
                    key={i} value={item.id}>{item?.name}
                    </option>
                  ))}
                </select>
                <Link onClick={handleSubmitStatus} to={"#"} style={{display:'flex', alignItems:'center', justifyContent:'center'}} className="btn btn-primary">
                  {t("Submit")}
                </Link>
              </div>
              </>:null 
              }
              </div>}
              </>}
              
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default ApplicantionDataTable;
