import axios from "axios";
import { base_API_URL, baseURL } from "./API";

export const getSubjects = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/subject?lang=${langNumber}`
  );
export const getGrades = (langNumber) =>
  axios.post(`https://api.madaresweb.mvp-apps.ae/api/grade?lang=${langNumber}`);
export const getJobTitles = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/jobTitle?lang=${langNumber}`
  );
export const getDegreeLevels = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/degreeLevel?lang=${langNumber}`
  );
export const getRequiredCertification = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/requiredCertification?lang=${langNumber}`
  );
export const getCitizenships = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/citizenship?lang=${langNumber}`
  );
export const getJobStatus = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/jobStatus?lang=${langNumber}`
  );
export const changeJobStatus = (jobID) =>
  axios.post(`https://api.madaresweb.mvp-apps.ae/api/closeJob?job_id=${jobID}`);
export const deleteJob = (jobID) =>
  axios.delete(
    `https://api.madaresweb.mvp-apps.ae/api/deleteJob?job_id=${jobID}`
  );
export const getLanguages = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/language?lang=${langNumber}`
  );
export const getCurriculums = (langNumber) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/curriculum?lang=${langNumber}`
  );
export const create_new_job_step1 = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "subjects" || item === "grades") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createJobDetails`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
export const create_new_job_step2 = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (
      item === "types_of_educators" ||
      item === "required_certifications" ||
      item === "citizenships"
    ) {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createJobRequirements`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
export const create_new_job_step3 = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    finalFormData.append(item, userData[item]);
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createJobBenefits`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
export const create_new_job_step4 = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    finalFormData.append(item, userData[item]);
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createJobOtherInfo`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
export const get_drafts = async (lang) => {
  const data = await axios.get(
    `https://api.madaresweb.mvp-apps.ae/api/draftJobs?lng=${lang}`
  );
  return data;
};
export const get_academy_jobs = async (pageNumber, lang, jobStatus) => {
  const data = await axios.get(
    `https://api.madaresweb.mvp-apps.ae/api/academyJobs?page=${pageNumber}&lang=${lang}&status=${jobStatus}`
  );
  return data;
};
export const getAllJobTitles = async (lang) => {
  const data = await axios.get(
    `https://api.madaresweb.mvp-apps.ae/api/activeAndClosedJobsTitlesByAcademy?lang=${lang}`
  );
  return data;
};
export const getApplicantsStatus = async (lang) => {
  const data = await axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/applicationStatus?lang=${lang}`
  );
  return data;
};
export const savedJobs = async (lng) => {
  return await axios.get(`${base_API_URL}savedJobs?lang=${lng}`);
};
export const saveJob = async (job_id) => {
  return await axios.post(`${base_API_URL}saveJob?job_id=${job_id}`);
};
export const unSaveJob = async (job_id) => {
  return await axios.post(`${base_API_URL}unSaveJob?job_id=${job_id}`)
}
export const updateJob = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (item === "subjects" || item === "grades" || item === "types_of_educators" || item === "required_certifications" || item === "citizenships") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]["value"]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/updateJob`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

