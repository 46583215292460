import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addTeacherEducation } from "../../../../../store/actions/teachers";
import { toastAlert } from "../../../../layouts/toastAlert/toastAlert";
import Loading from "../../../../layouts/loadingComponents/Loading";

const AddEducationModal = ({ onHide, show, degrees, setView, forceLoad, setForceLoad }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [teacherEducation, setTeacherEducation] = useState({
    degree_level_id: " 1",
    title: "",
    college: "",
    year_of_graduation: new Date(),
  });
  const submitEducationForm = () => {
    setLoading(true);
    let userData = [teacherEducation];
    userData[0].year_of_graduation = new Date(
      userData[0].year_of_graduation
    ).getFullYear();
    let errorArr = [];
    if (!userData[0].degree_level_id) {
      errorArr.push(t("Degree Level is required"));
    }
    if (!userData[0].title) {
      errorArr.push(t("Field of study is required"));
    }
    if (!userData[0].college) {
      errorArr.push(t("Place of Issurance is required"));
    }
    if (!userData[0].year_of_graduation) {
      errorArr.push(t("Year of graduation is required"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoading(false);
      return false;
    }
    dispatch(addTeacherEducation(userData, setLoading, setView, onHide, forceLoad, setForceLoad));
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      degrees={degrees}
      centered
      size="md"
      className="fade"
    >
      <Modal.Header>
        <h3>{t('Add Education')}</h3>
      </Modal.Header>
      {loading ? (
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Modal.Body className="p-5" style={{ backgroundColor: "#fff" }}>
          <>
            <div className="row">
              <div className="col-xl-6  col-md-6 mb-4">
                <label className="form-label font-w600">
                  {t("Degree level")}
                </label>
                <select
                  value={teacherEducation?.degree_level_id}
                  name="degree_level_id"
                  onChange={(e) =>
                    setTeacherEducation({
                      ...teacherEducation,
                      degree_level_id: e.target.value,
                    })
                  }
                  className="form-control solid"
                >
                  <option value="">{t("Select")}</option>
                  {degrees?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xl-6  col-md-6 mb-4">
                <label className="form-label font-w600">
                  {t("Field of study")}
                </label>
                <input
                  type="text"
                  className="form-control solid"
                  placeholder={t("Math")}
                  value={teacherEducation?.title}
                  name="title"
                  onChange={(e) =>
                    setTeacherEducation({
                      ...teacherEducation,
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12  col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Place of Issurance")}
                </label>
                <input
                  type="text"
                  className="form-control solid"
                  placeholder={t("University Name")}
                  value={teacherEducation?.college}
                  name="college"
                  onChange={(e) =>
                    setTeacherEducation({
                      ...teacherEducation,
                      college: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12  col-md-12 mb-4">
                <label className="form-label font-w600">
                  {t("Year of graduation")}
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    className="form-control solid"
                    name="year_of_graduation"
                    // format="dd/MM/yyyy"
                    views={["year"]}
                    // maxDate={`${formatDate(new Date().setFullYear(new Date().getFullYear()-18))}`}
                    disableFuture
                    value={teacherEducation?.year_of_graduation}
                    onChange={(e) =>
                      setTeacherEducation({
                        ...teacherEducation,
                        year_of_graduation: e,
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="row">
              <div
                style={{ display: "flex", gap: "7px" }}
                className="col-xl-6  col-md-6 mb-4"
              ></div>
              <div className="card-footer text-end">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link
                    to={"#"}
                    onClick={() => onHide()}
                    className="btn btn-secondry"
                  >
                    {t("Cancel")}
                  </Link>
                  <Link
                    to={"#"}
                    onClick={submitEducationForm}
                    className="btn btn-primary"
                  >
                    {t("Save")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      )}
      <Modal.Footer />
    </Modal>
  );
};

export default AddEducationModal;
