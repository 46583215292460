import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Rte from "../../../../Common/CreateArticle/Summernote/Rte";
import { useHistory } from "react-router";

const Benefits = ({editPostHandler, back, setBenefitsInfo, handleBenefitsInfoChange, benefitsInfo, submitNewJobStep3, setLoadingModalShow, editPost}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <>
    <div className="d-flex align-items-center mb-4">
        <h4 className="fs-20 font-w600 mb-0 me-auto">
            {t("Job Benefits")}
        </h4>
    </div>
    <div className="row">
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body">
                  <div className="row">
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Airfare")}
                            </label>
                            <input
                            type="text"
                            name="airfare"
                            value={benefitsInfo.airfare}
                            onChange={handleBenefitsInfoChange}
                            className="form-control solid"
                            placeholder={t("E.g., Full airfare reimbursement for an economy ticket and airport pickup")}
                            aria-label="name"
                            />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-xl-6  col-md-6 mb-4">
                            <label className="form-label font-w600">
                                {t("Accommodation")}
                            </label>
                            <input
                            name="accommodation"
                            value={benefitsInfo.accommodation}
                            onChange={handleBenefitsInfoChange}
                            type="text"
                            className="form-control solid"
                            placeholder={t("E.g., Housing allowance provided on a monthly basis")}
                            aria-label="name"
                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-12 mb-4">
                      <label className="form-label font-w600">
                        {t("All other benefits")}
                      </label>
                      <Rte 
                        changeFunc={setBenefitsInfo} 
                        obj={benefitsInfo}
                        benefits= 'other_benefits'
                      />
                    </div>
                    <div className="card-footer text-end">
                      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                      <button
                          className="btn btn-light"
                          onClick={() => back(1)}
                        >
                          {t("Back")}
                        </button>
                        <button
                          to={"#"}
                          className="btn btn-primary"
                          onClick={editPost? editPostHandler : submitNewJobStep3}
                        >
                          {t("Next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
  );
};
export default Benefits;
