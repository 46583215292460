import { t } from "i18next";
import React from "react";

const ChooseProfile = ({ setView }) => {
  const updateView = (num) => {
    setView(num);
  };
  return (
    <>
      <div className="col-xl-12">
        <div
          onClick={() => updateView(1)}
          style={{
            height: "auto",
            boxShadow: "1px 0px 8px #80808045",
            cursor: "pointer",
          }}
          className="card settingsBtn"
        >
          <div className="card-body">
            <div className="row">{t("Change General Information")}</div>
          </div>
        </div>
        <div
          onClick={() => updateView(2)}
          style={{
            height: "auto",
            boxShadow: "1px 0px 8px #80808045",
            cursor: "pointer",
          }}
          className="card settingsBtn"
        >
          <div className="card-body">
            <div className="row">{t("Change Education")}</div>
          </div>
        </div>
        <div
          onClick={() => updateView(3)}
          style={{
            height: "auto",
            boxShadow: "1px 0px 8px #80808045",
            cursor: "pointer",
          }}
          className="card settingsBtn"
        >
          <div className="card-body">
            <div className="row">{t("Change Experience")}</div>
          </div>
        </div>
        <div
          onClick={() => updateView(4)}
          style={{
            height: "auto",
            boxShadow: "1px 0px 8px #80808045",
            cursor: "pointer",
          }}
          className="card settingsBtn"
        >
          <div className="card-body">
            <div className="row">{t("Change License")}</div>
          </div>
        </div>
        <div
          onClick={() => updateView(5)}
          style={{
            height: "auto",
            boxShadow: "1px 0px 8px #80808045",
            cursor: "pointer",
          }}
          className="card settingsBtn"
        >
          <div className="card-body">
            <div className="row">{t("Change Documents")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseProfile;
